import React from "react";
import { renderIcon } from "../../Icons/Icons";
import "./custom-button.css";
import Loading from "../../Loading/Loading";

type CustomButtonType = {
  icon?: string;
  text: string;
  onClick: (ev: React.MouseEvent<HTMLElement>, id?: string) => void;
  type: string;
  id?: string;
  style?: string;
  disabled?: boolean;
  loading?: boolean;
};

const CustomButton = React.forwardRef<HTMLButtonElement, CustomButtonType>(
  (props, ref) => {
    const { text, onClick, type, icon, id, style, disabled, loading } = props;
    if (text !== "") {
      return (
        <div
          className={`buttonContainer ${type}`}
          style={{ maxWidth: style === "small" ? "100px" : "" }}
        >
          <button
            ref={ref}
            onClick={(ev: React.MouseEvent<HTMLElement>) => onClick(ev, id)}
            className={`${type} transition-1 d-flex align-items-center justify-content-center ${
              disabled ? "disabled" : ""
            }`}
            type={type === "reset" ? "reset" : "submit"}
          >
            {icon ? (
              <span className="icon line-height-1 mr-5 d-flex align-items-center justify-content-center">
                {renderIcon(icon, "", 12)}
              </span>
            ) : (
              ""
            )}
            {loading ? <div className="spinner"></div> : ""}
            <span className="text text-transform-uppercase f-s-14 f-w-3 line-height-1">
              {text}
            </span>
          </button>
        </div>
      );
    } else {
      return <span />;
    }
  }
);

export default CustomButton;
