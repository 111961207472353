import React from "react";

import { TableDisplayState } from "../../../settings/TableTypes/FaktureTypes";
import { formatDate } from "../../../functions/formaters";
import ModalBig from "../../../components/modals/ModalBig/ModalBig";

export const renderBigModal = (
  token: string,
  state: TableDisplayState,
  type: number,
  removeModal: () => void,
  pageType?: string
) => {
  if (type === 3){
    const klijent = state.dobavljaciFiltrirani.find(e => e.id === state.modalID);
    let data;
    if (klijent) {
      data = {
        id: klijent.id,
        naziv_firme: klijent.naziv_firme,
        pib: klijent.pib,
        grad: klijent.grad,
        drzava: klijent.drzava,
        drzava_id: klijent.drzava_id,
        listaGradova: [],
        grad_id: klijent.grad_id,
        listaDrzava: [],
        adresa: klijent.adresa,
        kontakt_osoba: klijent.kontakt_osoba,
        telefon: klijent.telefon,
        email: klijent.email,
        web: klijent.web,
        napomena: klijent.napomena,
        kupac: klijent.kupac,
        dobavljac:  klijent.dobavljac,
        ziro_racun: klijent.ziro_racun,
        pdv_broj: klijent.pdv_broj,
        errors: [],
        modal: false
      };
    } else {
      data = "";
    }
    return (
        <ModalBig
            token={token}
            onClick={() => false}
            title={state.modalName}
            removeModal={removeModal}
            data={data}
            type={type}
            copy={state.modalType === "copy"}
            pageType={pageType}
        />
    );
  } else
  if (type < 3 || type === 7) {
    const faktura = state.fakture.find(e => e.id === state.modalID);
    let data;
    let kupac;
    if (faktura) {
      const days = 24 * 60 * 60 * 1000;
      const valuta = Math.ceil(
        (new Date(faktura.valuta).getTime() -
          new Date(faktura.datum).getTime()) /
          days
      );

      data = {
        brfakture: faktura.br_fakture,
        datum: new Date(faktura.datum),
        datumizdavanja: formatDate(new Date(faktura.datum)),
        valuta,
        napomena: faktura.napomena,
        id: faktura.id,
        dokument:faktura.dokument,
        type: state.optionsFaktureName
      };
      kupac = faktura.kupac_id;
    } else {
      data = {};
      kupac = "";
    }
    return (
      <ModalBig
        token={token}
        onClick={() => false}
        title={state.modalName}
        removeModal={removeModal}
        data={data}
        klijent={kupac}
        type={type}
        copy={state.modalType === "copy"}
        pageType={pageType}
    />
    );
  } else if (type === 4) {
    const dokument = state.dokumentacija.find(e => e.id === state.modalID);
    let data;
    if (dokument) {
      data = {
        firstCheckBox: dokument.ui.toString() === "1" ? true : false,
        secondCheckBox: dokument.ui.toString() === "2" ? true : false,
        thirdCheckBox: false,
        tip: dokument.tip,
        klijent: dokument.kupac,
        datum: new Date(dokument.datum),
        djelovodni_broj: dokument.djelovodni_broj,
        nije_kd: "",
        dokument: dokument.dokument,
        ui: dokument.ui,
        id: dokument.id
      };
    } else {
      data = "";
    }
    return (
      <ModalBig
          token={token}
          onClick={() => false}
          title={state.modalName}
          removeModal={removeModal}
          data={data}
          type={type}
          copy={state.modalType === "copy"}
          pageType={pageType}
      />
    );
  } else if (type === 5) {
    const ugovor = state.sviUgovori.find(e => e.id === state.modalID);
    let data;
    if (ugovor) {
      data = {
        firstCheckBox: ugovor.ui.toString() === "1" ? true : false,
        secondCheckBox: ugovor.ui.toString() === "2" ? true : false,
        id: ugovor.id,
        djelovodni_broj_firme: ugovor.djelovodni_broj_firme,
        djelovodni_broj_klijenta: ugovor.djelovodni_broj_klijenta,
        klijent: "",
        datum_zak: ugovor.datum_zak,
        datumZakljucenja: new Date(ugovor.datum_zak),
        datum_vazenja: ugovor.datum_zak,
        datumVazenja: new Date(ugovor.datum_zak),
        opis: ugovor.opis,
        first_date: ugovor.first_date,
        firstDate: undefined,
        first_vrijednost: "",
        vrijednost: ugovor.vrijednost,
        offset: "",
        trajanje: "",
        drzava: "",
        listaDrzava: [],
        drzavaName: "",
        grad: "",
        listaGradova: [],
        gradName: "",
        valuta: "",
        errors: [],
        modal: false,
        listaKlijenata: [],
        klijentName: ugovor.klijent,
        listaUsluga: [],
        uslugaName: ""
      };
    } else {
      data = "";
    }
    return (
      <ModalBig
        token={token}
        onClick={() => false}
        title={state.modalName}
        removeModal={removeModal}
        data={data}
        type={type}
        pageType={pageType}
      />
    );
  } else if (type === 6) {
    const usluga = state.usluge.find(e => e.id === state.modalID);
    let data;
    if (usluga) {
      data = {
            id: usluga.id,
            naziv: usluga.naziv,
            linija_biznisa: usluga.linija_biznisa,
            linija_biznisa_naziv: usluga.linija_biznisa_naziv,
            linijeBiznisa: state.linijeBiznisa,
            cijena: usluga.cijena,
            pdv: usluga.pdv,
            valuta: usluga.valuta,
            opis:  usluga.opis
      };
    } else {
      data = "";
    }
    return (
        <ModalBig
            token={token}
            onClick={() => false}
            title={state.modalName}
            removeModal={removeModal}
            data={data}
            type={type}
            pageType={pageType}
        />
    );
  } else if (type === 8) {
    const alarm = state.alarmi !== undefined ? state.alarmi.find(e => e.id === state.modalID) : undefined;
    let data;
    if (alarm) {
      data = {
        id: alarm.id,
        naziv: alarm.naziv,
        datum: alarm.datum,
        obavjestenje: alarm.obavjestenje,
        // sms: alarm.sms,
        // mail: alarm.mail,
        // ponavljanje: alarm.ponavljanje,
      };
    } else {
      data = "";
    }
    return (
        <ModalBig
            token={token}
            onClick={() => false}
            title={state.modalName}
            removeModal={removeModal}
            data={data}
            type={type}
            pageType={pageType}
        />
    );
  }
};