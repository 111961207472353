import React, { useEffect } from "react";
import { renderIcon } from "../../Icons/Icons";

type ModalSmallType = {
  title: string;
  message: string;
  errors: Array<any>;
  removeModal: () => void;
};

const ModalErrors = ({ title, errors, removeModal }: ModalSmallType) => {
  useEffect(() => {
    document.addEventListener("keyup", escKeyHandler);

    return () => document.removeEventListener("keyup", escKeyHandler);
  }, []);

  const escKeyHandler = (event: any) => {
    if (event.keyCode === 27) {
      removeModal();
    }
  };

  const clickOutsideHandler = (event: any) => {
    if (event.target.classList.contains("modal")) {
      removeModal();
    }
  };

  return (
    <div
      className="modal modalSmall d-flex align-items-center justify-content-center ce"
      onClick={clickOutsideHandler}
    >
      <div className="modalSmallContent pa-10 d-flex align-items-center justify-content-center flex-column p-r">
        <div className="header d-flex align-items-center justify-content-center">
          <h5 className="text-color-white f-s-18 f-w-5">{title}</h5>
          <span className="center-a-v" onClick={removeModal}>
            {renderIcon("CloseIcon", "closeIcon", 16)}
          </span>
        </div>
        <h3 className="f-s-16 f-w-5 mb-30 text-center">
          Došlo je do sledećih problema:
        </h3>
        <div className="list">
          {errors.map((e) => {
            return (
              <li key={e} className="f-s-12 f-w-5 text-center">
                {e}
              </li>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ModalErrors;
