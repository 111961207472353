import { InitialState } from "../InitialState/InitialState";

export const ProfakturePretraga = {
  buttons: [
    { icon: "DocumentIcon", text: "Prikaži", type: "document" },
    { icon: "DeleteDocumentIcon", text: "Obriši", type: "delete" },
    { icon: "DocumentIcon", text: "Kopija", type: "document", mod: "copy" }
  ],
  state: {
    datumOd: undefined,
    datumDo: undefined,
    loadMore: false,
    pageNumber: 0,
    sortBy: {
      name: "datum",
      direction: true
    },
    filter: {
      od: "",
      do: "",
      brfakture: "",
      klijent: "",
      grad: "",
      iznos: "",
      currentpage: 1
    },
    ...InitialState,
    dropDown: {
      name: "statusDropdown",
      open: false,
      options: [
        "Status",
        "Djelimično pl. van valute",
        "Neplaćeno u valuti",
        "Neplaćeno van valute",
        "Plaćeno"
      ],
      reference: "dropDown",
      title: "Status"
    }
  },
  table: {
    thead: {
      firstRow: [
        "Status",
        "Datum od",
        "Datum do",
        "Br. fakture",
        "Kupac",
        "Grad",
        "Iznos",
        "Dug"
      ],
      secondRow: [
        { name: "", property: "status" },
        { name: "DateInput", property: "datumOd" },
        { name: "DateInput", property: "datumDo" },
        { name: "TextInput", property: "brfakture" },
        { name: "TextInput", property: "klijent" },
        { name: "TextInput", property: "grad" },
        { name: "", property: "iznos" },
        { name: "", property: "dug" }
      ]
    }
  }
};
