import { InitialState } from "../InitialState/InitialState";

export const PretragaUslugeNav = [
  { icon: "SearchIcon", title: "Pretraga usluga" }
];

export const PretragaUsluge = {
  buttons: [
    { icon: "DocumentIcon", text: "Prikaži", type: "document" },
    { icon: "DocumentIcon", text: "Kopija", type: "document", mod: "copy" }
  ],
  state: {
    datumOd: undefined,
    datumDo: undefined,
    loadMore: false,
    pageNumber: 0,
    modal: false,
    modalType: "",
    modalID: "",
    sortBy: {
      name: "datum",
      direction: true
    },

    filter: {
      od: "",
      do: "",
      brfakture: "",
      klijent: "",
      grad: "",
      iznos: "",
      currentpage: 1
    },
    fakturaType: 0,
    optionsFakture: [
      {
        id: 0,
        naziv: "Izlazne fakture"
      },
      {
        id: 1,
        naziv: "Profakture"
      },
      {
        id: 2,
        naziv: "Ulazne fakture"
      }
    ],
    optionsFaktureId: 0,
    optionsFaktureName: "Izlazne Fakture",
    optionsLinije: [],
    optionsLinijeId: 0,
    optionsLinijeName: "",
    ...InitialState,
    dropDown: {
      name: "statusDropdown",
      open: false,
      options: [
        "Status",
        "Djelimično pl. van valute",
        "Neplaćeno u valuti",
        "Neplaćeno van valute",
        "Plaćeno"
      ],
      reference: "dropDown",
      title: "Status"
    }
  },
  table: {
    thead: {
      firstRow: ["Datum od", "Datum do", "Br. fakture", "Kupac", "Iznos"],
      secondRow: [
        { name: "DateInput", property: "datumOd" },
        { name: "DateInput", property: "datumDo" },
        { name: "TextInput", property: "brfakture" },
        { name: "TextInput", property: "klijent" },
        { name: "", property: "iznos" }
      ]
    }
  }
};
