import React, { Component, ChangeEvent } from "react";
import { IPage } from "../../interfaces/pageInterfaces";
import NavbarLogo from "../../components/Navbar/NavbarLogo";
import TextInput from "../../components/Inputs/TextInput/TextInput";
import { postLoginUser } from "../../api/postFunctions";
import axios from "axios";
import ModalSmall from "../../components/modals/ModalSmall/ModalSmall";

interface ILoginProps extends IPage {
  loginUser: (
    token: string,
    firma: string,
    logo: string,
    adresa: string,
    grad: string,
    telefon: string,
    pib: string,
    pdv: string,
    ziro_racun: string,
    email: string,
    websajt: string
  ) => void;
}

interface ILoginState {
  username: string;
  password: string;
  modal: boolean;
}

type StateKeys = keyof ILoginState;

class Login extends Component<ILoginProps> {
  state: ILoginState = {
    username: "",
    password: "",
    modal: false
  };

  showModal = () => {
    this.setState({ modal: true });
  };
  removeModal = () => {
    this.setState({ modal: false });
  };

  renderModal = () => {
    return (
      <ModalSmall
        question="Pogrešno korisničko ime ili lozinka"
        buttons={["load"]}
        options={["U redu"]}
        id={""}
        onClick={() => false}
        removeModal={this.removeModal}
        title="Greška"
      />
    );
  };

  handleInputChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    name: StateKeys
  ) => {
    e.persist();
    this.setState(prevState => ({
      ...prevState,
      [name]: e.target.value
    }));
  };
  axiosCancelSource = axios.CancelToken.source();

  login = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { username, password } = this.state;
    postLoginUser(
      { uname: username, upass: password },
      this.axiosCancelSource
    ).then(res => {
      if (res === false) {
        this.showModal();
      } else {
        this.props.loginUser(
          res.token,
          res.firma,
          res.logo,
          res.adresa,
          res.grad,
          res.telefon,
          res.pib,
          res.pdv,
          res.ziro_racun,
          res.email,
          res.websajt
        );
      }
    });
  };
  componentWillUnmount() {
    this.axiosCancelSource.cancel("Component unmounted.");
  }
  render() {
    const s = this.state;

    return (
      <div className="login d-flex align-items-center justify-content-center flex-column">
        <div className="content">
          <div className="logo mb-40">
            <NavbarLogo width="300" />
          </div>
          <div className="welcome text-center mb-40">
            <img src="./images/welcome.png" alt="welcome" />
          </div>
          <form
            className="form mb-40"
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => this.login(e)}
          >
            <div className="inputContainer mb-20">
              <TextInput
                placeholder="Korisničko ime"
                value={s.username}
                onChange={this.handleInputChange}
                name="username"
              />
            </div>
            <div className="inputContainer">
              <TextInput
                placeholder="Lozinka"
                value={s.password}
                onChange={this.handleInputChange}
                name="password"
                type="password"
              />
            </div>
            <div className="btnContainer text-center mt-20">
              <button className="loginButton" type="submit">
                Uloguj se
              </button>
            </div>
          </form>
        </div>
        {this.state.modal ? this.renderModal() : ""}
      </div>
    );
  }
}

export default Login;
