import React from "react";

type TicksType = {
  tickList: Array<number>;
};

export const ChartTicks = ({ tickList }: TicksType) => {
  return (
    <div className="chartTicks d-flex justify-content-between mt-5">
      {tickList.map((e: number, index: number) => {
        return (
          <span className="text-color-white" key={index}>
            {e < 1000 ? e : e / 1000 + "k"}
          </span>
        );
      })}
    </div>
  );
};
