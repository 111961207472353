import React, {Component} from "react";
import { renderIcon } from "../../Icons/Icons";
import ModalSmall from "../ModalSmall/ModalSmall";
import axios from "axios";
import {postDeleteAlarms, postSeenAlarms} from "../../../api/postFunctions";
import {formatDate1} from "../../../functions/formaters";

type ModalSmallType = {
    alarmi: Array<{
        naziv: string;
        datum: string;
        seen: number;
    }>;
    removeModal: () => void;
    api:string;
};

class ModalAlarm extends Component<ModalSmallType> {
    state = {
        modal: false,
        type: ""
    };
    axiosCancelSource = axios.CancelToken.source();

    deleteAlarms = () => {
        postDeleteAlarms("1", this.axiosCancelSource, this.props.api).then(
            res => {
                if (res[0] === "deleted") {
                   this.props.removeModal();
                   this.removeModal();
                    window.location.reload(false);
                }
            }
        );
    };

    seenAlarms = () => {
        postSeenAlarms("1", this.axiosCancelSource, this.props.api).then(
            res => {
                if (res === "success") {
                   this.props.removeModal();
                   this.removeModal();
                    window.location.reload(false);
                }
            }
        );
    };

    showModal = () => {
        this.setState({ modal: true,  type: "delete"});
    };

    seenAll = () => {
        this.setState({ modal: true, type: "seen" });
    }

    removeModal = () => {
        this.setState({ modal: false, type: "" });
    };

    renderModal = () => {
        if(this.state.type === "seen"){
            return (
                <ModalSmall
                    question="Da li ste sigurni da želite da sve označite kao pročitane?"
                    buttons={["delete", "document"]}
                    options={["Da", "Ne"]}
                    id={""}
                    onClick={this.seenAlarms}
                    removeModal={this.removeModal}
                    title="Čitanje alarma"
                />
            );

        } else if(this.state.type === "delete"){
            return (
                <ModalSmall
                    question="Da li ste sigurni da želite da obrišete sve alarme?"
                    buttons={["delete", "document"]}
                    options={["Da", "Ne"]}
                    id={""}
                    onClick={this.deleteAlarms}
                    removeModal={this.removeModal}
                    title="Brisanje alarma"
                />
            );
        }
    };



    render() {
    return (
            <div
                className="modal modalAlarm modalSmall d-flex align-items-center justify-content-center ce"
            >
                <div className="modalSmallContent d-flex flex-column p-r">
                    <div className="header d-flex">
                        {" "}
                    </div>
                    <div className="row pa-10 navBarAlarmBorder">
                        <div className="col-6 f-s-12 f-w-8 cursor-pointer" onClick={this.seenAll}>
                            Pročitaj sve
                        </div>
                        <div className="col-6 text-right f-s-12 f-w-8 cursor-pointer" style={{color: "#DF4711"}} onClick={this.showModal}>
                            Obriši sve
                        </div>
                    </div>
                    <div className={this.props.alarmi.length > 7 ? "overflow-Y" :"" }>
                    {this.props.alarmi.length > 0
                        ? this.props.alarmi.map((alarm, index) => {
                            if(alarm.seen === 0){
                            return (
                                <div className="row pa-10 navBarAlarmBorder" key={index}>
                                    <div className="col-8 f-s-12 f-w-8 d-flex">
                                        {renderIcon("BellIcon", "h-20")}
                                        <div className="ml-5">
                                            {alarm.naziv}
                                        </div>
                                    </div>
                                    <div className="col-4 text-right f-s-10 f-w-4 ">
                                        {formatDate1(alarm.datum)}.
                                    </div>
                                </div>
                            )
                            }})
                        : <h2 className="ml-20 mt-20">Nemate nove alarme.</h2>
                    }
                    </div>
                </div>
                {this.state.modal ? this.renderModal() : ""}
            </div>
        );
    };

}


export default ModalAlarm;
