import React, { Component } from "react";
import { renderIcon } from "../../../components/Icons/Icons";
import TableHead from "./TableHead/TableHead";
import { getFaktureAnalitickaKartica } from "../../../api/getFunctions";
import axios from "axios";
import DateInput from "../../../components/Inputs/DateInput/DateInput";
import {formatDate, formatDate1} from "../../../functions/formaters";
import TableBody from "./TableBody/TableBody";
import { sortByHelper } from "../functions/functions";
import {saveTable} from "../../../functions/fileGenerators";
import {noScrollBody} from "../../../functions/functions";
import GeneratedAnaliticka from "../../../components/fileComponents/GeneratedAnaliticka";

type AnalitickaKarticaTypeProps = {
  token: string;
  kupac: number;
  dobavljac: number;
  id: string;
  klijent: any
};

type AnalitickaKarticaTypeState = {
  datumKupac: {
    od: Date;
    do: Date;
  };
  datumDobavljac: {
    od: Date;
    do: Date;
  };
  faktureKupci: Array<{
    datum: string;
    napomena: string;
    iznos: string;
    uplaceno: string;
    dug: string;
  }> | null;
  faktureDobavljaci: Array<{
    datum: string;
    napomena: string;
    iznos: string;
    uplaceno: string;
    dug: string;
  }> | null;
  modal: { show: boolean };
  type: {klijent: string};
};

type StateKeys = keyof AnalitickaKarticaTypeState;

class AnalitickaKartica extends Component<
  AnalitickaKarticaTypeProps,
  AnalitickaKarticaTypeState
> {
  initialDate = new Date();
  firstInTheMonth = new Date(
    this.initialDate.getFullYear(),
    this.initialDate.getMonth(),
    1
  );
  state: AnalitickaKarticaTypeState = {
    datumKupac: {
      od: this.firstInTheMonth,
      do: this.initialDate
    },
    datumDobavljac: {
      od: this.firstInTheMonth,
      do: this.initialDate
    },
    faktureKupci: null,
    faktureDobavljaci: null,
    modal: { show: false },
    type: {klijent: ""}
  };

  axiosCancelSource = axios.CancelToken.source();
  componentDidMount() {
    this.getFakture();
  }
  componentDidUpdate(
    prevProps: AnalitickaKarticaTypeProps,
    prevState: AnalitickaKarticaTypeState
  ) {
    if (prevProps.id !== this.props.id) {
      this.getFakture();
    }
  }

  getFakture = () => {
    if (this.props.kupac === 1) {
      const filter = {
        od: formatDate(this.state.datumKupac.od),
        do: formatDate(this.state.datumKupac.do)
      };
      getFaktureAnalitickaKartica(
        0,
        this.axiosCancelSource,
        this.props.token,
        filter,
        undefined,
        {
          klijentid: this.props.id
        }
      ).then(faktureKupci => this.setState({ faktureKupci }));
    }
    if (this.props.dobavljac === 1) {
      const filter = {
        od: formatDate(this.state.datumDobavljac.od),
        do: formatDate(this.state.datumDobavljac.do)
      };
      getFaktureAnalitickaKartica(
        2,
        this.axiosCancelSource,
        this.props.token,
        filter,
        undefined,
        {
          klijentid: this.props.id
        }
      ).then(faktureDobavljaci => this.setState({ faktureDobavljaci }));
    }
  };

  isMyType(x: string): x is StateKeys {
    return this.state.hasOwnProperty(x);
  }

  openModal = (type:string) => {
    this.setState(
        (prevState: AnalitickaKarticaTypeState) => ({
          ...prevState,
          modal: {
            show: true,
          },
          type: {
            klijent: type
          }
        }))
  }

  closeModal = () => {
    this.setState(
        (prevState: AnalitickaKarticaTypeState) => ({
          ...prevState,
          modal: {
            show: false
          }
        }))
  }

  handleDateChange = (datum: Date | undefined, name: string) => {
    const names = name.split("/");

    const object: string = names[0];
    const property: string = names[1];

    if (this.isMyType(object)) {
      this.setState(
        (prevState: AnalitickaKarticaTypeState) => ({
          ...prevState,
          [object]: {
            ...prevState[object],
            [property]: datum
          },
        }),
        this.getFakture
      );
    }
  };

  handleSortBy = (
    e: React.MouseEvent<HTMLTableHeaderCellElement>,
    object: string,
    name: string
  ) => {
    const element = e.currentTarget.parentNode!.querySelector("[data-sorted]");

    if (element) {
      if (element === e.currentTarget) {
        const attr = e.currentTarget.getAttribute("data-sorted");
        const newAttr = attr === "true" ? "false" : "true";
        e.currentTarget.setAttribute("data-sorted", newAttr);
        e.currentTarget.classList.toggle("rotate");
        const direction = newAttr === "true" ? true : false;
        if (object === "kupci" && this.state.faktureKupci) {
          const sorted = sortByHelper(
            this.state.faktureKupci,
            "",
            direction,
            object,
            name
          );
          this.setState({ faktureKupci: [...sorted] });
        } else if (object === "dobavljaci" && this.state.faktureDobavljaci) {
          const sorted = sortByHelper(
            this.state.faktureDobavljaci,
            "",
            direction,
            object,
            name
          );
          this.setState({ faktureDobavljaci: [...sorted] });
        }
      } else {
        element.removeAttribute("data-sorted");
        e.currentTarget.setAttribute("data-sorted", "true");
        if (object === "kupci" && this.state.faktureKupci) {
          const sorted = sortByHelper(
            this.state.faktureKupci,
            "",
            true,
            object,
            name
          );
          this.setState({ faktureKupci: [...sorted] });
        } else if (object === "dobavljaci" && this.state.faktureDobavljaci) {
          const sorted = sortByHelper(
            this.state.faktureDobavljaci,
            "",
            true,
            object,
            name
          );
          this.setState({ faktureDobavljaci: [...sorted] });
        }
      }
    } else {
      e.currentTarget.setAttribute("data-sorted", "true");
      if (object === "kupci" && this.state.faktureKupci) {
        const sorted = sortByHelper(
          this.state.faktureKupci,
          "",
          true,
          object,
          name
        );
        this.setState({ faktureKupci: [...sorted] });
      } else if (object === "dobavljaci" && this.state.faktureDobavljaci) {
        const sorted = sortByHelper(
          this.state.faktureDobavljaci,
          "",
          true,
          object,
          name
        );
        this.setState({ faktureDobavljaci: [...sorted] });
      }
    }
  };

  toggleLoading = (loading: boolean) => {
    noScrollBody(loading);
  };



  render() {
    return (
      <div className="analitickaKartica">
        {this.props.kupac === 1 && (
          <div className="kartica mt-40 pa-10 p-r">
            <div className="row mb-20">
              <div className="col-sm-2">
                <label htmlFor="" className="f-s-14">
                  Od
                </label>
                <DateInput
                  date={this.state.datumKupac.od}
                  name="datumKupac/od"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="col-sm-2">
                <label htmlFor="" className="f-s-14 ">
                  Do
                </label>
                <DateInput
                  date={this.state.datumKupac.do}
                  name="datumKupac/do"
                  onChange={this.handleDateChange}
                />
              </div>
            </div>
            <div className="scrollable">
              <div className="title">
                <div className="row mb-20">
                  <div className="col-sm-2 d-flex align-items-center">
                    <h3 className="f-s-14 f-w-5 text-transform-uppercase line-height-1">
                      Kartica Kupca
                    </h3>
                  </div>
                  <div className="col-sm-8 d-flex align-items-center">
                    <span className="line" />
                  </div>
                  <div className="col-sm-2">
                    <div className="btn pr-10">
                      <button className="d-flex align-items-center justify-content-center mr-10"
                              onClick={() => this.openModal("kupac")}>
                        {renderIcon("PDFIcon", "mr-10", 20)}{" "}
                        <span className="f-s-14 text-transform-uppercase line-height-1">
                          PDF
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <table id="analitickaKartica" >
                <TableHead
                  imena={["Datum", "Opis", "Fakturisano", "Plaćeno", "Saldo"]}
                  sortNames={["datum", "napomena", "iznos", "uplaceno", "dug"]}
                  sortBy={this.handleSortBy}
                  kupci
                />
                <TableBody data={this.state.faktureKupci} />
              </table>
            </div>
          </div>
        )}
        {this.props.dobavljac === 1 && (
          <div className="kartica mt-40 pa-10 p-r">
            <div className="row mb-20">
              <div className="col-sm-2">
                <label htmlFor="" className="f-s-14">
                  Od
                </label>
                <DateInput
                  date={this.state.datumDobavljac.od}
                  name="datumDobavljac/od"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="col-sm-2">
                <label htmlFor="" className="f-s-14 ">
                  Do
                </label>
                <DateInput
                  date={this.state.datumDobavljac.do}
                  name="datumDobavljac/do"
                  onChange={this.handleDateChange}
                />
              </div>
            </div>
            <div className="scrollable">
              <div className="title">
                <div className="row mb-20">
                  <div className="col-sm-2">
                    <h3 className="f-s-14 f-w-5 text-transform-uppercase ">
                      Kartica dobavljača
                    </h3>
                  </div>
                  <div className="col-sm-8 d-flex align-items-center">
                    <span className="line" />
                  </div>
                  <div className="col-sm-2">
                    <div className="btn pr-10">
                      <button className="d-flex align-items-center justify-content-center mr-10"
                              onClick={() => this.openModal("dobavljac")}>
                        {renderIcon("PDFIcon", "mr-10", 20)}{" "}
                        <span className="f-s-14 text-transform-uppercase line-height-1">
                          PDF
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <table>
                <TableHead
                  imena={["Datum", "Opis", "Fakturisano", "Plaćeno", "Saldo"]}
                  sortNames={["datum", "napomena", "iznos", "uplaceno", "dug"]}
                  sortBy={this.handleSortBy}
                />
                <TableBody data={this.state.faktureDobavljaci} />
              </table>
            </div>
          </div>
        )}

        {this.state.modal.show &&
          <GeneratedAnaliticka
              klijent={this.props.klijent}
              kupac={this.state.type.klijent === "kupac" ? 1 : 0}
              dobavljac={this.state.type.klijent !== "kupac" ? 1 : 0}
              datumKupac={formatDate1(this.state.datumKupac.od) + ". - " + formatDate1(this.state.datumKupac.do) + "."}
              datumDobavljac= {formatDate1(this.state.datumDobavljac.od) + ". - " + formatDate1(this.state.datumDobavljac.do) + "."}
              removeModal={this.closeModal}
              faktureKupci={ this.state.faktureKupci}
              faktureDobavljaci={ this.state.faktureDobavljaci}
          />
        }
      </div>
    );
  }
}

export default AnalitickaKartica;
