import React, { ChangeEvent, FormEvent } from "react";
import { ITextInput } from "../../../interfaces/inputInterfaces";
import NumberFormat from "react-number-format";

const TextInput = ({
  placeholder,
  value,
  onChange,
  name,
  id,
  number,
  readOnly,
  subname,
  index,
  min,
    max,
  className,
  onBlur,
  step,
  type,
  format
}: ITextInput) => {
  if (type !== "password" && type !== "formated" && type !== "number") {
    return (
      <div className="inputWrapper">
        <input
          type={`${number !== true ? "text" : "number"}`}
          placeholder={placeholder}
          value={value || ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange(e, name, subname, index)
          }
          className={`${
            !className ? "prl-10" : "pl-10 pr-20"
          } f-s-12 ${className}`}
          readOnly={readOnly}
          id={id}
          min={min}
          max={max}
          onBlur={(e: FormEvent) => onBlur && onBlur(e)}
          step={step ? step : "1"}
        />
      </div>
    );
  } else if (type === "password") {
    return (
      <div className="inputWrapper">
        <input
          type="password"
          placeholder={placeholder}
          value={value || ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange(e, name, subname, index)
          }
          className={`${
            !className ? "prl-10" : "pl-10 pr-20"
          } f-s-12 ${className}`}
          readOnly={readOnly}
          id={id}
          min={min}
          onBlur={(e: FormEvent) => onBlur && onBlur(e)}
          step={step ? step : "1"}
        />
      </div>
    );
  } else if (type === "number") {
    return (
        <div className="inputWrapper">
          <input
              type="number"
              placeholder={placeholder}
              value={value || ""}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  onChange(e, name, subname, index)
              }
              className={`${
                  !className ? "prl-10" : "pl-10 pr-20"
              } f-s-12 ${className}`}
              readOnly={readOnly}
              id={id}
              min={min}
              onBlur={(e: FormEvent) => onBlur && onBlur(e)}
              step={step ? step : "1"}
          />
        </div>
    );
  } else {
    return (
      <div className="inputWrapper">
        <NumberFormat
          format={format}
          mask="*"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange(e, name, subname, index)
          }
          value={value || ""}
          placeholder={placeholder}
          className={`${
            !className ? "prl-10" : "pl-10 pr-20"
          } f-s-12 ${className}`}
        />
      </div>
    );
  }
};

export default TextInput;