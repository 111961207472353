import { InitialState } from "../InitialState/InitialState";

export const AlarmiNav = [
    { icon: "SearchIcon", title: "Pretraži alarme" },
    { icon: "AddDocumentIcon", title: "Dodaj alarm" }
];

export const PretraziAlarme = {
    buttons: [{ icon: "DeleteDocumentIcon", text: "Obriši", type: "delete" }],
    state: {
        datumOd: undefined,
        datumDo: undefined,
        loadMore: false,
        pageNumber: 0,
        firstCheckBox: true,
        secondCheckBox: true,
        sortBy: {
            name: "datum",
            direction: true
        },
        filter: {},
        ...InitialState,
        dropDown: {
            name: "statusDropdown",
            open: false,
            options: [
                "Status",
                "Djelimično pl. van valute",
                "Neplaćeno u valuti",
                "Neplaćeno van valute",
                "Plaćeno"
            ],
            reference: "dropDown",
            title: "Status"
        }
    },
    table: {
        thead: {
            firstRow: ["Naziv", "Od datuma","Do datuma", "Obavještenje"],
            secondRow: [
                { name: "TextInput", property: "naziv" },
                { name: "DateInput", property: "datumOd" },
                { name: "DateInput", property: "datumDo" },
                { name: "TextInput", property: "obavjestenje" }
            ]
        }
    }
};
