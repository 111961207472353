import React, { Component } from "react";
import axios from "axios";

import { IKlijent } from "../../interfaces/pageInterfaces";
import {
  getKlijent,
  getKlijentStats,
  getFakture,
  getUgovori
} from "../../api/getFunctions";
import { KlijentNav } from "./KlijentNav/KlijentNav";
import KlijentStats from "./KlijentStats/KlijentStats";
import KlijentInfo from "./KlijentInfo/KlijentInfo";
import Loading from "../../components/Loading/Loading";
import KlijentData from "./KlijentData/KlijentData";
import CustomButton from "../../components/custom/CustomButton/CustomButton";
import { sortItems } from "../../functions/functions";
import { sortByHelper } from "./functions/functions";
import AnalitickaKartica from "./AnalitickaKartica/AnalitickaKartica";
import { BASE_URL } from "../../api/baseUrl";

export type KlijentType = {
  id: string;
  naziv_firme: string;
  pib: string;
  grad: string;
  adresa: string;
  drzava: string;
  kontakt_osoba: string;
  telefon: string;
  email: string;
  kupac: string;
  dobavljac: string;
  ziro_racun: string;
  pdv_broj: string;
  web: string;
};

interface IKlijentState {
  klijent: KlijentType | null;
  shown: number;
  klijentData: Array<{
    datum: string;
    br_fakture: string;
    iznos: string;
  }> | null;
  klijentUgovor: Array<{
    datum_zak: string;
    datum_vazenja: string;
    vrijednost: string;
  }> | null;
  klijentStats: {
    kupac: {
      fakturisano: string;
      placeno: string;
      neplaceno: string;
    };
    dobavljac: {
      fakturisano: string;
      placeno: string;
      neplaceno: string;
    };
  } | null;
  loadMoreFakture: boolean;
  filterFakture: {
    currentpage: number;
  };
  sortBy: {
    name: string;
    direction: boolean;
  };
  sortByUgovor: {
    name: string;
    direction: boolean;
  };
}

type StateKeys = keyof IKlijentState;

class Klijent extends Component<IKlijent, IKlijentState> {
  state: IKlijentState = {
    klijent: null,
    klijentStats: null,
    klijentData: null,
    klijentUgovor: null,
    shown: 0,
    loadMoreFakture: false,
    filterFakture: {
      currentpage: 1
    },
    sortBy: {
      name: "",
      direction: true
    },
    sortByUgovor: {
      name: "",
      direction: true
    }
  };
  axiosCancelSource = axios.CancelToken.source();
  componentDidMount() {
    const url = `${BASE_URL}/klijenti.php?id=${this.props.match.params.name}`;
    getKlijent(url, this.axiosCancelSource, this.props.api).then(klijent =>
      this.setState({ klijent: klijent[0] }, () => {
        if (this.state.klijent) {
          getKlijentStats(
            this.state.klijent.id,
            this.axiosCancelSource,
            this.props.api
          ).then(klijentStats => this.setState({ klijentStats }));
          this.handleGetFakture();
          this.handleGetUgovor();
        }
      })
    );
  }

  handleSortBy = (
    e: React.MouseEvent<HTMLElement>,
    object: string,
    name: string
  ) => {
    var elements = e.currentTarget.parentNode!.querySelector(
      ".sortDirection.d-block"
    );
    const element = e.currentTarget.querySelector(".sortDirection");
    if (elements && elements !== element) {
      elements.classList.remove("d-block");
      elements.classList.remove("rotate");
      elements.classList.add("d-none");
    }
    if (element) {
      if (element.classList.contains("d-block")) {
        element.classList.toggle("rotate");
      } else {
        element.classList.remove("d-none");
        element.classList.add("d-block");
      }
    }

    const sortName = name;
    if (object === "klijentData" && this.state.klijentData) {
      const data = this.state.klijentData;
      if (sortName === this.state.sortBy.name) {
        this.setState(
          (prevState: IKlijentState) => ({
            sortBy: {
              ...prevState.sortBy,
              direction: !prevState.sortBy.direction
            }
          }),
          () => {
            const sortedArray: any = sortByHelper(
              data,
              null,
              this.state.sortBy.direction,
              object,
              sortName
            );
            this.setState((prevState: IKlijentState) => ({
              klijentData: [...sortedArray],
              sortBy: {
                ...prevState.sortBy,
                name: sortName
              }
            }));
          }
        );
      } else {
        this.setState(
          (prevState: IKlijentState) => ({
            sortBy: {
              ...prevState.sortBy,
              direction: true
            }
          }),
          () => {
            const sortedArray: any = sortByHelper(
              data,
              null,
              this.state.sortBy.direction,
              object,
              sortName
            );
            this.setState((prevState: IKlijentState) => ({
              klijentData: [...sortedArray],
              sortBy: {
                ...prevState.sortBy,
                name: sortName
              }
            }));
          }
        );
      }
    } else if (object === "klijentUgovor" && this.state.klijentUgovor) {
      const data = this.state.klijentUgovor;
      if (sortName === this.state.sortByUgovor.name) {
        this.setState(
          (prevState: IKlijentState) => ({
            sortByUgovor: {
              ...prevState.sortBy,
              direction: !prevState.sortByUgovor.direction
            }
          }),
          () => {
            const sortedArray: any = sortByHelper(
              data,
              null,
              this.state.sortByUgovor.direction,
              object,
              sortName
            );
            this.setState((prevState: IKlijentState) => ({
              klijentUgovor: [...sortedArray],
              sortByUgovor: {
                ...prevState.sortByUgovor,
                name: sortName
              }
            }));
          }
        );
      } else {
        this.setState(
          (prevState: IKlijentState) => ({
            sortByUgovor: {
              ...prevState.sortByUgovor,
              direction: true
            }
          }),
          () => {
            const sortedArray: any = sortByHelper(
              data,
              null,
              this.state.sortByUgovor.direction,
              object,
              sortName
            );
            this.setState((prevState: IKlijentState) => ({
              klijentUgovor: [...sortedArray],
              sortByUgovor: {
                ...prevState.sortByUgovor,
                name: sortName
              }
            }));
          }
        );
      }
    }
  };

  handleGetFakture = (loadMore?: boolean) => {
    if (this.state.klijent && this.state.shown < 2) {
      getFakture(
        this.state.shown === 0
          ? this.state.shown
          : this.state.shown === 1
          ? this.state.shown + 1
          : 0,
        this.axiosCancelSource,
        this.props.api,
        this.state.filterFakture,
        0,
        { klijentid: this.state.klijent.id }
      ).then(klijentData => {
        if (!loadMore) {
          this.setState({
            klijentData,
            loadMoreFakture: klijentData.length === 50
          });
        } else {
          this.setState((prevState: IKlijentState) => ({
            klijentData: [...prevState.klijentData, ...klijentData],
            loadMoreFakture: klijentData.length === 50
          }));
        }
      });
    }
  };

  handleGetUgovor = (loadMore?: boolean) => {
    if (this.state.klijent) {
      getUgovori(this.axiosCancelSource, this.props.api, undefined, {
        klijentid: this.state.klijent.id
      }).then(klijentUgovor => {
        this.setState({
          klijentUgovor
        });
      });
    }
  };

  componentDidUpdate(prevProps: IKlijent, prevState: IKlijentState) {
    if (this.state.klijent && prevState.klijent !== this.state.klijent) {
      this.props.changePageTitle(this.state.klijent.naziv_firme);
    }
  }
  componentWillUnmount() {
    this.axiosCancelSource.cancel("Component unmounted.");
  }

  handleNavChange = (e: React.MouseEvent<HTMLElement>, shown: number) => {
    const nav: any = e.currentTarget.parentNode;
    nav.querySelectorAll("button").forEach((el: any) => {
      el.classList.remove("active");
    });
    e.currentTarget.classList.add("active");
    this.setState({ shown }, this.handleGetFakture);
  };

  loadMore = (id?: string) => {
    this.setState(
      (prevState: any) => ({
        ...prevState,
        filterFakture: {
          currentpage: prevState.filterFakture.currentpage + 1
        }
      }),
      () => this.handleGetFakture(true)
    );
  };

  renderLoadMore = (load: boolean) => {
    if (load) {
      return (
        <div className="loadMore text-center d-flex align-items-center justify-content-center">
          <CustomButton
            text="Učitaj još"
            type="load"
            onClick={(ev: React.MouseEvent<HTMLElement>) => this.loadMore()}
            id="1"
          />
        </div>
      );
    }
  };

  render() {
    const { klijent, shown, klijentStats } = this.state;
    if (klijent && shown < 2) {
      return (
        <div className="klijentContainer">
          <KlijentNav
            handleNavChange={this.handleNavChange}
            kupac={parseInt(klijent.kupac)}
            dobavljac={parseInt(klijent.dobavljac)}
          />
          <div className="klijentContent mt-40">
            <div className="container">
              <div className="row">
                <div className="col-sm-6">
                  <KlijentStats klijentStats={klijentStats} shown={shown} />
                </div>
                <div className="col-sm-6">
                  <KlijentInfo {...klijent} />
                </div>
              </div>
              <div className="row mt-30">
                <div className="col-sm-6">
                  <h3 className="f-s-20 f-w-4 text-transform-uppercase mb-10">
                    Fakture
                  </h3>
                  <KlijentData
                    data={this.state.klijentData}
                    loadMore={this.renderLoadMore}
                    loadMoreCondition={this.state.loadMoreFakture}
                    sortBy={this.handleSortBy}
                  />
                </div>
                <div className="col-sm-6">
                  <h3 className="f-s-20 f-w-4 text-transform-uppercase mb-10">
                    Ugovori
                  </h3>
                  <KlijentData
                    dataUgovor={this.state.klijentUgovor}
                    loadMore={this.renderLoadMore}
                    loadMoreCondition={this.state.loadMoreFakture}
                    sortBy={this.handleSortBy}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (klijent && shown === 2) {
      return (
        <div className="klijentContainer">
          <KlijentNav
            handleNavChange={this.handleNavChange}
            kupac={parseInt(klijent.kupac)}
            dobavljac={parseInt(klijent.dobavljac)}
          />
          <div className="klijentContent">
            <AnalitickaKartica
              token={this.props.api}
              kupac={parseInt(klijent.kupac)}
              dobavljac={parseInt(klijent.dobavljac)}
              id={klijent.id}
              klijent={this.state.klijent}
            />
          </div>
        </div>
      );
    } else {
      return <Loading />;
    }
  }
}

export default Klijent;
