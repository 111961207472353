import React from "react";
import Loading from "../../../../components/Loading/Loading";
import {formatDate1, formatMoney} from "../../../../functions/formaters";

type TableBodyType = {
  data: Array<{
    datum: string;
    napomena: string;
    iznos: string;
    uplaceno: string;
    dug: string;
  }> | null;
};

const TableBody = ({ data }: TableBodyType) => {
  if (data) {
    if (data.length > 0) {
      const ukupnoIznos = data
        .map(e => {
          return parseFloat(e.iznos);
        })
        .reduce((a: number, b: number) => a + b);
      const ukupnoPlaceno = data
        .map(e => {
          return parseFloat(e.uplaceno);
        })
        .reduce((a: number, b: number) => a + b);
      const ukupnoDug = data
        .map(e => {
          return parseFloat(e.dug);
        })
        .reduce((a: number, b: number) => a + b);
      return (
        <tbody className="pt-10">
          {data.map((e, index) => {
            return (
              <tr key={index}>
                <td className="text-center f-s-12">{formatDate1(e.datum) + "."}</td>
                <td className="text-center f-s-12">{e.napomena}</td>
                <td className="text-center f-s-12 green">
                  {formatMoney(e.iznos)} €
                </td>
                <td className="text-center f-s-12 green">
                  {formatMoney(e.uplaceno)} €
                </td>
                <td className="text-center f-s-12 red">
                  {formatMoney(e.dug)} €
                </td>
              </tr>
            );
          })}
          <tr style={{border:"0.5px solid #ccc"}}></tr>
          <tr>
            <td colSpan={2} />
            <td className="text-center f-s-12 green">
              {formatMoney(ukupnoIznos)} €
            </td>
            <td className="text-center f-s-12 green">
              {formatMoney(ukupnoPlaceno)} €
            </td>
            <td className="text-center f-s-12 red">
              {formatMoney(ukupnoDug)} €
            </td>
          </tr>
        </tbody>
      );
    } else {
      return (
        <tbody className="pt-10">
          <tr>
            <td className="text-center f-s-12" colSpan={5}>
              Nema rezultata
            </td>
          </tr>
        </tbody>
      );
    }
  } else {
    return <Loading small />;
  }
};

export default TableBody;
