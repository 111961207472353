import React from "react";
import HorizontalBarChart from "../../../components/Charts/HorizontalBar/HorizontalBarChart";

type BarType = {
  list: Array<any>;
  type: number;
};

const BarChartWrapper = ({ list, type }: BarType) => {
  if (type === 0) {
    return (
      <>
        <HorizontalBarChart
          legends={[
            "Ukupna vrijednost faktura",
            "Vrijednost plaćenih faktura",
            "Vrijednost neplaćenih u valuti",
            "Vrijednost neplaćenih van valute"
          ]}
          colors={["#7bae45", "#ed9d10", "#FCD202", "#dd3131"]}
          list={list[0]}
          type={type}
          title="izlazne fakture"
        />
        <HorizontalBarChart
          legends={[
            "Ukupna vrijednost faktura",
            "Vrijednost plaćenih faktura",
            "Vrijednost neplaćenih u valuti",
            "Vrijednost neplaćenih van valute"
          ]}
          colors={["#7bae45", "#ed9d10", "#FCD202", "#dd3131"]}
          list={list[1]}
          type={type}
          title="ulazne fakture"
        />
      </>
    );
  } else if (type === 1) {
    return (
      <>
        <HorizontalBarChart
          legends={[
            "Ukupna vrijednost potraživanja/dugovanja",
            "Ukupna vrijednost potraživanja/dugovanja van valute"
          ]}
          colors={["#ed9d10", "#dd3131"]}
          list={list[0]}
          type={type}
          title="POTRAŽIVANJA"
        />
        <HorizontalBarChart
          legends={[
            "Ukupna vrijednost potraživanja/dugovanja",
            "Ukupna vrijednost potraživanja/dugovanja van valute"
          ]}
          colors={["#ed9d10", "#dd3131"]}
          list={list[1]}
          type={type}
          title="DUGOVANJA"
        />
      </>
    );
  } else if (type === 2) {
    return (
      <>
        <HorizontalBarChart
          legends={[
            "Ukupna vrijednost potraživanja/dugovanja",
            "Ukupna vrijednost potraživanja/dugovanja van valute"
          ]}
          colors={["#ed9d10", "#dd3131"]}
          list={list}
          type={type}
          title="TOP 5 DUŽNIKA"
        />
      </>
    );
  } else if (type === 3) {
    return (
      <>
        <HorizontalBarChart
          legends={["Ukupan dug", "Ukupan dug van valute"]}
          colors={["#ed9d10", "#dd3131"]}
          list={list}
          type={type}
          title="TOP 5 POVJERILACA"
        />
      </>
    );
  } else {
    return <div>Loading</div>;
  }
};

export default BarChartWrapper;
