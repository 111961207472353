import React, { Component, ChangeEvent } from "react";
import DateInput from "../../Inputs/DateInput/DateInput";
import { formatDate, formatDate1 } from "../../../functions/formaters";
import { renderIcon } from "../../Icons/Icons";
import CustomButton from "../../custom/CustomButton/CustomButton";
import TextInput from "../../Inputs/TextInput/TextInput";
import axios from "axios";
import { getUplate } from "../../../api/getFunctions";
import { postInsertUplata } from "../../../api/postFunctions";

interface IModalUplataState {
  date: Date | undefined;
  datum: string;
  faktura: string;
  vrijednost: string;
  dug: number;
  disabled: boolean;
  uplate: Array<object>;
}

interface IModalUplataProps {
  dug: number;
  token: string;
  id?: string;
  removeModal?: () => void;
  onClick: (
    id: string,
    faktura: string,
    datum: string,
    vrijednost: string
  ) => void;
  title?: string;
}

class ModalUplata extends Component<IModalUplataProps, IModalUplataState> {
  state: IModalUplataState = {
    date: new Date(),
    datum: "",
    faktura: "",
    vrijednost: "",
    dug: 0,
    disabled: false,
    uplate: [],
  };

  escKeyHandler = (event: any) => {
    if (event.keyCode === 27 && this.props.removeModal) {
      this.props.removeModal();
    }
  };

  clickOutsideHandler = (event: any) => {
    if (event.target.classList.contains("modal") && this.props.removeModal) {
      this.props.removeModal();
    }
  };

  handleDateChange = (datum: Date | undefined, name: string) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        [name]: datum,
      }),
      () => {
        const datum: any = formatDate(this.state.date);
        this.setState({ datum });
      }
    );
  };

  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    const faktura: any = this.props.id;
    const datum: any = formatDate(this.state.date);

    getUplate(faktura, this.axiosCancelSource, this.props.token).then((res) => {
      const uplate = res.map((uplata: any) => {
        return {
          id: uplata.id,
          faktura: uplata.faktura,
          vrijednost: Number(uplata.vrijednost).toFixed(2),
          date: new Date(uplata.datum),
          datum: uplata.datum,
        };
      });
      this.setState({ uplate });
    });

    this.setState({
      datum,
      faktura,
      dug: this.props.dug,
      vrijednost: this.props.dug.toFixed(2).toString(),
    });

    document.addEventListener("keyup", this.escKeyHandler);
  }

  handleInputChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) => {
    e.persist();
    if (parseFloat(e.target.value) > this.props.dug) {
      this.setState({ vrijednost: this.props.dug.toFixed(2).toString() });
    } else {
      this.setState({ vrijednost: e.target.value });
    }
  };

  handleUpdateDateChange = (
    datum: Date | undefined,
    name: string,
    index?: number
  ) => {
    if (index !== undefined) {
      this.setState((prevState: IModalUplataState) => ({
        ...prevState,
        uplate: [
          ...prevState.uplate.slice(0, index),
          {
            ...prevState.uplate[index],
            date: datum,
            datum: datum ? formatDate(datum) : undefined,
          },
          ...prevState.uplate.slice(index + 1),
        ],
      }));
    }
  };

  handleUpdateInputChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    name: string,
    subname: string,
    index: number
  ) => {
    e.persist();
    this.setState((prevState: IModalUplataState) => ({
      ...prevState,
      uplate: [
        ...prevState.uplate.slice(0, index),
        {
          ...prevState.uplate[index],
          vrijednost: e.target.value,
        },
        ...prevState.uplate.slice(index + 1),
      ],
    }));
  };

  componentWillUnmount() {
    document.removeEventListener("keyup", this.escKeyHandler);
  }

  render() {
    const s = this.state;
    return (
      <div
        className="modal modalSmall d-flex align-items-center justify-content-center"
        onClick={this.clickOutsideHandler}
      >
        <div className="modalSmallContent pa-10 p-r">
          <div className="header d-flex align-items-center justify-content-center">
            <h5 className="text-color-white f-s-18 f-w-5">
              {this.props.title}
            </h5>
            <span className="center-a-v" onClick={this.props.removeModal}>
              {renderIcon("CloseIcon", "closeIcon", 16)}
            </span>
          </div>
          <div className="modalUplataContent" style={{ height: "auto" }}>
            {this.state.uplate.map((uplata: any, index: number) => (
              <div
                className="row align-items-center mt-10 mb-15"
                key={uplata.id}
              >
                <div className="col-md-4">
                  <label className="f-s-14" htmlFor="">
                    Datum
                  </label>
                  <DateInput
                    date={uplata.date}
                    name="date"
                    index={index}
                    onChange={this.handleUpdateDateChange}
                  />
                </div>
                <div className="col-md-4">
                  <label className="f-s-14" htmlFor={`uplata-${index}`}>
                    Iznos
                  </label>
                  <TextInput
                    placeholder=""
                    value={uplata.vrijednost}
                    number={true}
                    min={0}
                    index={index}
                    name="uplate"
                    id={`uplata-${index}`}
                    onChange={this.handleUpdateInputChange}
                  />
                </div>
                <div className="col-md-4">
                  <CustomButton
                    text="Sačuvaj"
                    onClick={() => {
                      this.setState({
                        disabled: true,
                      });
                      this.props.onClick(
                        uplata.id,
                        uplata.faktura,
                        uplata.datum,
                        uplata.vrijednost
                      );
                    }}
                    type="save"
                    disabled={this.state.disabled}
                  />
                </div>
              </div>
            ))}
            <div className="row align-items-center mb-15 mt-25">
              <div className="col-md-6">
                <label className="f-s-14" htmlFor="">
                  Datum
                </label>
                <DateInput
                  date={this.state.date}
                  name="date"
                  onChange={this.handleDateChange}
                />
              </div>
              <div className="col-md-6">
                <label className="f-s-14" htmlFor="faktura">
                  Iznos
                </label>
                <TextInput
                  placeholder=""
                  value={this.state.vrijednost}
                  number={true}
                  min={0}
                  name="faktura"
                  id="faktura"
                  onChange={this.handleInputChange}
                />
              </div>
              <div className="button d-flex justify-content-center mt-20">
                <div className="col-md-6">
                  <CustomButton
                    text="Uplati"
                    onClick={() => {
                      this.setState({
                        disabled: true,
                      });
                      this.props.onClick("0", s.faktura, s.datum, s.vrijednost);
                    }}
                    type="save"
                    disabled={this.state.disabled}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ModalUplata;
