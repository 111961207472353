import { InitialState } from "../InitialState/InitialState";

export const SviUgovori = {
  buttons: [
    { icon: "DocumentIcon", text: "IZMJENA", type: "document" }
  ],
  state: {
    datumOd: undefined,
    datumDo: undefined,
    loadMore: false,
    pageNumber: 1,
    firstCheckBox: true,
    secondCheckBox: false,
    sortBy: {
      name: "datum",
      direction: true
    },
    filter: {},
    ...InitialState,
    dropDown: {
      name: "statusDropdown",
      open: false,
      options: [
        "Status",
        "Djelimično pl. van valute",
        "Neplaćeno u valuti",
        "Neplaćeno van valute",
        "Plaćeno"
      ],
      reference: "dropDown",
      title: "Status"
    }
  },
  table: {
    thead: {
      firstRow: [
        "Firma",
        "Datum zaključenja",
        "Djelovodni broj firme",
        "Datum isteka",
        "Vrijednost",
        "Linija biznisa"
      ],
      secondRow: [
        { name: "TextInput", property: "klijent" },
        { name: "DateInput", property: "datum_zak" },
        { name: "TextInput", property: "djelovodni_broj_firme" },
        { name: "DateInput", property: "datum_vazenja" },
        { name: "TextInput", property: "vrijednost" },
        { name: "TextInput", property: "linija_biznisa" }
      ]
    }
  }
};

export const DobavljaciNav = [
  { icon: "SearchIcon", title: "Pronađi kupca/dobavljača" },
  { icon: "AddBuyerIcon", title: "Unesi kupca/dobavljača" }
];

export const UgovoriNav = [
  { icon: "SearchIcon", title: "Istekli ugovori" },
  { icon: "SearchIcon", title: "Svi ugovori" },
  { icon: "AddDocumentIcon", title: "Unesi ugovor" }
];
