import React from "react";
import DatePicker from "react-date-picker";

import { IDateInput } from "../../../interfaces/inputInterfaces";
import { formatDate } from "../../../functions/formaters";

const DateInput = ({ date, onChange, name, index }: IDateInput) => {
  const formatWeekday = (date: Date) => {
    var options = {
      weekday: "narrow"
    };
    return date.toLocaleDateString("sr-latn-SR", options);
  };
    const dateOriginal = formatDate(date)
    const dateFilter = dateOriginal !== undefined ? dateOriginal.split("-").reverse().join(".") + "." : dateOriginal

    return (
    <div className="customDatePickerContainer p-r">
      <span
        className="clearDate text-color-white f-s-14 d-flex align-items-center justify-content-center"
        onClick={() => onChange(undefined, name, index)}
      >
        X
      </span>
      <span className="dateContainer f-s-12 f-w-5">{dateFilter}</span>
      <DatePicker
        value={date}
        locale="sr-latn-SR"
        onChange={(date: any) => onChange(date, name, index)}
        formatShortWeekday={(locale, date) => formatWeekday(date)}
        minDetail="year"
      />
    </div>
  );
};

export default DateInput;
