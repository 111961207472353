import { IUnosUsluge } from "../UnosUsluge";

export const validateForm = (data: IUnosUsluge, type: string) => {
  let invalidFields: Array<any> = [];
  if (type === "unos") {
    if (data.naziv.trim() === "") {
      invalidFields.push("Polje naziva je obavezno!");
    } else if(data.naziv.trim().length > 100){
      invalidFields.push("Dužina naziva usluge ne može biti veća od 100 karaktera!");
    }
    if (data.linija_biznisa === "") {
      invalidFields.push("Polje linija biznisa je obavezno!");
    }
    if (data.valuta === "") {
      invalidFields.push("Polje valuta je obavezno!");
    } else if(data.valuta.length > 11){
      invalidFields.push("Valuta ne može imati preko 11 cifara!");
    }
    if (data.cijena === "") {
      invalidFields.push("Polje cijena je obavezno!");
    } else if(data.cijena.length > 15){
      invalidFields.push("Cijena ne može imati preko 15 cifara!");
    }
    if (data.pdv === "") {
      invalidFields.push("Polje PDV je obavezno!");
    } else if(data.pdv.length > 11){
      invalidFields.push("PDV ne može imati preko 11 cifara!");
    }
    if(data.opis !== null) {
        if (data.opis.trim().length > 1000) {
            invalidFields.push("Dužina napomene ne može biti veća od 1000 karaktera");
        }
    }
  }
  return invalidFields;
};
