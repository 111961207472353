import React from "react";
import Lottie from "react-lottie";
import { type } from "os";

type LoadingType = {
  small?: boolean;
  title?: string;
};

const Loading = ({ small, title }: LoadingType) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: !small
      ? require("./loading.json")
      : require("./loadingSmall.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  return (
    <div
      id="loading"
      className={`d-flex align-items-center justify-content-center flex-column ${small &&
        "small"}`}
    >
      <h2 className="f-s-40 text-color-white">{title}</h2>
      <Lottie options={defaultOptions} isStopped={false} isPaused={false} />
    </div>
  );
};

export default Loading;
