import React, { Component } from "react";
import { renderIcon } from "../Icons/Icons";
import { saveTable } from "../../functions/fileGenerators";
import Axios from "axios";
import {formatDate1, formatMoney} from "../../functions/formaters";
import Loading from "../Loading/Loading";
import { noScrollBody } from "../../functions/functions";

type GeneratedAnalitickaProps = {
    removeModal: (refresh?: boolean) => void;
    klijent: any;
    kupac: number;
    dobavljac: number;
    datumKupac: string;
    datumDobavljac: string;
    faktureKupci: any;
    faktureDobavljaci: any;
};

type GeneratedAnalitickaState = {
    fakture: any;
    loading: boolean;
    ukupnoIznos: number;
    ukupnoPlaceno: number;
    ukupnoDug: number;
};

class GeneratedAnaliticka extends Component<GeneratedAnalitickaProps> {
    axiosCancelSource = Axios.CancelToken.source();
    state: GeneratedAnalitickaState = {
        fakture: this.props.kupac === 1 ? this.props.faktureKupci : this.props.faktureDobavljaci,
        loading: false,
        ukupnoIznos: 0,
        ukupnoPlaceno: 0,
        ukupnoDug: 0
    };

    toggleLoading = (loading: boolean) => {
        this.setState({ loading });
        noScrollBody(loading);
    };

    componentWillUnmount() {
        this.axiosCancelSource.cancel("Component unmounted.");
    }

    componentDidMount() {
        if(this.state.fakture.length > 0) {
            const ukupnoIznos = this.state.fakture
                .map((e:any) => {
                    return parseFloat(e.iznos);
                })
                .reduce((a: number, b: number) => a + b);
            const ukupnoPlaceno = this.state.fakture
                .map((e:any) => {
                    return parseFloat(e.uplaceno);
                })
                .reduce((a: number, b: number) => a + b);
            const ukupnoDug = this.state.fakture
                .map((e:any) => {
                    return parseFloat(e.dug);
                })
                .reduce((a: number, b: number) => a + b);
            this.setState( {
                ukupnoIznos: formatMoney(ukupnoIznos),
                ukupnoPlaceno: formatMoney(ukupnoPlaceno),
                ukupnoDug: formatMoney(ukupnoDug)
            })
        }
    }


    renderBody = (page: number, length: number) => {
        if (length > 1) {
            return this.state.fakture
                .slice(page === 0 ? 0 : page * 14, page === 0 ? 14 : page * 14 + 14)
                .map((e:any, index: number) => {
                    return (
                        <tr key={index}>
                            <td className="text-center f-s-12" rowSpan={2}>{formatDate1(e.datum) + "."}</td>
                            <td className="text-center f-s-12" rowSpan={4}>{e.napomena}</td>
                            <td className="text-center f-s-12 green" rowSpan={2}>
                                {formatMoney(e.iznos)} €
                            </td>
                            <td className="text-center f-s-12 green" rowSpan={2}>
                                {formatMoney(e.uplaceno)} €
                            </td>
                            <td className="text-center f-s-12 red" rowSpan={2}>
                                {formatMoney(e.dug)} €
                            </td>
                        </tr>
                    );
                });
        } else {
            if (this.state.fakture.length > 0) {
                return (
                    <>
                    {this.state.fakture.map((e:any, index: number) => {
                        return (
                            <tr key={index}>
                                <td className="text-center f-s-12" >{formatDate1(e.datum) + "."}</td>
                                <td className="text-center f-s-12" >{e.napomena}</td>
                                <td className="text-center f-s-12 green">
                                    {formatMoney(e.iznos)} €
                                </td>
                                <td className="text-center f-s-12 green">
                                    {formatMoney(e.uplaceno)} €
                                </td>
                                <td className="text-center f-s-12 red">
                                    {formatMoney(e.dug)} €
                                </td>
                            </tr>
                        );
                    })}
                    </>
                );
            } else {
                return (
                    <tr>
                        <td className="text-center f-s-12" colSpan={5}>
                            Nema rezultata
                        </td>
                    </tr>
                );
            }
        }
    };

    render() {
        const pages = Math.ceil(this.state.fakture.length / 6);
        let pagesArray = [pages];
        let actualPages: number = 0;
        if (pages > 1) {
            actualPages = Math.ceil(this.state.fakture.length / 14);;
            pagesArray = new Array(actualPages).fill("1");
        }

        const k = this.props.klijent;

        return (
            <div className="fakturaBackground pb-40" id="fakt">
                {this.state.loading ? <Loading small title="Generisanje PDF-a" /> : ""}
                <div className="actions">
                    <div className="btn pr-10 ml-20 mt-20 ">
                        <div className="btn pr-10 ml-20 mt-20  d-flex justify-content-center">
                            <button
                                className="d-flex align-items-center justify-content-center mr-10 exit"
                                onClick={() => this.props.removeModal(false)}
                            >
                                <span className="f-s-14 text-transform-uppercase line-height-1">
                                  Odustani
                                </span>
                            </button>
                            <button
                                className="d-flex align-items-center justify-content-center mr-10 "
                                onClick={() =>
                                    saveTable(
                                        false,
                                        this.toggleLoading,
                                        2,
                                        pages === 1 ? pages : actualPages
                                    )
                                }
                            >
                                {renderIcon("PDFIcon", "mr-10", 20)}{" "}
                                <span className="f-s-14 text-transform-uppercase line-height-1">
                                  Preuzmi
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                {pagesArray.map((e, index) => {
                    return (
                        <React.Fragment key={index}>
                            <div
                                className={`fakturaPrint ${
                                    pagesArray.length === 1
                                        ? "d-flex flex-column justify-content-between"
                                        : ""
                                } pa-20 mt-40 center-margin`}
                                id={`analitickaPrint-${index}`}
                            >

                                <div className="content pa-20 d-flex flex-column justify-content-between">
                                    {index === 0 ? (
                                        <div id="test" style={{background: "white" , marginBottom: "50px"}}>
                                            <img src="/images/amplitudo.png" alt="amplitudo" className="mb-50"/>
                                            <div className="row">
                                                <div className="col-sm-4 ">
                                                    <div className="klijentContent pl-10 ptb-10" style={{border: "1px solid black"}}>
                                                        <h5 className="f-s-14 f-w-5 mb-10">{this.props.kupac === 1 ? "Kupac" : "Dobavljač"}:</h5>
                                                        <span className="clientName f-w-6 f-s-12 d-block">
                                                        {k.naziv_firme}
                                                      </span>
                                                        <span className="clientAdress f-s-12 d-block">
                                                        {k.adresa}
                                                      </span>
                                                        <span className="clientCity  f-s-12 d-block">
                                                        {k.grad}
                                                      </span>
                                                        <span className="clientPib  f-s-12 d-block">
                                                      PIB {k.pib}
                                                    </span>
                                                        <span className="clientPDV  f-s-12 d-block">
                                                      PDV {k.pdv_broj}
                                                    </span>
                                                    </div>
                                                </div>
                                                <div className="col-sm-8 d-flex justify-content-center flex-column">
                                                    <div className="row mb-10">
                                                        <div className="col-sm-6">
                                                            <span className="f-s-12 f-w-6">Račun broj:</span>
                                                        </div>
                                                        <div className="col-sm-6 text-right">
                                                      <span className="f-s-12 f-w-6">
                                                        {k.ziro_racun}
                                                      </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-10">
                                                        <div className="col-sm-6">
                                                      <span className="f-s-12">
                                                        Datum:
                                                      </span>
                                                        </div>
                                                        <div className="col-sm-6 text-right">
                                                    <span className="f-s-12">
                                                      {this.props.kupac === 1
                                                          ? this.props.datumKupac
                                                          : this.props.datumDobavljac
                                                      }
                                                    </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    <div className="table">
                                        <table>
                                            {this.state.fakture.slice(
                                                index === 0 ? 0 : index * 14,
                                                index === 0 ? 14 : index * 14 + 14
                                            ).length !== 0 ? (
                                                <thead>
                                                <tr>
                                                    <th rowSpan={2}>Datum</th>
                                                    <th rowSpan={4}>Opis</th>
                                                    <th rowSpan={2}>Fakturisano</th>
                                                    <th rowSpan={2}>Plaćeno</th>
                                                    <th rowSpan={2}>Saldo</th>
                                                </tr>
                                                </thead>
                                            ) : (
                                                ""
                                            )}
                                            <tbody>{this.renderBody(index, pagesArray.length)}</tbody>
                                        </table>
                                    </div>
                                    {pagesArray.length - 1 === index ||
                                    this.state.fakture.slice(
                                        index === 0 ? 0 : index * 14,
                                        index === 0 ? 14 : index * 14 + 14
                                    ).length < 7 ? (
                                        <>
                                            <div className="sum mt-20 pr-20">
                                                <div className="bezPoreza text-right">
                                                    <span className="f-s-12">Fakturisano:</span>
                                                    <span className=" ml-40 f-s-12">
                                                        {this.state.ukupnoIznos}€
                                                      </span>
                                                </div>
                                                <div className="porez text-right">
                                                  <span className="f-s-12">
                                                    Plaćeno:
                                                  </span>
                                                    <span className=" ml-40 f-s-12">
                                                        {this.state.ukupnoPlaceno}€
                                                  </span>
                                                </div>
                                                <div className="ukupno text-right">
                                                    <span className="f-s-12 f-w-5">Saldo:</span>
                                                    <span className=" ml-40 f-s-12 f-w-5">
                                                        {this.state.ukupnoDug}€
                                                      </span>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </React.Fragment>
                    );
                })}
            </div>
        );
    }
}

export default GeneratedAnaliticka;
