import React, { Component, createRef } from "react";
import NavbarLogo from "./NavbarLogo";
import { Link } from "react-router-dom";
import { RouteComponentProps } from "@reach/router";
import NavbarLink from "./NavbarLink/NavbarLink";
import { renderIcon } from "../Icons/Icons";
import {BASE_URL} from "../../api/baseUrl";

interface INavbar extends RouteComponentProps {
  history: any;
  state: {
    rotateArrow: any;
  };
  logo: string;
  firma: string;
}

class Navbar extends Component<INavbar> {
  subRef = createRef<any>();

  state = {
    rotateArrow: false,
  };

  isFakture = () =>
    this.props.history.location.pathname.split("/")[1] === "fakture"
      ? true
      : false;

  openSub = () => {
    const node = this.subRef.current;
    this.setState((prevState: any) => ({
      rotateArrow: !prevState.rotateArrow,
    }));
    if (node) {
      if (node.offsetHeight === 0) {
        node.style.height = "120px";
      } else {
        node.style.height = "0";
      }
    }
  };

  render() {
    return (
      <div id="navbar" className="d-flex justify-content-between flex-column">
        <div className="wrapper pt-10">
          <div className="navbarHead d-flex justify-content-between align-items-center flex-column">
            <div className="navbarHeadContainer">
              <div className="indigoLogo text-center">
                <Link to="/">
                  <NavbarLogo />
                </Link>
              </div>
              <div className="companyContainer mt-10 mb-10">
                <Link to="/">
                  <div className="companyLogo d-flex justify-content-center">
                    <img
                      src={this.props.logo}
                      alt={this.props.firma}
                      style={{ maxWidth: "100%", marginBottom: "1rem" }}
                    />
                  </div>
                  <div className="companyName text-center">
                    <span className="text-color-white f-s-15 f-w-5">
                      {this.props.firma}
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className="navbarListWrapper">
            <div className="primaryList mt-20 mb-20">
              <ul>
                <NavbarLink name="Pregled" icon="PregledIcon" to="/" />
                <NavbarLink
                  name="Fakture"
                  icon="FaktureIcon"
                  to="#"
                  dropdown={true}
                  openSub={this.openSub}
                  rotateArrow={this.state.rotateArrow}
                  ref={this.subRef}
                  fakture={this.isFakture()}
                />

                <NavbarLink
                  name="Kupci/Dobavljači"
                  icon="KupciIcon"
                  to="/kupci"
                />

                <NavbarLink
                  name="Dokumentacija"
                  icon="DokumentacijaIcon"
                  to="/dokumentacija"
                />
                <NavbarLink
                  name="Izvještaji"
                  icon="IzvjestajiIcon"
                  to="/izvjestaji"
                />
                <NavbarLink name="Porezi" icon="PoreziIcon" to="/porezi" />
              </ul>
            </div>
            <div className="secondaryList pt-20">
              <ul>
                <NavbarLink
                  subNav={true}
                  name="Ugovori"
                  icon=""
                  to="/ugovori"
                />
                <NavbarLink subNav={true} name="Alarmi" icon="" to="/alarmi" />
                <NavbarLink
                  subNav={true}
                  name="Targeti"
                  icon=""
                  to="/targeti"
                />
                <NavbarLink subNav={true} name="Usluge" icon="" to="/usluge" />
                <NavbarLink
                  subNav={true}
                  name="Pretraga po uslugama"
                  icon=""
                  to="/pretragaPoUslugama"
                />
                <NavbarLink
                  subNav={true}
                  name="Provjeri IKOF"
                  icon=""
                  to=""
                  onClick={() => window.location.href = `${BASE_URL}/fiskalizacija/form.php`}
                />
                <NavbarLink subNav={true} name="KIF/KUF" icon="" to="/knjige" />
              </ul>
            </div>
          </div>
        </div>
        <div className="brandContainer d-flex align-items-center justify-content-center">
          <i className="text-color-font-l mr-5 f-s-12">powered by</i>
          <span className="line-height-1">
            <a
              href="https://amplitudo.me"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/amplitudoLogoNew.svg"
                alt="Amplitudo"
                width={65}
                style={{ marginTop: "11px" }}
              />
            </a>
          </span>
        </div>
      </div>
    );
  }
}

export default Navbar;