import { InitialState } from "../InitialState/InitialState";

export const Dobavljaci = {
  buttons: [
    { icon: "DocumentIcon", text: "Izmjena", type: "izmjena" },
    { icon: "DeleteDocumentIcon", text: "Obriši", type: "delete" },
    { icon: "DocumentIcon", text: "Kopija", type: "copy" }
  ],
  state: {
    datumOd: undefined,
    datumDo: undefined,
    loadMore: false,
    pageNumber: 0,
    firstCheckBox: true,
    secondCheckBox: true,
    sortBy: {
      name: "datum",
      direction: true
    },
    filter: {
      od: "",
      do: "",
      brfakture: "",
      klijent: "",
      grad: "",
      iznos: "",
      currentpage: 1
    },
    ...InitialState,
    dropDown: {
      name: "statusDropdown",
      open: false,
      options: [
        "Status",
        "Djelimično pl. van valute",
        "Neplaćeno u valuti",
        "Neplaćeno van valute",
        "Plaćeno"
      ],
      reference: "dropDown",
      title: "Status"
    }
  },
  table: {
    thead: {
      firstRow: ["Naziv kompanije", "Grad", "PIB", "Kontakt osoba"],
      secondRow: [
        { name: "TextInput", property: "naziv_firme" },
        { name: "TextInput", property: "grad" },
        { name: "TextInput", property: "pib", type: "number"},
        { name: "TextInput", property: "kontakt_osoba" }
      ]
    }
  }
};

export const DobavljaciNav = [
  { icon: "SearchIcon", title: "Pronađi kupca/dobavljača" },
  { icon: "AddBuyerIcon", title: "Unesi kupca/dobavljača" }
];
