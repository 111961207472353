import React, { Component, createRef } from "react";
import axios from "axios";
import { IPage } from "../../interfaces/pageInterfaces";

import CustomSelect from "../../components/custom/CustomSelect/CustomSelect";
import {
  getHomeSuperUlazneIzlazne,
  getHomeSuperPotrazivanjaDugovanja,
  getHomeSuperTopDuznici,
  getHomeSuperTopPovjerioci,
  getHomeSuperPDV
} from "../../api/getFunctions";
import BarChartWrapper from "./BarChartWrapper/BarChartWrapper";
import RingChartWrapper from "./RingChartWrapper/RingChartWrapper";

interface IState {
  [key: string]: any;
}

class Home extends Component<IPage> {
  constructor(props: any) {
    super(props);
    if (this.props.changePageTitle) {
      this.props.changePageTitle("Pregled");
    }
  }

  axiosCancelSource = axios.CancelToken.source();

  monthList = () => {
    const date = new Date().getMonth();
    const monthList = [];

    for (let index = 0; index <= date; index++) {
      monthList.push(
        new Date(0, index).toLocaleString("sr-latn-SR", { month: "long" })
      );
    }
    return monthList;
  };
  state: IState = {
    firstDropdown: false,
    secondDropdown: false,
    firstOptions: [
      "Izlazne/Ulazne",
      "Potraživanja/Dugovanja",
      "Dužnici",
      "Povjerioci",
      "PDV"
    ],
    secondOptions: this.monthList(),
    activeFirst: "Izlazne/Ulazne",
    activeSecond: this.monthList().pop(),
    results: [],
    month: new Date().getMonth() + 1,
    type: 0
  };

  firstRef = createRef<any>();
  secondRef = createRef<any>();

  closeDropdowns = (item?: number) => {
    if (item === 0) {
      this.setState({ secondDropdown: false });
      if (this.state.type < 1) this.secondRef.current.style.height = "0px";
    } else if (item === 1) {
      this.setState({ firstDropdown: false });
      if (this.state.type < 1) this.firstRef.current.style.height = "0px";
    } else {
      this.setState({ firstDropdown: false, secondDropdown: false });
      this.firstRef.current.style.height = "0px";
      if (this.state.type < 1) this.secondRef.current.style.height = "0px";
    }
  };

  handleDropdown = (e: React.MouseEvent<HTMLButtonElement>, name: any) => {
    const item = name === "firstDropdown" ? 0 : 1;
    this.closeDropdowns(item);
    e.stopPropagation();
    this.setState((prevState: any) => ({
      [name]: !prevState[name]
    }));
  };

  handleFilterClick = (e: React.MouseEvent<HTMLLIElement>, name: string) => {
    if (name === "activeSecond") {
      const month =
        this.state.secondOptions.findIndex(
          (el: any) => el === e.currentTarget.innerHTML
        ) + 1;
      this.setState({ [name]: e.currentTarget.innerHTML, month });
    } else {
      const type = this.state.firstOptions.findIndex(
        (el: any) => el === e.currentTarget.innerHTML
      );
      this.setState({ [name]: e.currentTarget.innerHTML, type });
    }
  };

  componentDidMount() {
    this.monthList();
    window.onclick = () => {
      this.closeDropdowns();
      const el = document.getElementById("create");
      if (el) {
        el.classList.remove("open");
      }
    };
    this.getData();
  }

  getData = () => {
    const { type } = this.state;
    if (type === 0) {
      getHomeSuperUlazneIzlazne(
        this.state.month,
        this.axiosCancelSource,
        this.props.api
      ).then(res => this.setState({ results: res }));
    } else if (type === 1) {
      getHomeSuperPotrazivanjaDugovanja(
        this.state.month,
        this.axiosCancelSource,
        this.props.api
      ).then(res => this.setState({ results: res }));
    } else if (type === 2) {
      getHomeSuperTopDuznici(
        this.state.month,
        this.axiosCancelSource,
        this.props.api
      ).then(res => this.setState({ results: res }));
    } else if (type === 3) {
      getHomeSuperTopPovjerioci(
        this.state.month,
        this.axiosCancelSource,
        this.props.api
      ).then(res => this.setState({ results: res }));
    } else {
      getHomeSuperPDV(
        this.state.month,
        this.axiosCancelSource,
        this.props.api
      ).then(res => this.setState({ pdv: res }));
    }
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.month !== this.state.month) {
      this.getData();
    }
    if (prevState.type !== this.state.type) {
      this.getData();
    }
  }

  componentWillUnmount() {
    window.onclick = null;
    this.axiosCancelSource.cancel("Component unmounted.");
  }

  render() {
    return (
      <>
        <div className="homeHeader d-flex">
          <div className="select mr-40">
            <CustomSelect
              name="activeFirst"
              title={this.state.activeFirst}
              options={this.state.firstOptions}
              open={this.state.firstDropdown}
              reference="firstDropdown"
              toggleDropdown={this.handleDropdown}
              ref={this.firstRef}
              handleFilterClick={this.handleFilterClick}
            />
          </div>
          {this.state.type < 1 ? (
            <div className="select">
              <CustomSelect
                name="activeSecond"
                handleFilterClick={this.handleFilterClick}
                title={this.state.activeSecond}
                options={this.state.secondOptions}
                open={this.state.secondDropdown}
                reference="secondDropdown"
                toggleDropdown={this.handleDropdown}
                ref={this.secondRef}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="homeContent mt-20 prl-20">
          {this.state.type !== 4 ? (
            <BarChartWrapper list={this.state.results} type={this.state.type} />
          ) : (
            <RingChartWrapper result={this.state.pdv} />
          )}
        </div>
      </>
    );
  }
}

export default Home;
