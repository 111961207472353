import React, { Component } from "react";

import { Chart } from "./Chart/Chart";

interface IHorizontalBar {
  legends: Array<string>;
  colors: Array<string>;
  list: Array<Object>;
  type: number;
  title: string;
}

class HorizontalBarChart extends Component<IHorizontalBar> {
  componentDidMount() {}

  render() {
    let sortedList: any;
    if (this.props.type === 2 || this.props.type === 3) {
      sortedList = this.props.list.sort(
        (a: any, b: any) => b.fakturisano - a.fakturisano
      );
    } else {
      sortedList = [];
    }
    const colors: Array<string> = this.props.colors;
    if (this.props.list) {
      const list =
        this.props.type === 2 || this.props.type === 3
          ? sortedList
          : this.props.list;
      return (
        <div className="horizontalBarContainer">
          <div className="title mb-20 pt-20">
            <h2 className="f-s-30 line-height-1 text-color-white text-transform-uppercase f-w-4 text-center">
              {this.props.title}
            </h2>
          </div>
          <div className="legend">
            <span className="text-color-white pl-10  d-flex">
              {this.props.legends.map((e: any, index: number) => {
                return (
                  <span key={index} className="d-flex align-items-center mr-20">
                    <span
                      className="circle mr-5"
                      style={{ backgroundColor: colors[index] }}
                    />
                    <span className="title">{e}</span>
                  </span>
                );
              })}
            </span>
          </div>
          <div className="pb-40">
            <Chart
              list={list}
              colors={this.props.colors}
              type={this.props.type}
              id={this.props.title.replace(" ", "-")}
            />
          </div>
        </div>
      );
    } else {
      return <h1>Loading</h1>;
    }
  }
}

export default HorizontalBarChart;
