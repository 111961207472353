import React from "react";
import Loading from "../../../components/Loading/Loading";
import { formatMoney } from "../../../functions/formaters";
import { renderIcon } from "../../../components/Icons/Icons";

type KlijentDataType = {
  data?: Array<{ datum: string; br_fakture: string; iznos: string }> | null;
  dataUgovor?: Array<{
    datum_zak: string;
    datum_vazenja: string;
    vrijednost: string;
  }> | null;
  loadMore: (loadMore: boolean) => void;
  loadMoreCondition: boolean;
  sortBy: (
    e: React.MouseEvent<HTMLElement>,
    object: string,
    name: string
  ) => void;
};

const KlijentData = ({
  data,
  dataUgovor,
  loadMore,
  loadMoreCondition,
  sortBy
}: KlijentDataType) => {
  if (data) {
    return (
      <div className="klijentCard klijentData">
        <div className="row mb-10 head">
          <div
            className="col-sm-4 text-center mt-10 p-r"
            onClick={(e: React.MouseEvent<HTMLElement>) =>
              sortBy(e, "klijentData", "datum")
            }
          >
            <span className="f-s-12 f-w-5">Datum</span>
            <span className="sortDirection d-none">
              {renderIcon("ArrowIcon", "")}
            </span>
          </div>
          <div
            className="col-sm-4 text-center mt-10 p-r"
            onClick={(e: React.MouseEvent<HTMLElement>) =>
              sortBy(e, "klijentData", "br_fakture")
            }
          >
            <span className="f-s-12 f-w-5">Br. fakture</span>
            <span className="sortDirection d-none">
              {renderIcon("ArrowIcon", "")}
            </span>
          </div>
          <div
            className="col-sm-4 text-center mt-10 p-r"
            onClick={(e: React.MouseEvent<HTMLElement>) =>
              sortBy(e, "klijentData", "iznos")
            }
          >
            <span className="f-s-12 f-w-5">Iznos</span>
            <span className="sortDirection d-none">
              {renderIcon("ArrowIcon", "")}
            </span>
          </div>
        </div>
        {data.map((e, index) => {
          return (
            <div className="row item d-flex align-items-center" key={index}>
              <div className="col-sm-4 text-center">
                <span className="f-s-12 f-w-4">{e.datum.split("-").reverse().join(".") + "."}</span>
              </div>
              <div className="col-sm-4 text-center">
                <span className="f-s-12 f-w-4">{e.br_fakture}</span>
              </div>
              <div className="col-sm-4 text-center">
                <span className="f-s-12 f-w-4">{formatMoney(e.iznos)} €</span>
              </div>
            </div>
          );
        })}
        {loadMore(loadMoreCondition)}
      </div>
    );
  } else if (dataUgovor) {
    return (
      <div className="klijentCard klijentData">
        <div className="row mb-10 head">
          <div
            className="col-sm-4 text-center mt-10 p-r"
            onClick={(e: React.MouseEvent<HTMLElement>) =>
              sortBy(e, "klijentUgovor", "datum_zak")
            }
          >
            <span className="f-s-12 f-w-5">Datum zaklj.</span>
            <span className="sortDirection d-none">
              {renderIcon("ArrowIcon", "")}
            </span>
          </div>
          <div
            className="col-sm-4 text-center mt-10 p-r"
            onClick={(e: React.MouseEvent<HTMLElement>) =>
              sortBy(e, "klijentUgovor", "vrijednost")
            }
          >
            <span className="f-s-12 f-w-5">Vrijednost</span>
            <span className="sortDirection d-none">
              {renderIcon("ArrowIcon", "")}
            </span>
          </div>
          <div
            className="col-sm-4 text-center mt-10 p-r"
            onClick={(e: React.MouseEvent<HTMLElement>) =>
              sortBy(e, "klijentUgovor", "datum_vazenja")
            }
          >
            <span className="f-s-12 f-w-5">Datum isteka</span>
            <span className="sortDirection d-none">
              {renderIcon("ArrowIcon", "")}
            </span>
          </div>
        </div>
        {dataUgovor.map((e, index) => {
          return (
            <div className="row item d-flex align-items-center" key={index}>
              <div className="col-sm-4 text-center">
                <span className="f-s-12 f-w-4">{e.datum_zak.split("-").reverse().join(".") + "."}</span>
              </div>
              <div className="col-sm-4 text-center">
                <span className="f-s-12 f-w-4">
                  {formatMoney(e.vrijednost)} €
                </span>
              </div>
              <div className="col-sm-4 text-center">
                <span className="f-s-12 f-w-4">{e.datum_vazenja.split("-").reverse().join(".") + "."}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  } else {
    return (
      <div className="klijentCard klijentData p-r">
        <Loading small />
      </div>
    );
  }
};

export default KlijentData;
