import React, { Component, ChangeEvent } from "react";
import TextInput from "../../Inputs/TextInput/TextInput";
import CustomButton from "../../custom/CustomButton/CustomButton";

interface ILinijaBiznisaState {
  id: string;
  naziv: string;
  valuta: string;
}

type StateKeys = keyof ILinijaBiznisaState;

class LinijaBiznisa extends Component<{
  id: string;
  naziv: string;
  placeholder?: string;
  onClick: (id: string, name: string) => void;
}> {
  state: ILinijaBiznisaState = {
    id: "",
    naziv: "",
    valuta: ""
  };

  handleInputChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    name: StateKeys,
    subname?: any,
    index?: number
  ) => {
    e.persist();
    this.setState(prevState => ({
      ...prevState,
      [name]: e.target.value
    }));
  };

  componentDidUpdate(prevProps: {
    id: string;
    naziv: string;
    placeholder?: string;
  }) {
    if (prevProps !== this.props) {
      this.setState({ id: this.props.id, naziv: this.props.naziv });
    }
  }

  render() {
    return (
      <div className="d-flex align-items-center linijaBiznisa">
        <span className="mr-10">
          <TextInput
            placeholder={this.props.placeholder || ""}
            value={this.state.naziv}
            onChange={this.handleInputChange}
            name="naziv"
          />
        </span>
        <button
          className={this.props.placeholder && "dodaj"}
          onClick={() => this.props.onClick(this.state.id, this.state.naziv)}
        >
          {this.props.placeholder ? "Dodaj" : "Izmijeni"}
        </button>
      </div>
    );
  }
}

export default LinijaBiznisa;
