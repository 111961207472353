import React, { Component, ChangeEvent } from "react";
import TextInput from "../Inputs/TextInput/TextInput";
import axios from "axios";
import TextArea from "../Inputs/TextArea/TextArea";
import CustomButton from "../custom/CustomButton/CustomButton";
import { postInsertAlarm } from "../../api/postFunctions";
import ModalSmall from "../modals/ModalSmall/ModalSmall";
import { validateForm } from "./functions/validateForm";
import ModalErrors from "../modals/ModalErrors/ModalErrors";
import { LinijeBiznisa } from "../../settings/TableTypes/FaktureTypes";
import DateInput from "../Inputs/DateInput/DateInput";
import {formatDate} from "../../functions/formaters";

export interface IUnosAlarma {
    id: string;
    date: Date | undefined;
    datum: string;
    naziv: string;
    sms: number,
    mail: number,
    ponavljanje: number,
    obavjestenje: string;
    errors: Array<string>;
    modal: boolean;
}

type UnosAlarmaType = {
    edit?: boolean;
    data?: any;
    token: string;
    linijeBiznisa?: Array<LinijeBiznisa>;
};

type StateKeys = keyof IUnosAlarma;

class UnosAlarma extends Component<UnosAlarmaType> {
    form: any = React.createRef();
    state: IUnosAlarma = {
        id: "0",
        naziv: "",
        date: undefined,
        sms: 0,
        mail: 0,
        ponavljanje: 0,
        datum: "",
        obavjestenje: "",
        errors: [],
        modal: false,
    };

    //*****************************************/
    //*              LIFECYCLE                */
    //*****************************************/

    axiosCancelSource = axios.CancelToken.source();

    componentDidMount() {
        this.initStateData();
    }

    componentWillUnmount() {
        this.axiosCancelSource.cancel("Component unmounted.");
    }

    //*****************************************/
    //*              LIFECYCLE                */
    //*****************************************/

    //****************************************?/
    //?              CUSTOM FUNCTIONS         */
    //****************************************?/

    handleInputChange = (
        e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
        name: StateKeys,
        subname?: any,
        index?: number
    ) => {
        e.persist();
        if (name !== "naziv" && name !== "obavjestenje") {
            const regNumbers = new RegExp("^[0-9.]+$");
            if (regNumbers.test(e.target.value) || e.target.value.length === 0) {
                this.setState((prevState) => ({
                    ...prevState,
                    [name]: e.target.value,
                }));
            }
        } else {
            if (name === "obavjestenje") {
                if (e.target.value.length <= 1000) {
                    this.setState((prevState) => ({
                        ...prevState,
                        [name]: e.target.value,
                    }));
                } else {
                    this.setState((prevState) => ({
                        ...prevState,
                        [name]: e.target.value.substr(0, 1000),
                        errors: ["Dužina obavještenja ne može biti veća od 1000 karaktera!"],
                    }));
                    this.setState({
                        naziv: "",
                    });
                    this.showModal();
                }
            } else {
                this.setState((prevState) => ({
                    ...prevState,
                    [name]: e.target.value,
                }));
            }
        }
    };

    showModal = () => {
        this.setState({ modal: true });
    };
    removeModal = () => {
        this.setState({ modal: false });
    };

    renderModal = () => {
        if (this.state.errors.length > 0) {
            return (
                <ModalErrors
                    title="Greška prilikom unosa alarma"
                    errors={this.state.errors}
                    removeModal={this.removeModal}
                    message="Greška prilikom unosa alarma."
                />
            );
        } else {
            return (
                <ModalSmall
                    question="Alarm uspješno sačuvan."
                    buttons={["euro"]}
                    options={["U redu"]}
                    id={""}
                    onClick={() => false}
                    removeModal={this.removeModal}
                    title="Alarm sačuvan"
                />
            );
        }
    };

    initStateData = () => {
        if (this.props.edit) {
            this.setState({
                id: this.props.data.id,
                naziv: this.props.data.naziv,
                date: new Date(this.props.data.datum),
                datum: this.props.data.datum,
                obavjestenje: this.props.data.obavjestenje,
            });
        }
    };

    resetForm = () => {
        this.setState({
            naziv: "",
            date: undefined,
            datum: "",
            obavjestenje: "",
        });
        if (this.props.edit) {
            this.initStateData();
        }
    };

    insertUsluga = () => {
        const { id, naziv, datum, obavjestenje,sms, mail, ponavljanje } = this.state;
        let errors;
        const data = {
            id,
            naziv,
            datum,
            obavjestenje,
            sms,
            mail,
            ponavljanje
        };
        errors = validateForm(this.state, "unos");

        if (errors.length === 0) {
            this.setState({ errors });
            postInsertAlarm(data, this.axiosCancelSource, this.props.token).then(
                (res) => {
                    if (res) {
                        if (!this.props.edit) {
                            this.setState({
                                id: "0",
                                naziv: "",
                                datum: "",
                                date: "",
                                obavjestenje: "",
                            });
                        }
                        this.showModal();
                    }
                }
            );
        } else {
            this.setState({ errors }, () => {
                this.showModal();
            });
        }
    };


    handleDateChange = (datum: Date | undefined, name: string) => {
        this.setState(
            (prevState) => ({
                ...prevState,
                [name]: datum,
            }),
            () => {
                const datum: any = formatDate(this.state.date);
                this.setState({ datum });
            }
        );
    };

    //****************************************?/
    //?              CUSTOM FUNCTIONS         */
    //****************************************?/

    render() {
        const s = this.state;
        return (
            <div>
                <form ref={this.form} onSubmit={(e) => e.preventDefault()}>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="unosFakturaContainer ">
                                <div className="row mt-30">
                                    <div className="col-sm-12 col-lg-6">
                                        <div className="title f-s-12 f-w-5 mb-5">
                                            <label htmlFor="naziv">
                                                Naziv alarma <span>*</span>
                                            </label>
                                        </div>
                                        <div className="input">
                                            <TextInput
                                                placeholder=""
                                                value={s.naziv}
                                                onChange={this.handleInputChange}
                                                name="naziv"
                                                id="naziv"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-6">
                                        <div className="title f-s-12 f-w-5 mb-5">
                                            <label htmlFor="naziv">
                                                Datum <span>*</span>
                                            </label>
                                        </div>
                                        <div className="input">
                                            <DateInput
                                                date={s.date}
                                                name="date"
                                                onChange={this.handleDateChange}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-30">
                                    <div className="col-sm-12">
                                        <TextArea
                                            value={s.obavjestenje}
                                            onChange={this.handleInputChange}
                                            name="obavjestenje"
                                            placeholder="Obavještenje"
                                            height={150}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-40">
                                    <div className="col-sm-2">
                                        <CustomButton
                                            text="Reset"
                                            onClick={this.resetForm}
                                            type="reset"
                                        />
                                    </div>
                                    <div className="col-sm-3 pb-20">
                                        <CustomButton
                                            text="Sačuvaj"
                                            onClick={this.insertUsluga}
                                            type="save"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.modal ? this.renderModal() : ""}
                </form>
            </div>
        );
    }
}

export default UnosAlarma;
