import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

type VerticalBarType = {
  data: {
    placeno: string;
    neplaceno: string;
  };
  id: string;
};

// Themes end
class VerticalBar extends Component<VerticalBarType> {
  componentDidMount() {}
  renderBar = () => {
    let chart = am4core.create(this.props.id, am4charts.XYChart);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    chart.data = [
      {
        category: "One",
        value1: this.props.data.placeno,
        value2: this.props.data.neplaceno,
      },
    ];

    chart.colors.step = 2;
    chart.maskBullets = false;
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.line.strokeOpacity = 0;
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.ticks.template.disabled = true;
    categoryAxis.renderer.labels.template.disabled = true;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
    valueAxis.calculateTotals = true;
    valueAxis.renderer.grid.template.strokeOpacity = 0;

    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.renderer.ticks.template.disabled = true;
    valueAxis.renderer.labels.template.disabled = true;

    valueAxis.renderer.baseGrid.disabled = true;
    valueAxis.renderer.ticks.template.disabled = true;

    let series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.columns.template.width = am4core.percent(80);
    series1.columns.template.tooltipText =
      "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
    series1.name = "Plaćeno";
    series1.dataFields.categoryX = "category";
    series1.dataFields.valueY = "value1";
    series1.dataFields.valueYShow = "totalPercent";
    series1.dataItems.template.locations.categoryX = 0.5;
    series1.stacked = true;
    series1.strokeWidth = 0;

    series1.fill = am4core.color("#359B6B");
    series1.fillOpacity = 0.8;
    // series1!.tooltip.pointerOrientation = "vertical";

    let bullet1 = series1.bullets.push(new am4charts.LabelBullet());
    bullet1.interactionsEnabled = false;
    bullet1.label.text = "{valueY.totalPercent.formatNumber('#.00')}";
    bullet1.label.fill = am4core.color("#ffffff");
    bullet1.locationY = 0;
    bullet1.dy = -10;
    bullet1.label.adapter.add("textOutput", function (text, target) {
      if (
        target.dataItem &&
        (target.dataItem.getValue("valueY") == 0 ||
          typeof target.dataItem.getValue("valueY") == "undefined")
      ) {
        return "";
      }
      return "";
    });

    let series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.columns.template.width = am4core.percent(80);
    series2.columns.template.tooltipText =
      "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
    series2.name = "Neplaćeno";
    series2.dataFields.categoryX = "category";
    series2.dataFields.valueY = "value2";
    series2.dataFields.valueYShow = "totalPercent";
    series2.dataItems.template.locations.categoryX = 0.5;
    series2.stacked = true;
    series2.fill = am4core.color("#DD3131");
    series2.fillOpacity = 0.8;
    series2.strokeWidth = 0;
    // series2.tooltip.pointerOrientation = "vertical";

    let bullet2 = series2.bullets.push(new am4charts.LabelBullet());
    bullet2.interactionsEnabled = false;
    bullet2.label.text = "{valueY.totalPercent.formatNumber('#.00')}";
    bullet2.locationY = 0;
    bullet2.label.fill = am4core.color("#ffffff");
    bullet2.label.adapter.add("textOutput", function (text, target) {
      if (
        target.dataItem &&
        (target.dataItem.getValue("valueY") == 0 ||
          typeof target.dataItem.getValue("valueY") == "undefined")
      ) {
        return "";
      }
      return "";
    });

    let series3 = chart.series.push(new am4charts.ColumnSeries());
    series3.columns.template.width = am4core.percent(80);
    series3.columns.template.tooltipText =
      "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
    series3.name = "Series 3";
    series3.dataFields.categoryX = "category";
    series3.dataFields.valueY = "value3";
    series3.dataFields.valueYShow = "totalPercent";
    series3.dataItems.template.locations.categoryX = 0.5;
    series3.stacked = true;
    // series3.tooltip.pointerOrientation = "vertical";
  };
  componentDidUpdate(prevProps: VerticalBarType) {
    if (this.props.data) {
      this.renderBar();
    }
  }
  render() {
    return (
      <div
        id={this.props.id}
        style={{
          width: "120px",
          height: "100%",
          transform: "translateY(15px)",
        }}
      />
    );
  }
}

export default VerticalBar;
