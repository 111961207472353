import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";
import am4themes_dark from "@amcharts/amcharts4/themes/amchartsdark";
import Loading from "../../Loading/Loading";
am4core.useTheme(am4themes_dark);
am4core.useTheme(am4themes_kelly);
export type DuzniciPovjerioci = {
  firma: string;
  fakturisano: number;
  van_valute: number;
  u_valuti: number;
};

type ColumnChartProps = {
  fakture: Array<{
    tip: string;
    fakturisano: number;
    uplaceno: number;
    dug_van_valute: number;
  }> | null;
  potrazivanja: Array<{
    tip: string;
    ukupno: number;
    u_valuti: number;
    van_valute: number;
  }> | null;
  duznici: Array<DuzniciPovjerioci> | null;
  data: Array<{
    kupac: string;
    vrijednost_faktura: number;
    dug_u_valuti: number;
    dug_van_valute: number;
  }> | null;
  brojFaktura: Array<{
    broj_faktura: number;
    linija_biznisa: string;
  }> | null;
  targeti: {
    trenutnaGodina: Array<{
      mjesec: number;
      data: {
        target: number;
        prihodi: number;
        rashodi: number;
      };
    }>;
  } | null;

  id: string;
  title: string;
  type?: number;
};

class ColumnChart extends Component<ColumnChartProps> {
  componentDidMount() {
    if (this.props.data) {
      this.renderChart();
    }
    if (this.props.brojFaktura) {
      this.renderFakture();
    }
    if (this.props.targeti) {
      this.renderTargeti();
    }
    if (this.props.fakture) {
      this.renderFaktureUkupno();
    }
    if (this.props.potrazivanja) {
      this.renderPotrazivanja();
    }
    if (this.props.duznici) {
      this.renderDuznici();
    }
  }

  renderFaktureUkupno = () => {
    let chart = am4core.create(this.props.id, am4charts.XYChart);

    // Add data
    chart.data = [...this.props.fakture];
    chart.maskBullets = false;
    chart.numberFormatter.numberFormat = "#.##a";
    chart.numberFormatter.bigNumberPrefixes = [
      { "number": 1e+0, "suffix": "" },
      { "number": 1e+3, "suffix": "K" },
      { "number": 1e+6, "suffix": "M" },
      { "number": 1e+9, "suffix": "B" }
    ];
    chart.scrollbarY = new am4core.Scrollbar();
    chart.scrollbarY.marginLeft = 0;

    let title = chart.titles.create();
    title.text = this.props.title;
    title.fontSize = 18;
    title.marginBottom = 15;
    title.marginTop = 0;
    title.fontWeight = "500";
    title.fontFamily = "Roboto";
    title.fill = am4core.color("#fff");

    // Create axes

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "tip";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.fontSize = 14;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.labels.template.disabled = true;
    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "fakturisano";
    series.dataFields.categoryX = "tip";
    series.name = "Ukupna vrijednost faktura";
    series.columns.template.tooltipText =
      "{name}: [bold]{valueY.formatNumber('#######,###.##')}[/]€";
    series.columns.template.fillOpacity = 0.8;
    series.strokeWidth = 0;
    series.fill = am4core.color("#7BAE45");
    series.fontFamily = "Roboto";

    var seriesLabel = series.bullets.push(new am4charts.LabelBullet());
    seriesLabel.label.text = "{valueY}€";
    seriesLabel.label.hideOversized = false;
    seriesLabel.label.truncate = false;
    seriesLabel.label.verticalCenter = "bottom";
    seriesLabel.label.dy = -10;

    let series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.dataFields.valueY = "uplaceno";
    series1.dataFields.categoryX = "tip";
    series1.name = "Ukupna vrijednost plaćenih faktura";
    series1.columns.template.tooltipText =
      "{name}: [bold]{valueY.formatNumber('#######,###.##')}[/]€";
    series1.columns.template.fillOpacity = 0.8;
    series1.strokeWidth = 0;
    series1.fill = am4core.color("#ED9D10");

    var seriesLabel1 = series1.bullets.push(new am4charts.LabelBullet());
    seriesLabel1.label.text = "{valueY}€";
    seriesLabel1.label.hideOversized = false;
    seriesLabel1.label.truncate = false;
    seriesLabel1.label.verticalCenter = "bottom";
    seriesLabel1.label.dy = -10;

    let series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = "dug_van_valute";
    series2.dataFields.categoryX = "tip";
    series2.name = "Ukupan iznos van valute";
    series2.columns.template.tooltipText =
      "{name}: [bold]{valueY.formatNumber('#######,###.##')}[/]€";
    series2.columns.template.fillOpacity = 0.8;
    series2.strokeWidth = 0;
    series2.fill = am4core.color("#DD3131");

    var seriesLabel2 = series2.bullets.push(new am4charts.LabelBullet());
    seriesLabel2.label.text = "{valueY}€";
    seriesLabel2.label.hideOversized = false;
    seriesLabel2.label.truncate = false;
    seriesLabel2.label.verticalCenter = "bottom";
    seriesLabel2.label.dy = -10;

    chart.legend = new am4charts.Legend();
    chart.legend.itemContainers.template.clickable = false;
    chart.legend.labels.template.text = "[#fff]{name}[/]";
    chart.legend.fontFamily = "Roboto";
  };

  renderPotrazivanja = () => {
    let chart = am4core.create(this.props.id, am4charts.XYChart);

    // Add data
    chart.data = [...this.props.potrazivanja];
    chart.maskBullets = false;
    chart.numberFormatter.numberFormat = "#.##a";
    chart.numberFormatter.bigNumberPrefixes = [
      { "number": 1e+0, "suffix": "" },
      { "number": 1e+3, "suffix": "K" },
      { "number": 1e+6, "suffix": "M" },
      { "number": 1e+9, "suffix": "B" }
    ];
    chart.scrollbarY = new am4core.Scrollbar();
    chart.scrollbarY.marginLeft = 0;
    let title = chart.titles.create();
    title.text = this.props.title;
    title.fontSize = 18;
    title.marginBottom = 15;
    title.marginTop = 0;
    title.fontWeight = "500";
    title.fontFamily = "Roboto";
    title.fill = am4core.color("#fff");

    // Create axes

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "tip";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.fontSize = 14;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.labels.template.disabled = true;
    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "ukupno";
    series.dataFields.categoryX = "tip";
    series.name = "Ukupna potraživanja";

    series.columns.template.tooltipText =
      "{name}: [bold]{valueY.formatNumber('#######,###.##')}[/]€";
    series.columns.template.fillOpacity = 0.8;
    series.strokeWidth = 0;
    series.fill = am4core.color("#7BAE45");

    var seriesLabel = series.bullets.push(new am4charts.LabelBullet());
    seriesLabel.label.text = "{valueY}€";
    seriesLabel.label.hideOversized = false;
    seriesLabel.label.truncate = false;
    seriesLabel.label.verticalCenter = "bottom";
    seriesLabel.label.dy = -10;

    let series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.dataFields.valueY = "u_valuti";
    series1.dataFields.categoryX = "tip";
    series1.name = "Ukupna vrijednost potraživanja/dugovanja u valuti";
    series1.columns.template.tooltipText =
      "{name}: [bold]{valueY.formatNumber('#######,###.##')}[/]€";
    series1.columns.template.fillOpacity = 0.8;
    series1.strokeWidth = 0;
    series1.fill = am4core.color("#ED9D10");

    var seriesLabel1 = series1.bullets.push(new am4charts.LabelBullet());
    seriesLabel1.label.text = "{valueY}€";
    seriesLabel1.label.hideOversized = false;
    seriesLabel1.label.truncate = false;
    seriesLabel1.label.verticalCenter = "bottom";
    seriesLabel1.label.dy = -10;

    let series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = "van_valute";
    series2.dataFields.categoryX = "tip";
    series2.name = "Ukupna vrijednost potraživanja/dugovanja van valute";
    series2.columns.template.tooltipText =
      "{name}: [bold]{valueY.formatNumber('#######,###.##')}[/]€";
    series2.columns.template.fillOpacity = 0.8;
    series2.strokeWidth = 0;
    series2.fill = am4core.color("#DD3131");

    var seriesLabel2 = series2.bullets.push(new am4charts.LabelBullet());
    seriesLabel2.label.text = "{valueY}€";
    seriesLabel2.label.hideOversized = false;
    seriesLabel2.label.truncate = false;
    seriesLabel2.label.verticalCenter = "bottom";
    seriesLabel2.label.dy = -10;

    chart.legend = new am4charts.Legend();
    chart.legend.itemContainers.template.clickable = false;
    chart.legend.labels.template.text = "[#fff]{name}[/]";
  };

  renderDuznici = () => {
    let chart = am4core.create(this.props.id, am4charts.XYChart);

    // Add data
    let data: any = [];
    // console.log(this.props.duznici);
    if (this.props.duznici) {
      data = this.props.duznici
        .sort((a, b) => {
          return b.fakturisano - a.fakturisano;
        })
        .slice(0, 5);
    }
    chart.data = [...data];

    chart.maskBullets = false;
    chart.numberFormatter.numberFormat = "#.##a";
    chart.numberFormatter.bigNumberPrefixes = [
      { "number": 1e+0, "suffix": "" },
      { "number": 1e+3, "suffix": "K" },
      { "number": 1e+6, "suffix": "M" },
      { "number": 1e+9, "suffix": "B" }
    ];
    chart.scrollbarY = new am4core.Scrollbar();
    chart.scrollbarY.marginLeft = 0;
    let title = chart.titles.create();
    title.text = this.props.title;
    title.fontSize = 18;
    title.marginBottom = 15;
    title.marginTop = 0;
    title.fontWeight = "500";
    title.fontFamily = "Roboto";
    title.fill = am4core.color("#fff");

    // Create axes

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "kupac";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.fontSize = 14;
    categoryAxis.renderer.labels.template.adapter.add("dy", function(
      dy,
      target
    ) {
      if (target.dataItem && target.dataItem.index % 2 === 0) {
        if (dy !== undefined) {
          return dy + 25;
        }
      }
      return dy;
    });

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.labels.template.disabled = true;
    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "fakturisano";
    series.dataFields.categoryX = "kupac";
    series.name = "Ukupna fakturisana vrijednost";
    series.columns.template.tooltipText =
      "{name}: [bold]{valueY.formatNumber('#######,###.##')}[/]€";
    series.columns.template.fillOpacity = 0.8;
    series.strokeWidth = 0;
    series.fill = am4core.color("#7BAE45");

    var seriesLabel = series.bullets.push(new am4charts.LabelBullet());
    seriesLabel.label.text = "{valueY}€";
    seriesLabel.label.hideOversized = false;
    seriesLabel.label.truncate = false;
    seriesLabel.label.verticalCenter = "bottom";
    seriesLabel.label.dy = -10;

    let series1 = chart.series.push(new am4charts.ColumnSeries());
    series1.dataFields.valueY = "u_valuti";
    series1.dataFields.categoryX = "kupac";
    series1.name = "Ukupna vrijednost potraživanja/dugovanja u valuti";
    series1.columns.template.tooltipText =
      "{name}: [bold]{valueY.formatNumber('#######,###.##')}[/]€";
    series1.columns.template.fillOpacity = 0.8;
    series1.strokeWidth = 0;
    series1.fill = am4core.color("#ED9D10");

    var seriesLabel1 = series1.bullets.push(new am4charts.LabelBullet());
    seriesLabel1.label.text = "{valueY}€";
    seriesLabel1.label.hideOversized = false;
    seriesLabel1.label.truncate = false;
    seriesLabel1.label.verticalCenter = "bottom";
    seriesLabel1.label.dy = -10;

    let series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = "van_valute";
    series2.dataFields.categoryX = "kupac";
    series2.name = "Ukupna vrijednost potraživanja/dugovanja van valute";
    series2.columns.template.tooltipText =
      "{name}: [bold]{valueY.formatNumber('#######,###.##')}[/]€";
    series2.columns.template.fillOpacity = 0.8;
    series2.strokeWidth = 0;
    series2.fill = am4core.color("#DD3131");

    var seriesLabel2 = series2.bullets.push(new am4charts.LabelBullet());
    seriesLabel2.label.text = "{valueY}€";
    seriesLabel2.label.hideOversized = false;
    seriesLabel2.label.truncate = false;
    seriesLabel2.label.verticalCenter = "bottom";
    seriesLabel2.label.dy = -10;

    chart.legend = new am4charts.Legend();
    chart.legend.itemContainers.template.clickable = false;
    chart.legend.marginTop = 20;
    chart.legend.labels.template.text = "[#fff]{name}[/]";
  };

  renderChart = () => {
    let chart = am4core.create(this.props.id, am4charts.XYChart);

    chart.data = [...this.props.data];
    let data: any = [];
    if (this.props.data) {
      data = this.props.data
        .sort((a, b) => {
          return b.vrijednost_faktura - a.vrijednost_faktura;
        })
        .slice(0, 10);
    }
    chart.data = [...data];
    chart.numberFormatter.numberFormat = "#.##a";
    chart.numberFormatter.bigNumberPrefixes = [
      { "number": 1e+0, "suffix": "" },
      { "number": 1e+3, "suffix": "K" },
      { "number": 1e+6, "suffix": "M" },
      { "number": 1e+9, "suffix": "B" }
    ];
    chart.maskBullets = false;
    chart.scrollbarY = new am4core.Scrollbar();
    chart.scrollbarY.marginLeft = 0;
    // Create axes
    let title = chart.titles.create();
    title.text = this.props.title;
    title.fontSize = 18;
    title.marginBottom = 15;
    title.marginTop = 0;
    title.fontWeight = "500";
    title.fontFamily = "Roboto";
    title.fill = am4core.color("#fff");

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "kupac";

    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    if (data.length > 5) {
      categoryAxis.renderer.labels.template.adapter.add("dy", function(
        dy,
        target
      ) {
        if (target.dataItem && target.dataItem.index % 2 === 0) {
          if (dy !== undefined) {
            return dy + 25;
          }
        }
        return dy;
      });
    }
    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;

    const createSeries = (
      field: any,
      name: any,
      stacked: any,
      color: any,
      clustered?: any
    ) => {
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = "kupac";
      series.name = name;
      series.columns.template.tooltipText =
        "{name}: [bold]{valueY.formatNumber('#######,###.##')}[/]€";
      series.stacked = stacked;
      series.columns.template.width = am4core.percent(80);
      series.fill = am4core.color(color);
      series.strokeWidth = 0;

      var seriesLabel = series.bullets.push(new am4charts.LabelBullet());
      seriesLabel.label.text = "{valueY}€";
      seriesLabel.label.hideOversized = false;
      seriesLabel.label.truncate = false;
      seriesLabel.label.verticalCenter = !stacked ? "bottom" : "top";
      seriesLabel.label.dy = !stacked ? -10 : 10;

      if (this.props.data && this.props.data.length < 4) {
        series.columns.template.width = am4core.percent(50);
      }
    };

    createSeries(
      "vrijednost_faktura",
      "Ukupna vrijednost faktura",
      false,
      "#7BAE45"
    );
    createSeries(
      "dug_u_valuti",
      "Ukupna vrijednost duga u valuti",
      false,
      "#FFAC29"
    );
    createSeries(
      "dug_van_valute",
      "Ukupna vrijednost duga van valute",
      false,
      "#EA3838"
    );

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.itemContainers.template.clickable = false;
    chart.legend.marginTop = 20;
    chart.legend.labels.template.text = "[#fff]{name}[/]";
  };

  renderFakture = () => {
    let chart = am4core.create(this.props.id, am4charts.XYChart);

    // Add data
    chart.data = [...this.props.brojFaktura];
    chart.scrollbarY = new am4core.Scrollbar();
    chart.scrollbarY.marginLeft = 0;
    let title = chart.titles.create();
    title.text = this.props.title;
    title.fontSize = 18;
    title.marginBottom = 15;
    title.marginTop = 0;
    title.fontWeight = "500";
    title.fontFamily = "Roboto";
    title.fill = am4core.color("#fff");

    // Create axes

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "linija_biznisa";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "broj_faktura";
    series.dataFields.categoryX = "linija_biznisa";
    series.name = "Broj Faktura";
    series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
    series.columns.template.fillOpacity = 0.8;
    series.strokeWidth = 0;
    if (this.props.brojFaktura && this.props.brojFaktura.length < 4) {
      series.columns.template.width = am4core.percent(25);
    }
    series.columns.template.adapter.add("fill", function(fill, target: any) {
      return chart.colors.getIndex(target.dataItem.index);
    });
  };

  renderTargeti = () => {
    let chart = am4core.create(this.props.id, am4charts.XYChart);
    chart.numberFormatter.numberFormat = "#######,###.##a";
    chart.numberFormatter.bigNumberPrefixes = [
      { "number": 1e+0, "suffix": "" },
      { "number": 1e+3, "suffix": "K" },
      { "number": 1e+6, "suffix": "M" },
      { "number": 1e+9, "suffix": "B" }
    ];
    // Add data
    chart.maskBullets = false;
    chart.scrollbarY = new am4core.Scrollbar();
    chart.scrollbarY.marginLeft = 0;
    // Create axes
    let title = chart.titles.create();
    title.text = this.props.title;
    title.fontSize = 18;
    title.marginBottom = 15;
    title.marginTop = 0;
    title.fontWeight = "500";
    title.fontFamily = "Roboto";
    title.fill = am4core.color("#fff");

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "mjesec";

    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;

    const year = new Date().getFullYear();

    const months = [
      "Januar",
      "Februar",
      "Mart",
      "April",
      "Jun",
      "Jul",
      "Avgust",
      "Septembar",
      "Oktobar",
      "Novembar",
      "Decembar"
    ];

    let currentYear, pastYear;
    if (this.props.targeti) {
      if (this.props.type === 0) {
        currentYear = this.props.targeti.trenutnaGodina.map((e, index) => {
          return {
            year: year.toString(),
            mjesec: e.mjesec,
            target: e.data.target,
            prihodi: e.data.prihodi.toFixed(2),
            monthName: months[index]
          };
        });
      } else {
        currentYear = this.props.targeti.trenutnaGodina.map((e, index) => {
          return {
            year: year.toString(),
            mjesec: e.mjesec,
            target: e.data.target,
            rashodi: e.data.rashodi.toFixed(2),
            monthName: months[index]
          };
        });
      }
    }

    chart.data = [...currentYear];
    function createSeries(
      field: any,
      category: any,
      name: any,
      stacked: any,
      color: any,
      clustered?: any
    ) {
      var series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = field;
      series.dataFields.categoryX = category;
      series.name = name;
      // series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]€";
      series.stacked = stacked;
      // series.columns.template.width = am4core.percent(95);
      series.fill = am4core.color(color);
      series.strokeWidth = 0;
      series.columns.template.adapter.add("tooltipText", (text, target) => {
        var data: any = target.tooltipDataItem.dataContext;
        if (field === "prihodi") {
          return "Prihod: [bold]{valueY.formatNumber('#######,###.##')}[/]€";
        } else if (field === "rashodi") {
          return "Rashod: [bold]{valueY.formatNumber('#######,###.##')}[/]€";
        } else {
          return "Target: [bold]{valueY.formatNumber('#######,###.##')}[/]€";
        }
      });
      var seriesLabel = series.bullets.push(new am4charts.LabelBullet());
      seriesLabel.label.text = "{valueY}€";
      seriesLabel.label.hideOversized = false;
      seriesLabel.label.truncate = false;
      seriesLabel.label.verticalCenter = !stacked ? "bottom" : "top";
      seriesLabel.label.dy = !stacked ? -10 : 10;
    }
    if (this.props.type === 0) {
      createSeries("prihodi", "mjesec", "Prihodi 2019", false, "#7BAE45");
      createSeries("target", "mjesec", "Targeti", false, "#FFAC29");
    } else {
      createSeries("rashodi", "mjesec", "Prihodi 2019", false, "#7BAE45");
      createSeries("target", "mjesec", "Targeti", false, "#FFAC29");
    }
  };

  componentDidUpdate(prevProps: ColumnChartProps) {
    if (prevProps.data !== this.props.data) {
      this.renderChart();
    }
    if (prevProps.brojFaktura !== this.props.brojFaktura) {
      this.renderFakture();
    }
    if (this.props.targeti && prevProps.targeti !== this.props.targeti) {
      this.renderTargeti();
    }
    if (this.props.fakture && prevProps.fakture !== this.props.fakture) {
      this.renderFaktureUkupno();
    }
    if (
      this.props.potrazivanja &&
      prevProps.potrazivanja !== this.props.potrazivanja
    ) {
      this.renderPotrazivanja();
    }
    if (this.props.duznici && prevProps.duznici !== this.props.duznici) {
      this.renderDuznici();
    }
  }

  render() {
    if (
      !this.props.data &&
      !this.props.brojFaktura &&
      !this.props.targeti &&
      !this.props.fakture &&
      !this.props.potrazivanja &&
      !this.props.duznici
    ) {
      return (
        <div
          className="p-r"
          id={this.props.id}
          style={{ height: "100%", width: "100%" }}
        >
          <Loading small />
        </div>
      );
    } else {
      return (
        <div
          id={this.props.id}
          style={{ height: "100%", width: "100%", fontFamily: "Roboto" }}
        />
      );
    }
  }
}

export default ColumnChart;
