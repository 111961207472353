import React, { Component } from "react";
import UnosFaktura from "../../UnosFaktura/UnosFaktura";
import { renderIcon } from "../../Icons/Icons";
import UnosDokumenta from "../../UnosDokumenta/UnosDokumenta";
import UnosUgovora from "../../UnosUgovora/UnosUgovora";
import UnosUsluge from "../../UnosUsluge/UnosUsluge";
import UnosKupca from "../../UnosKupca/UnosKupca";
import UnosAlarma from "../../UnosAlarma/UnosAlarma";

interface IModalBigState {
  date: Date | undefined;
  datum: string;
  faktura: string;
  vrijednost: string;
  delta: number;
  startX: number;
  startY: number;
}

interface IModalBigProps {
  token: string;
  id?: string;
  removeModal: (refresh?: boolean) => void;
  onClick: (
    id: string,
    faktura: string,
    datum: string,
    vrijednost: string
  ) => void;
  title?: string;
  data: {};
  klijent?: string;
  type?: number;
  copy?: boolean;
  pageType?: string;
}

class ModalBig extends Component<IModalBigProps, IModalBigState> {
  escKeyHandler = (event: any) => {
    if (event.keyCode === 27) {
      this.props.removeModal(true);
    }
  };

  mouseDownHandler = (event: any) => {
    this.setState({
      startX: event.pageX,
      startY: event.pageY,
    });
  };

  mouseUpHandler = (event: any) => {
    const diffX = Math.abs(event.pageX - this.state.startX);
    const diffY = Math.abs(event.pageY - this.state.startY);

    if (diffX < this.state.delta && diffY < this.state.delta) {
      if (event.target.classList.contains("modal")) {
        this.props.removeModal(true);
      }
    }
  };

  componentDidMount() {
    this.setState({ delta: 6 });
    document.addEventListener("keyup", this.escKeyHandler);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.escKeyHandler);
  }

  render() {
    let component: any = () => "";
    if (this.props.type !== undefined) {
      if (this.props.type < 3 || this.props.type === 7) {
        component = () => {
          return (
            <UnosFaktura
              edit={true}
              data={this.props.data}
              klijent={this.props.klijent}
              type={this.props.type}
              copy={this.props.copy}
              finish={this.props.removeModal}
              token={this.props.token}
              pageType={this.props.pageType}
            />
          );
        };
      } else if (this.props.type === 3) {
        component = () => {
          return (
            <UnosKupca
              edit={true}
              data={this.props.data}
              copy={this.props.copy}
              token={this.props.token}
            />
          );
        };
      } else if (this.props.type === 4) {
        component = () => {
          return (
            <UnosDokumenta
              edit={true}
              data={this.props.data}
              token={this.props.token}
            />
          );
        };
      } else if (this.props.type === 5) {
        component = () => {
          return (
            <UnosUgovora
              edit={true}
              data={this.props.data}
              token={this.props.token}
            />
          );
        };
      } else if (this.props.type === 6) {
        component = () => {
          return (
            <UnosUsluge
              edit={true}
              data={this.props.data}
              token={this.props.token}
            />
          );
        };
      } else if (this.props.type === 8) {
        component = () => {
          return (
            <UnosAlarma
              edit={true}
              data={this.props.data}
              token={this.props.token}
            />
          );
        };
      }
    }

    return (
      <div
        className="modal fade full modalBig d-flex align-items-center justify-content-center "
        onMouseDown={this.mouseDownHandler}
        onMouseUp={this.mouseUpHandler}
      >
        <div
          className="wrapper p-r"
          style={{ height: "80%" }}
        >
          <div className="header d-flex align-items-center justify-content-center">
            <h5 className="text-color-white f-s-18 f-w-5">
              {this.props.title}
            </h5>
            <span
              className="center-a-v"
              onClick={() => this.props.removeModal(true)}
            >
              {renderIcon("CloseIcon", "closeIcon", 16)}
            </span>
          </div>
          <div className="content pa-20" style={{ height: "100%" }}>
            {component()}
          </div>
        </div>
      </div>
    );
  }
}

export default ModalBig;