import React from "react";
import { calculateTicks } from "../../../../functions/functions";
import { ChartItem } from "./chartComponents/ChartItem";
import { ChartTicks } from "./chartComponents/ChartTicks";
import { formatMoney } from "../../../../functions/formaters";

type chartType = {
  list: any;
  colors: Array<string>;
  type: number;
  id: string;
};

export const Chart = ({ list, colors, type, id }: chartType) => {
  if (type === 0) {
    const values = calculateTicks(list.fakturisano);
    const tickList = values.ticks;
    const maxVal = values.maxVal;
    const uplaceno = (list.uplaceno * 100) / maxVal;
    const dugValuta =
      ((list.fakturisano - list.uplaceno - list.dug_van_valute) * 100) / maxVal;
    const dugValutaValue =
      list.fakturisano - list.uplaceno - list.dug_van_valute;
    const dugVanValuta = (list.dug_van_valute * 100) / maxVal;
    return (
      <>
        <ChartTicks tickList={tickList} />
        <div className="barWrapper mt-20">
          <ChartItem
            customClass="customTooltipGreen"
            moneyValue={formatMoney(list.fakturisano)}
            width={(list.fakturisano * 100) / maxVal}
            color={colors[0]}
            id="ukupno"
          />
          <ChartItem
            customClass="customTooltipOrange"
            moneyValue={formatMoney(list.uplaceno)}
            width={uplaceno}
            color={colors[1]}
            id="uplaceno"
          />
          <ChartItem
            customClass="customTooltipYellow"
            moneyValue={formatMoney(dugValutaValue)}
            width={dugValuta}
            color={colors[2]}
            id="dugValuta"
            subCustomClass="customTooltipRed"
            subMoneyValue={formatMoney(list.dug_van_valute)}
            subWidth={dugVanValuta}
            subColor={colors[3]}
            subId="dugVanValuta"
            subLeft={dugValuta}
          />
        </div>
      </>
    );
  } else if (type === 1) {
    const values = calculateTicks(list.ukupno);
    const tickList = values.ticks;
    const maxVal = values.maxVal;
    const dugVanValuta = (list.van_valute * 100) / maxVal;
    return (
      <>
        <ChartTicks tickList={tickList} />
        <div className="barWrapper mt-20">
          <ChartItem
            customClass="customTooltipOrange"
            moneyValue={formatMoney(list.ukupno)}
            width={(list.ukupno * 100) / maxVal}
            color={colors[0]}
            id="ukupno"
          />
          <ChartItem
            customClass="customTooltipRed"
            moneyValue={formatMoney(list.van_valute)}
            width={dugVanValuta}
            color={colors[1]}
            id="uplaceno"
          />
        </div>
      </>
    );
  } else if (type === 2) {
    const highestValue = list.sort(
      (a: any, b: any) => b.fakturisano - a.fakturisano
    );
    return list.map((el: any, index: number) => {
      const values = calculateTicks(highestValue[0].fakturisano);
      const maxVal = values.maxVal;
      const dugVanValuta = (el.van_valute * 100) / maxVal;
      return (
        <React.Fragment key={index}>
          <div className="barWrapper mt-20">
            <h2 className="text-color-white f-s-18 f-w-4">{`#${index + 1} ${
              el.kupac
            }`}</h2>
            <ChartItem
              customClass="customTooltipOrange"
              moneyValue={formatMoney(el.fakturisano)}
              width={(el.fakturisano * 100) / maxVal}
              color={colors[0]}
              id="ukupno"
            />
            <ChartItem
              customClass="customTooltipRed"
              moneyValue={formatMoney(el.van_valute)}
              width={dugVanValuta}
              color={colors[1]}
              id="uplaceno"
            />
          </div>
        </React.Fragment>
      );
    });
  } else if (type === 3) {
    const highestValue = list.sort(
      (a: any, b: any) => b.fakturisano - a.fakturisano
    );
    return list.map((el: any, index: number) => {
      const values = calculateTicks(highestValue[0].fakturisano);
      const maxVal = values.maxVal;
      const dugVanValuta = (el.van_valute * 100) / maxVal;
      return (
        <React.Fragment key={index}>
          <div className="barWrapper mt-20">
            <h2 className="text-color-white f-s-18 f-w-4">{`#${index + 1} ${
              el.kupac
            }`}</h2>
            <ChartItem
              customClass="customTooltipOrange"
              moneyValue={formatMoney(el.fakturisano)}
              width={(el.fakturisano * 100) / maxVal}
              color={colors[0]}
              id="ukupno"
            />
            <ChartItem
              customClass="customTooltipRed"
              moneyValue={formatMoney(el.van_valute)}
              width={dugVanValuta}
              color={colors[1]}
              id="uplaceno"
            />
          </div>
        </React.Fragment>
      );
    });
  } else {
    return <span>test</span>;
  }
};
