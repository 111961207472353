export const InitialState = {
  fakture: [],
  mjesecneFakture: [],
  dobavljaci: [],
  dobavljaciFiltrirani: [],
  dokumentacija: [],
  dokumentacijaFiltrirana: [],
  sviUgovori: [],
  istekliUgovori: [],
  usluge: [],
  linijeBiznisa: []
};
