import { IIzvjestajiState } from "../Izvjestaji";

export const checkLoaded = (state: IIzvjestajiState) => {
  if (
    state.odnosLosegDuga &&
    state.profitabilnost &&
    state.vrijednostIzlaznihFaktura &&
    state.topKupci &&
    state.topDobavljaci &&
    state.targeti &&
    state.brojFaktura
  ) {
    return true;
  } else {
    return false;
  }
};
