import React, { Component, createRef, ChangeEvent, FormEvent } from "react";
import { IPage } from "../../interfaces/pageInterfaces";
import CustomSelect from "../../components/custom/CustomSelect/CustomSelect";
import axios from "axios";
import TextInput from "../../components/Inputs/TextInput/TextInput";
import { getTargeti } from "../../api/getFunctions";
import { postInsertTarget } from "../../api/postFunctions";
import CustomButton from "../../components/custom/CustomButton/CustomButton";
import ModalSmall from "../../components/modals/ModalSmall/ModalSmall";

interface IState {
  [key: string]: any;
}

class Targeti extends Component<IPage> {
  get years() {
    const now = new Date().getUTCFullYear();
    return Array(now - (now - 50))
      .fill("")
      .map((v, idx) => now - idx) as Array<number>;
  }
  state: IState = {
    modal: false,
    firstDropdown: false,
    secondDropdown: false,
    firstOptions: this.years,
    activeFirst: new Date().getUTCFullYear(),
    results: [
      { id: 0, month: "Jan", prihodi: 0, rashodi: 0 },
      { id: 0, month: "Feb", prihodi: 0, rashodi: 0 },
      { id: 0, month: "Mar", prihodi: 0, rashodi: 0 },
      { id: 0, month: "Apr", prihodi: 0, rashodi: 0 },
      { id: 0, month: "Maj", prihodi: 0, rashodi: 0 },
      { id: 0, month: "Jun", prihodi: 0, rashodi: 0 },
      { id: 0, month: "Jul", prihodi: 0, rashodi: 0 },
      { id: 0, month: "Avg", prihodi: 0, rashodi: 0 },
      { id: 0, month: "Sep", prihodi: 0, rashodi: 0 },
      { id: 0, month: "Okt", prihodi: 0, rashodi: 0 },
      { id: 0, month: "Nov", prihodi: 0, rashodi: 0 },
      { id: 0, month: "Dec", prihodi: 0, rashodi: 0 },
    ],
    type: 0,
  };

  componentDidMount() {
    if (this.props.changePageTitle) {
      this.props.changePageTitle("Targeti");
    }
    window.onclick = () => {
      this.closeDropdowns();
    };
    this.getData();
  }

  axiosCancelSource = axios.CancelToken.source();

  componentWillUnmount() {
    window.onclick = null;
    this.axiosCancelSource.cancel("Component unmounted.");
  }

  firstRef = createRef<any>();

  getData = () => {
    getTargeti(
      this.state.activeFirst,
      this.axiosCancelSource,
      this.props.api
    ).then((res) => {
      if (res.length === 0) {
        this.setState({
          results: [
            { id: 0, month: "Jan", prihodi: 0, rashodi: 0 },
            { id: 0, month: "Feb", prihodi: 0, rashodi: 0 },
            { id: 0, month: "Mar", prihodi: 0, rashodi: 0 },
            { id: 0, month: "Apr", prihodi: 0, rashodi: 0 },
            { id: 0, month: "Maj", prihodi: 0, rashodi: 0 },
            { id: 0, month: "Jun", prihodi: 0, rashodi: 0 },
            { id: 0, month: "Jul", prihodi: 0, rashodi: 0 },
            { id: 0, month: "Avg", prihodi: 0, rashodi: 0 },
            { id: 0, month: "Sep", prihodi: 0, rashodi: 0 },
            { id: 0, month: "Okt", prihodi: 0, rashodi: 0 },
            { id: 0, month: "Nov", prihodi: 0, rashodi: 0 },
            { id: 0, month: "Dec", prihodi: 0, rashodi: 0 },
          ],
        });
        // this.state.results.map((e: any, index: number) => {
        //   if (index + 1 < 10) {
        //     const data = {
        //       godina: this.state.activeFirst,
        //       mjesec: "0" + (index + 1),
        //       godinamjesec: this.state.activeFirst + "-0" + (index + 1),
        //       rashodi: "0",
        //       prihodi: "0"
        //     };
        //     postInsertTarget(data, this.axiosCancelSource, this.props.api).then(
        //       res => {
        //         if (res) {
        //           this.setState((prevState: any) => ({
        //             results: [
        //               ...prevState.results.slice(0, index),
        //               {
        //                 ...prevState.results[index],
        //                 prihodi: 0,
        //                 rashodi: 0
        //               },
        //               ...prevState.results.slice(index + 1)
        //             ]
        //           }));
        //         }
        //       }
        //     );
        //   } else {
        //     const data = {
        //       godina: this.state.activeFirst,
        //       mjesec: (index + 1).toString(),
        //       godinamjesec: this.state.activeFirst + "-" + (index + 1),
        //       rashodi: "0",
        //       prihodi: "0"
        //     };
        //     postInsertTarget(data, this.axiosCancelSource, this.props.api).then(
        //       res => {
        //         if (res) {
        //           this.setState((prevState: any) => ({
        //             results: [
        //               ...prevState.results.slice(0, index),
        //               {
        //                 ...prevState.results[index],
        //                 prihodi: 0,
        //                 rashodi: 0
        //               },
        //               ...prevState.results.slice(index + 1)
        //             ]
        //           }));
        //         }
        //       }
        //     );
        //   }
        // });
      } else {
        res.sort((a: any, b: any) => (a.mjesec > b.mjesec ? 1 : -1));
        res.map((e: any, index: number) => {
          this.setState((prevState: any) => ({
            results: [
              ...prevState.results.slice(0, index),
              {
                ...prevState.results[index],
                id: e.id,
                prihodi: e.prihodi,
                rashodi: e.rashodi,
              },
              ...prevState.results.slice(index + 1),
            ],
          }));
        });
      }
    });
  };

  closeDropdowns = (item?: number) => {
    if (item === 0) {
      this.setState({ secondDropdown: false });
    } else if (item === 1) {
      this.setState({ firstDropdown: false });
      if (this.state.type !== 4) this.firstRef.current.style.height = "0px";
    } else {
      this.setState({ firstDropdown: false, secondDropdown: false });
      this.firstRef.current.style.height = "0px";
    }
  };

  handleDropdown = (e: React.MouseEvent<HTMLButtonElement>, name: any) => {
    const item = name === "firstDropdown" ? 0 : 1;
    this.closeDropdowns(item);
    e.stopPropagation();
    this.setState((prevState: any) => ({
      [name]: !prevState[name],
    }));
  };

  handleFilterClick = (e: React.MouseEvent<HTMLLIElement>, name: string) => {
    const type = this.state.firstOptions.findIndex(
      (el: any) => el === e.currentTarget.innerHTML
    );
    this.setState({ [name]: e.currentTarget.innerHTML, type }, () => {
      this.getData();
    });
  };

  handleInputChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    name: string,
    subname: string,
    index: number
  ) => {
    e.persist();
    this.setState((prevState: any) => ({
      ...prevState,
      results: [
        ...prevState.results.slice(0, index),
        {
          ...prevState.results[index],
          [name]: parseInt(e.target.value) >= 0 ? parseInt(e.target.value) : 0,
        },
        ...prevState.results.slice(index + 1),
      ],
    }));
  };

  handleInputBlur = (e: FormEvent) => {
    const fixedArray = this.state.results.map((e: any) => {
      if (isNaN(e.prihodi) || parseInt(e.prihodi) < 0) {
        return {
          month: e.month,
          prihodi: 0,
          rashodi: e.rashodi,
        };
      } else if (isNaN(e.rashodi) || parseInt(e.rashodi) < 0) {
        return {
          month: e.month,
          prihodi: e.prihodi,
          rashodi: 0,
        };
      } else {
        return e;
      }
    });
    this.setState({ results: fixedArray });
  };

  resetForm = () => {
    this.getData();
  };

  saveTargeti = () => {
    this.state.results.map((e: any, index: number) => {
      const data = {
        id: e.id,
        godina: this.state.activeFirst,
        rashodi: e.rashodi,
        prihodi: e.prihodi,
        mjesec: (index + 1 < 10 ? "0" : "") + (index + 1),
        godinamjesec:
            this.state.activeFirst + (index + 1 < 10 ? "-0" : "-") + (index + 1),
      };
      postInsertTarget(data, this.axiosCancelSource, this.props.api).then(
          (res) => {
            if(index === 11){
              this.setState({
                modal: true,
              })
            }
            this.getData()
          })
    })
  };

  render() {
    const prihodiUkupno = this.state.results
      .map((e: any) => {
        return !isNaN(e.prihodi) && parseInt(e.prihodi) >= 0 ? e.prihodi : 0;
      })
      .reduce((a: number, b: number) => a + b);

    const rashodiUkupno = this.state.results
      .map((e: any) => {
        return !isNaN(e.rashodi) && parseInt(e.rashodi) >= 0 ? e.rashodi : 0;
      })
      .reduce((a: number, b: number) => a + b);
    return (
      <div className="targetiContainer">
        {this.state.modal && (
          <ModalSmall
            question="Target uspješno sačuvan."
            buttons={["euro"]}
            options={["U redu"]}
            id={""}
            onClick={() => false}
            removeModal={() => this.setState({ modal: false })}
            title="Target sačuvan"
          />
        )}

        <div className="row">
          <div className="col-sm-12 col-lg-6">
            <div className="header d-flex justify-content-center mb-20">
              <CustomSelect
                name="activeFirst"
                title={this.state.activeFirst}
                options={this.state.firstOptions}
                open={this.state.firstDropdown}
                reference="firstDropdown"
                toggleDropdown={this.handleDropdown}
                ref={this.firstRef}
                handleFilterClick={this.handleFilterClick}
              />
            </div>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="content">
                <div className="title d-flex">
                  <div className="col-sm-4 d-flex align-items-center justify-content-center">
                    <h5 className="f-s-12 f-w-5">Mjesec</h5>
                  </div>
                  <div className="col-sm-4 d-flex align-items-center justify-content-center">
                    <h5 className="f-s-12 f-w-5">Prihodi</h5>
                  </div>
                  <div className="col-sm-4 d-flex align-items-center justify-content-center">
                    <h5 className="f-s-12 f-w-5">Rashodi</h5>
                  </div>
                </div>
                {this.state.results.map((e: any, index: number) => {
                  return (
                    <div className="results d-flex" key={index}>
                      <div className="col-sm-4 d-flex align-items-center justify-content-center">
                        <h5 className="f-s-12 f-w-4">{e.month}</h5>
                      </div>
                      <div className="col-sm-4 d-flex align-items-center justify-content-center p-r">
                        <TextInput
                          placeholder=""
                          value={e.prihodi.toString()}
                          onChange={this.handleInputChange}
                          name="prihodi"
                          className="text-right"
                          number
                          index={index}
                          min={0}
                          onBlur={this.handleInputBlur}
                        />
                        <span className="f-s-12 euro">€</span>
                      </div>
                      <div className="col-sm-4 d-flex align-items-center justify-content-center">
                        <TextInput
                          placeholder=""
                          value={e.rashodi.toString()}
                          onChange={this.handleInputChange}
                          name="rashodi"
                          className="text-right"
                          number
                          index={index}
                          min={0}
                          onBlur={this.handleInputBlur}
                        />
                        <span className="f-s-12 euro">€</span>
                      </div>
                    </div>
                  );
                })}
                <div className="total d-flex">
                  <div className="col-sm-4 d-flex align-items-center justify-content-center">
                    <h5 className="f-s-12 f-w-5">Ukupno</h5>
                  </div>
                  <div className="col-sm-4 d-flex align-items-center justify-content-end">
                    <h5 className="f-s-12 f-w-5 pr-5">
                      {prihodiUkupno} <span className="pl-5">€</span>
                    </h5>
                  </div>
                  <div className="col-sm-4 d-flex align-items-center justify-content-end">
                    <h5 className="f-s-12 f-w-5 pr-5">
                      {rashodiUkupno} <span className="pl-5">€</span>
                    </h5>
                  </div>
                </div>
                <div className="save d-flex pb-20 mt-20 justify-content-center">
                  <CustomButton
                    text="Reset"
                    onClick={this.resetForm}
                    type="reset"
                  />
                  <div style={{ width: "1rem" }}></div>
                  <CustomButton
                    text="Sačuvaj"
                    onClick={this.saveTargeti}
                    type="save"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Targeti;
