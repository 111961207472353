import React, { ChangeEvent } from "react";
import DateInput from "./DateInput/DateInput";
import TextInput from "./TextInput/TextInput";
import { IDateInput, ITextInput } from "../../interfaces/inputInterfaces";
import { ICustomSelect } from "../../interfaces/pageInterfaces";
import CustomSelect from "../custom/CustomSelect/CustomSelect";

interface IInputs {
  [key: string]: any;
}

const inputs: IInputs = {
  DateInput: ({ date, onChange, name }: IDateInput) => {
    return <DateInput date={date} onChange={onChange} name={name} />;
  },
  TextInput: ({ placeholder, value, onChange, name, type }: ITextInput) => {
    return (
      <TextInput
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
        type={type}
      />
    );
  },
  CustomSelect: ({
    name,
    title,
    options,
    open,
    reference,
    toggleDropdown,
    handleFilterClick,
    ref,
    white
  }: ICustomSelect) => {
    return (
      <CustomSelect
        name={name}
        title={title}
        options={options}
        open={open}
        reference={reference}
        toggleDropdown={toggleDropdown}
        handleFilterClick={handleFilterClick}
        ref={ref}
        white={white}
      />
    );
  }
};

interface renderInputType {
  data: IDateInput | ITextInput | ICustomSelect;
}

export const renderInput = (name: string, { data }: renderInputType) => {
  if (name !== "") {
    return inputs[name](data);
  }
};
