import React, { Component } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { formatMoney } from "../../../functions/formaters";
import Loading from "../../Loading/Loading";

am4core.useTheme(am4themes_animated);

type DougnutProps = {
  data: Array<{
    name: string;
    value: number;
  }> | null;
  title: string;
  id: string;
};

class DoughnutChart extends Component<DougnutProps> {
  renderDougnut = () => {
    var chart = am4core.create(this.props.id, am4charts.PieChart);

    // Add data
    if (this.props.data) {
      chart.data = this.props.data.map(e => e);
    }
    // Add and configure Series
    var pieSeries = chart.series.push(new am4charts.PieSeries());
    // pieSeries.hiddenState.transitionDuration = 50000;
    pieSeries.dataFields.value = "value";
    pieSeries.dataFields.category = "name";
    // pieSeries.ticks.template.disabled = true;
    // pieSeries.alignLabels = false;
    pieSeries.labels.template.text = "{value.formatNumber('#.#a')}€";
    pieSeries.labels.template.fontSize = 13;
    // pieSeries.labels.template.radius = am4core.percent(-25);
    pieSeries.labels.template.fill = am4core.color("#fff");

    // pokusaj da napravis da ukoliko je vrijednost preko 50% da labela za chart ne ide izvan charta
    // nego da je u njemu
    pieSeries.labels.template.adapter.add("fill", function(color, target) {
      if (target.dataItem && (target.dataItem.values.value.percent > 50)) {
        return am4core.color('#fff');
      }
      return color;
    });



    const ukupno = this.props.data && this.props.data.map(e => e.value);
    if (ukupno && ukupno.length > 0) {
      const zbir = ukupno.reduce((a: number, b: number): number => {
        return a + b;
      });
    }

    // console.log(pieSeries.slices.template.tooltipText);

    pieSeries.slices.template.tooltipText =
      "{category}: \n [bold]{value.value.formatNumber('#.#a')}[/] ({value.percent.formatNumber('#.00')}%)";

    pieSeries.tooltip!.getFillFromObject = false;
    pieSeries.tooltip!.background.fill = am4core.color("#333");
    pieSeries.tooltip!.background.opacity = 0.8;
    pieSeries.tooltip!.fontSize = 13;

    // Let's cut a hole in our Pie chart the size of 40% the radius
    chart.innerRadius = am4core.percent(40);

    // Put a thick white border around each Slice
    pieSeries.slices.template.stroke = am4core.color("#ccc");
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.strokeOpacity = 0.2;
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    // Add a legend
    chart.legend = new am4charts.Legend();
    chart.legend.itemContainers.template.clickable = false;
    chart.legend.labels.template.text = "[#fff]{name}[/]";
    chart.legend.valueLabels.template.text = "";
    let legendContainer = am4core.create(
      this.props.id + "legend",
      am4core.Container
    );
    legendContainer.width = am4core.percent(100);
    legendContainer.height = am4core.percent(100);
    chart.legend.parent = legendContainer;
    const resizeLegend = (ev: any) => {
      document.getElementById(this.props.id + "legend")!.style.height =
        chart.legend.contentHeight + "px";
    };
    chart.events.on("datavalidated", resizeLegend);
    chart.events.on("maxsizechanged", resizeLegend);

    let markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 15;
    markerTemplate.height = 15;
    chart.exporting.menu = new am4core.ExportMenu();
    chart.exporting.extraSprites.push({
      sprite: legendContainer,
      position: "bottom",
      marginTop: 20
    });
    chart.exporting.menu.align = "right";
    chart.exporting.menu.verticalAlign = "top";
    chart.exporting.menu.items = [
      {
        label: "Preuzmi",
        menu: [
          { type: "png", label: "PNG" },
          { type: "jpg", label: "JPG" },
          { type: "gif", label: "GIF" },
          { type: "svg", label: "SVG" },
          { type: "pdf", label: "PDF" }
        ]
      }
    ];

    // ovo je za pozicioniranje 'export' dugmeta samo za 'pie chartove'
    // trenutno je gore desno.. ranije je bio dolje desno ali je smetao broju da se prikaze
    // vidjeti sa dizajnerima/testerima koja je solucija najbolja
    chart.exporting.menu.align = "left";
    chart.exporting.menu.verticalAlign = "top";
    let title = chart.titles.create();
    title.text = this.props.title;
    title.fontSize = 18;
    title.marginBottom = 20;
    title.marginTop = 15;
    title.fontWeight = "500";
    title.fontFamily = "Roboto";
    title.fill = am4core.color("#fff");
    title.wrap = true;
  };
  componentDidMount() {
    if (this.props.data) {
      this.renderDougnut();
    }
  }

  componentDidUpdate(prevProps: DougnutProps, prevState: any) {
    if (this.props.data && prevProps.data !== this.props.data) {
      this.renderDougnut();
    }
  }

  handleZbir = (ammount: number) => {
    if (ammount < 1000) {
      return formatMoney(ammount) + " €";
    } else if (ammount > 1000 && ammount < 1000000) {
      return formatMoney(ammount / 1000) + "k €";
    } else {
      return formatMoney(ammount / 1000000) + "M €";
    }
  };

  render() {
    const ukupno = this.props.data && this.props.data.map(e => e.value);
    let zbir;
    if (ukupno && ukupno.length > 0) {
      zbir = ukupno.reduce((a: number, b: number): number => {
        return a + b;
      });
    } else {
      zbir = 0;
    }

    if (!this.props.data) {
      return (
        <div className="dougnut p-r">
          <div id={this.props.id} style={{ width: "100%", height: "380px" }}>
            <Loading small />
          </div>
        </div>
      );
    } else {
      return (
        <>
          <div className="dougnut p-r">
            <div
              id={this.props.id}
              style={{ width: "100%", height: "380px" }}
            />
            <div
              className="info  center-a-b d-flex align-items-center justify-content-center flex-column"
              style={{ transform: "translate(-50%,0)" }}
            >
              <span className="title f-s-16 text-color-white text-transform-uppercase">
                Ukupno
              </span>
              <span className="title f-s-16 text-color-white text-transform-uppercase f-w-5">
                {this.handleZbir(zbir)}
              </span>
            </div>
          </div>
          <div
            id={this.props.id + "legend"}
            style={{ width: "100%", height: "200px" }}
          />
        </>
      );
    }
  }
}

export default DoughnutChart;
