import { IUnosAlarma } from "../UnosAlarma";

export const validateForm = (data: IUnosAlarma, type: string) => {
    let invalidFields: Array<any> = [];
    if (type === "unos") {
        if (data.naziv.trim() === "") {
            invalidFields.push("Polje naziv alarma je obavezno!");
        } else if(data.naziv.trim().length > 100){
            invalidFields.push("Dužina naziva alarma ne može biti veća od 100 karaktera!");
        }

        if(!data.date) {
            invalidFields.push("Polje datum je obavezno!");
        }

        if(data.obavjestenje.trim() === "") {
            invalidFields.push("Polje obavjestenje je obavezno!");
        } else if (data.obavjestenje.trim().length > 1000) {
            invalidFields.push("Dužina obavjestenja ne može biti veća od 1000 karaktera");
        }
    }
    return invalidFields;
};
