import { sortItems } from "../../../functions/functions";

export const sortByHelper = (
  data: Array<any>,
  type: any,
  direction: boolean,
  object: string,
  sortName: string
) => {
  if (sortName === "br_fakture" || sortName === "napomena") {
    const sortedArray = sortItems(data, "string", direction, sortName)
      .sortedArray;
    return sortedArray;
  } else if (
    sortName === "datum" ||
    sortName === "datum_zak" ||
    sortName === "datum_vazenja"
  ) {
    const sortedArray = sortItems(data, "datum", direction, sortName)
      .sortedArray;
    return sortedArray;
  } else if (
    sortName === "iznos" ||
    sortName === "vrijednost" ||
    sortName === "iznos" ||
    sortName === "uplaceno" ||
    sortName === "dug"
  ) {
    const sortedArray = sortItems(data, 1, direction, sortName).sortedArray;
    return sortedArray;
  }
};
