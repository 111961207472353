export const customAnimation = (
  startValue: number,
  endValue: number,
  durationMs: number,
  setProgress: (value: any) => void
) => {
  const easeInCubic = (t: any, b: any, c: any, d: any) => {
    return c * (t /= d) * t * t + b;
  };
  var currentValue = startValue;
  // console.log(endValue);
  var startTime = new Date().getTime();
  var increment = endValue - currentValue;
  // console.log(window.innerWidth);
  var animation = setInterval(() => {
    var time: any = new Date().getTime() - startTime;
    if (time < durationMs) {
      var percentage = easeInCubic(time, 0, increment, durationMs).toFixed(2);
      try {
        setProgress(percentage);
      } catch (e) {
        myStopFunction(animation);
      }
    } else {
      try {
        setProgress(endValue);
        myStopFunction(animation);
      } catch (e) {
        myStopFunction(animation);
      }
    }
  }, 13);
};

export const animateScrollTo = (
  startValue: number,
  endValue: number,
  durationMs: number,
  element?: HTMLElement | null
) => {
  const easeOutQuint = (t: any, b: any, c: any, d: any) => {
    return c * ((t = t / d - 1) * t * t * t * t + 1) + b;
  };

  var startTime = new Date().getTime();
  var ammountToChange = endValue - startValue;

  const myStopFunction = (f: any) => {
    clearInterval(f);
  };

  var animation = setInterval(function() {
    var time = new Date().getTime() - startTime;
    if (time < durationMs) {
      if (element) {
        element.scrollTo(
          0,
          easeOutQuint(time, startValue, ammountToChange, durationMs)
        );
      } else {
        window.scrollTo(
          0,
          easeOutQuint(time, startValue, ammountToChange, durationMs)
        );
      }
    } else {
      window.scrollTo(0, endValue);
      myStopFunction(animation);
    }
  }, 13);
};

const myStopFunction = (f: any) => {
  clearInterval(f);
};
