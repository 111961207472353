import { InitialState } from "../InitialState/InitialState";

export const IzlazneFakturePretragaNav = [
  { icon: "SearchIcon", title: "Pretraga faktura" },
  { icon: "DocumentIcon", title: "Unesi fakturu" },
  { icon: "SearchIcon", title: "Mjesečne fakture" }
];

export const IzlazneFakturePretraga = {
  buttons: [
    { icon: "EuroIcon", text: "Uplata", type: "euro" },
    { icon: "DocumentIcon", text: "Prikaži", type: "document" },
    { icon: "DeleteDocumentIcon", text: "Obriši", type: "delete" },
    { icon: "DocumentIcon", text: "Kopija", type: "document", mod: "copy" }
  ],
  state: {
    datumOd: undefined,
    datumDo: undefined,
    loadMore: false,
    pageNumber: 0,
    modal: false,
    modalType: "",
    modalID: "",
    sortBy: {
      name: "datum",
      direction: true
    },
    filter: {
      od: "",
      do: "",
      brfakture: "",
      klijent: "",
      grad: "",
      iznos: "",
      currentpage: 1
    },
    ...InitialState,
    dropDown: {
      name: "statusDropdown",
      open: false,
      options: [
        "Status",
        "Djelimično pl. van valute",
        "Neplaćeno u valuti",
        "Neplaćeno van valute",
        "Plaćeno"
      ],
      reference: "dropDown",
      title: "Status"
    }
  },
  table: {
    thead: {
      firstRow: [
        "Status",
        "Fiskalizovana",
        "Datum od",
        "Datum do",
        "Br. fakture",
        "Kupac",
        "Grad",
        "Iznos",
        "Dug"
      ],
      secondRow: [
        { name: "", property: "status" },
        { name: "", property: "fisk_status" },
        { name: "DateInput", property: "datumOd" },
        { name: "DateInput", property: "datumDo" },
        { name: "TextInput", property: "brfakture" },
        { name: "TextInput", property: "klijent" },
        { name: "TextInput", property: "grad" },
        { name: "", property: "iznos" },
        { name: "", property: "dug" }
      ]
    }
  }
};

export const IzlazneFakturePretragaLegend = [
  {
    color: "green",
    title: "Plaćena faktura"
  },
  {
    color: "blue",
    title: "Neplaćena fak. u valuti "
  },
  {
    color: "red",
    title: "Neplaćena fak. van valute"
  },
  {
    color: "yellow",
    title: "Djelimično plaćena fak. u valuti"
  },
  {
    color: "orange",
    title: "Djelimično plaćena fak. van valute"
  }
];