import React, { Component, ChangeEvent } from "react";
import Select from "react-dropdown-select";

import { renderCheckbox } from "./../../pages/TableDisplay/functions/renderFunctions";
import TextInput from "../Inputs/TextInput/TextInput";

import { getDrzave, getGradovi } from "../../api/getFunctions";
import axios from "axios";
import TextArea from "../Inputs/TextArea/TextArea";
import CustomButton from "../custom/CustomButton/CustomButton";
import { postInstertKlijent } from "../../api/postFunctions";
import ModalErrors from "../modals/ModalErrors/ModalErrors";
import ModalSmall from "../modals/ModalSmall/ModalSmall";
import { validateForm } from "./functions/validateForm";
import { emailIsValid } from "../../functions/functions";

export interface IUnosKupca {
  firstCheckBox: boolean;
  secondCheckBox: boolean;
  id: string;
  naziv_firme: string;
  pib: string;
  grad: string;
  listaGradova: Array<{ id: string; name: string; drzava: string }>;
  gradName: "";
  adresa: string;
  kontakt_osoba: string;
  telefon: string;
  email: string;
  kupac: string;
  dobavljac: string;
  ziro_racun: string;
  web: string;
  napomena: string;
  pdv_broj: string;
  drzava: string;
  listaDrzava: Array<{ id: string; name: string }>;
  drzavaName: string;
  errors: Array<string>;
  modal: boolean;
}

type UnosKupcaProps = {
  token: string;
  edit?: boolean;
  copy?: boolean;
  data?: any;
};

type StateKeys = keyof IUnosKupca;

class UnosKupca extends Component<UnosKupcaProps> {
  form: any = React.createRef();
  state: IUnosKupca = {
    firstCheckBox: true,
    secondCheckBox: false,
    id: "",
    naziv_firme: "",
    pib: "",
    grad: "",
    listaGradova: [],
    gradName: "",
    adresa: "",
    kontakt_osoba: "",
    telefon: "",
    email: "",
    kupac: "",
    dobavljac: "",
    ziro_racun: "",
    web: "",
    napomena: "",
    pdv_broj: "",
    drzava: "",
    listaDrzava: [],
    drzavaName: "",
    errors: [],
    modal: false,
  };

  //*****************************************/
  //*              LIFECYCLE                */
  //*****************************************/

  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    getDrzave(this.axiosCancelSource, this.props.token).then((listaDrzava) =>
      this.setState({ listaDrzava })
    );
    getGradovi(this.axiosCancelSource, this.props.token).then(
      (listaGradova) => {
        this.setState({ listaGradova });
      }
    );

    if (this.props.edit || this.props.copy) {
      const data = this.props.data;
      this.setState((prevState: IUnosKupca) => ({
        ...prevState,
        ...data,
        firstCheckBox: data.kupac === 1,
        secondCheckBox: data.dobavljac === 1,
      }));

      this.updateCountry(data.drzava_id, data.drzava);
    }
  }

  componentWillUnmount() {
    this.axiosCancelSource.cancel("Component unmounted.");
  }

  //*****************************************/
  //*              LIFECYCLE                */
  //*****************************************/

  //****************************************?/
  //?              CUSTOM FUNCTIONS         */
  //****************************************?/

  toggleCheckbox = (name: StateKeys) => {
    this.setState((prevState: IUnosKupca) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  handleInputChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    name: StateKeys,
    subname?: any,
    index?: number
  ) => {
    e.persist();
    const regNumbers = new RegExp("^[0-9]+$");

    if (name === "pib") {
      if (
        (regNumbers.test(e.target.value) || e.target.value.length === 0) &&
        e.target.value.length <= 8
      ) {
        this.setState({ pib: e.target.value });
      } else {
        if (!regNumbers.test(e.target.value)) {
          this.setState({
            errors: ["PIB može sadržati samo brojeve."],
          });
          this.showModal();
        } else {
          this.setState({
            errors: ["PIB ne može sadržati više od 8 karaktera."],
          });
          this.showModal();
        }
      }
    } else if (name === "napomena") {
      if (this.state.napomena.length <= 1000) {
        this.setState((prevState) => ({
          ...prevState,
          [name]: e.target.value,
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          [name]: e.target.value.substr(0, 1000),
          errors: ["Dužina napomene ne može biti veća od 1000 karaktera"],
        }));

        this.showModal();
      }
    } else if (name === "naziv_firme") {
      if (this.state.naziv_firme.length <= 100) {
        this.setState((prevState) => ({
          ...prevState,
          [name]: e.target.value,
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          [name]: e.target.value.substr(0, 100),
          errors: ["Dužina naziva firme ne može biti veća od 100 karaktera"],
        }));

        this.showModal();
      }
    } else {
      const event: any = e.nativeEvent;
      if (e.target.value.length === 1) {
        if (event.data !== " ") {
          this.setState((prevState) => ({
            ...prevState,
            [name]: e.target.value.replace(/\s+/g, " "),
          }));
        }
      } else {
        this.setState((prevState) => ({
          ...prevState,
          [name]: e.target.value.replace(/\s+/g, " "),
        }));
      }
    }
  };

  updateCountry = (id: any, name: any) => {
    this.setState(
      {
        drzava: id,
        drzavaName: name,
      },
      () => {
        getGradovi(
          this.axiosCancelSource,
          this.props.token,
          this.state.drzava
        ).then((listaGradova) => {
          this.setState({ listaGradova });
          if (this.props.edit) {
            this.setState({
              grad: this.props.data.grad_id,
              gradName: this.props.data.grad,
            });
          }
        });
      }
    );
  };

  handleSelectChange = (
    val: any,
    name: StateKeys,
    index?: number,
    edit?: boolean
  ) => {
    if (name === "drzava" && val.length > 0) {
      this.updateCountry(val[0].id, val[0].naziv);
    } else if (name === "drzava" && val.length === 0) {
      this.setState(
        {
          drzava: "",
          drzavaName: "",
        },
        () => this.setState({ listaGradova: [], grad: "", gradName: "" })
      );
    }

    if (name === "grad" && val.length > 0) {
      this.setState({
        grad: val[0].id,
        gradName: val[0].naziv,
      });
    } else if (name === "grad" && val.length === 0) {
      this.setState({
        grad: "",
        gradName: "",
      });
    }
  };

  showModal = () => {
    this.setState({ modal: true });
  };
  removeModal = () => {
    this.setState({ modal: false, errors: [] });
  };

  renderModal = () => {
    if (this.state.errors.length > 0) {
      return (
        <ModalErrors
          title="Greška prilikom unosa klijenta"
          errors={this.state.errors}
          removeModal={this.removeModal}
          message="Greška prilikom unosa klijenta"
        />
      );
    } else {
      return (
        <ModalSmall
          question="Klijent uspješno sačuvan."
          buttons={["euro"]}
          options={["U redu"]}
          id={""}
          onClick={() => false}
          removeModal={this.removeModal}
          title="Klijent sačuvan"
        />
      );
    }
  };

  resetForm = () => {
    this.setState({
      naziv_firme: "",
      pib: "",
      grad: "",
      gradName: "",
      adresa: "",
      kontakt_osoba: "",
      telefon: "",
      email: "",
      kupac: "",
      dobavljac: "",
      ziro_racun: "",
      web: "",
      napomena: "",
      pdv_broj: "",
      drzava: "",
      drzavaName: "",
    });
    if (this.props.edit) {
      const data = this.props.data;
      this.setState((prevState: IUnosKupca) => ({
        ...prevState,
        ...data,
        firstCheckBox: data.kupac === 1,
        secondCheckBox: data.dobavljac === 1,
      }));

      this.updateCountry(data.drzava_id, data.drzava);
    }
  };

  inserKlijent = () => {
    const {
      id,
      naziv_firme,
      pib,
      pdv_broj,
      adresa,
      drzava,
      grad,
      ziro_racun,
      web,
      kontakt_osoba,
      email,
      telefon,
      napomena,
      firstCheckBox,
      secondCheckBox,
    } = this.state;
    let errors;
    const data = {
      naziv: naziv_firme,
      pib,
      pdv_broj,
      adresa,
      drzava,
      grad,
      ziro_racun,
      web,
      kontakt_osoba,
      email,
      telefon,
      kupac: firstCheckBox ? 1 : 0,
      dobavljac: secondCheckBox ? 1 : 0,
      napomena,
    };
    if (this.props.edit && !this.props.copy) {
      // @ts-ignore
      data.id = id;
    }
    errors = validateForm(this.state, "unos");

    if (email !== "" && !emailIsValid(email)) {
      errors.push("Email koji ste unijeli nije validan.");
    }
    if (errors.length === 0) {
      postInstertKlijent(data, this.axiosCancelSource, this.props.token).then(
        (res) => {
          if (res.id) {
            if (!this.props.edit) {
              this.setState((prevState: IUnosKupca) => ({
                naziv_firme: "",
                pib: "",
                pdv_broj: "",
                adresa: "",
                ziro_racun: "",
                web: "",
                kontakt_osoba: "",
                email: "",
                telefon: "",
              }));
              this.handleSelectChange([], "drzava", 0);
              this.handleSelectChange([], "grad", 0);
            }
            this.showModal();
          } else {
            console.log("Something went wrong");
          }
        }
      );
    } else {
      this.setState({ errors }, () => {
        this.showModal();
      });
    }
  };

  //****************************************?/
  //?              CUSTOM FUNCTIONS         */
  //****************************************?/

  render() {
    const s = this.state;
    return (
      <div>
        <form ref={this.form} onSubmit={(e) => e.preventDefault()}>
          <div className="unosFakturaContainer">
            <div className="row">
              <div className="col-sm-12">
                {renderCheckbox(
                  this.state.firstCheckBox,
                  this.state.secondCheckBox,
                  this.toggleCheckbox,
                  3,
                  true
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-4">
                <div className="title f-s-12 f-w-5 mb-5">
                  <label htmlFor="naziv_firme">
                    Naziv firme <span>*</span>
                  </label>
                </div>
                <div className="input">
                  <TextInput
                    placeholder=""
                    value={s.naziv_firme}
                    onChange={this.handleInputChange}
                    name="naziv_firme"
                    id="naziv_firme"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-4">
                <div className="title f-s-12 f-w-5 mb-5">
                  <label htmlFor="pib">PIB</label>
                </div>
                <div className="input">
                  <TextInput
                    placeholder=""
                    value={s.pib}
                    onChange={this.handleInputChange}
                    name="pib"
                    id="pib"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-4">
                <div className="title f-s-12 f-w-5 mb-5">
                  <label htmlFor="pdv_broj">PDV Broj</label>
                </div>
                <div className="input">
                  <TextInput
                    placeholder=""
                    value={s.pdv_broj}
                    onChange={this.handleInputChange}
                    name="pdv_broj"
                    id="pdv_broj"
                    type={"formated"}
                    format="##/##-#####-#"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-sm-12 col-lg-6 col-xl-3">
                <div className="title f-s-12 f-w-5 mb-5">
                  <span>
                    Država <span>*</span>
                  </span>
                </div>
                <div className="input p-r">
                  <label htmlFor="" className="react-select-label f-s-12">
                    {s.drzavaName}
                  </label>
                  <Select
                    options={s.listaDrzava}
                    labelField="naziv"
                    valueField="id"
                    placeholder=""
                    clearable={true}
                    dropdownGap={0}
                    clearOnSelect={true}
                    onChange={(val: any) =>
                      this.handleSelectChange(val, "drzava", 0)
                    }
                    onClearAll={() => this.handleSelectChange([], "drzava", 0)}
                    noDataRenderer={() => (
                      <span className="text-center ptb-5">Nema rezultata</span>
                    )}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-xl-3">
                <div className="title f-s-12 f-w-5 mb-5">
                  <span>
                    Grad <span>*</span>
                  </span>
                </div>
                <div className="input p-r">
                  <label htmlFor="" className="react-select-label f-s-12">
                    {s.gradName}
                  </label>
                  <Select
                    options={s.listaGradova}
                    labelField="naziv"
                    valueField="id"
                    placeholder=""
                    clearable={true}
                    dropdownGap={0}
                    clearOnSelect={true}
                    onChange={(val: any) =>
                      this.handleSelectChange(val, "grad", 0)
                    }
                    onClearAll={() => this.handleSelectChange([], "grad", 0)}
                    noDataRenderer={() => (
                      <span className="text-center ptb-5">Nema rezultata</span>
                    )}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-xl-3">
                <div className="title f-s-12 f-w-5 mb-5">
                  <label htmlFor="adresa">Adresa</label>
                </div>
                <div className="input">
                  <TextInput
                    placeholder=""
                    value={s.adresa}
                    onChange={this.handleInputChange}
                    name="adresa"
                    id="adresa"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-xl-3">
                <div className="title f-s-12 f-w-5 mb-5">
                  <label htmlFor="ziro_racun">
                    Žiro račun <span>*</span>
                  </label>
                </div>
                <div className="input">
                  <TextInput
                    placeholder=""
                    value={s.ziro_racun}
                    onChange={this.handleInputChange}
                    name="ziro_racun"
                    id="ziro_racun"
                    type={"formated"}
                    format="###-#############-##"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-sm-12 col-lg-6 col-xl-3">
                <div className="title f-s-12 f-w-5 mb-5">
                  <label htmlFor="web">Web</label>
                </div>
                <div className="input">
                  <TextInput
                    placeholder=""
                    value={s.web}
                    onChange={this.handleInputChange}
                    name="web"
                    id="web"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-xl-3">
                <div className="title f-s-12 f-w-5 mb-5">
                  <label htmlFor="kontakt_osoba">Kontakt osoba</label>
                </div>
                <div className="input">
                  <TextInput
                    placeholder=""
                    value={s.kontakt_osoba}
                    onChange={this.handleInputChange}
                    name="kontakt_osoba"
                    id="kontakt_osoba"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-xl-3">
                <div className="title f-s-12 f-w-5 mb-5">
                  <label htmlFor="email">Email</label>
                </div>
                <div className="input">
                  <TextInput
                    placeholder=""
                    value={s.email}
                    onChange={this.handleInputChange}
                    name="email"
                    id="email"
                  />
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 col-xl-3">
                <div className="title f-s-12 f-w-5 mb-5">
                  <label htmlFor="telefon">Telefon</label>
                </div>
                <div className="input">
                  <TextInput
                    placeholder=""
                    value={s.telefon}
                    onChange={this.handleInputChange}
                    name="telefon"
                    id="telefon"
                  />
                </div>
              </div>
            </div>
            <div className="row mt-30">
              <div className="col-sm-12">
                <TextArea
                  value={s.napomena}
                  onChange={this.handleInputChange}
                  name="napomena"
                  placeholder="Napomena"
                  height={150}
                />
              </div>
            </div>
            <div className="row mt-40">
              <div className="col-sm-2">
                <CustomButton
                  text="Reset"
                  onClick={this.resetForm}
                  type="reset"
                />
              </div>
              <div className="col-sm-3 pb-20">
                <CustomButton
                  text="Sačuvaj"
                  onClick={this.inserKlijent}
                  type="save"
                />
              </div>
            </div>
          </div>
          {this.state.modal ? this.renderModal() : ""}
        </form>
      </div>
    );
  }
}

export default UnosKupca;
