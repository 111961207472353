import React, { Component } from "react";
import { getLinijeBiznisa } from "../../api/getFunctions";
import axios from "axios";
import LinijaBiznisa from "./LinijaBiznisa/LinijaBiznisa";
import { postIzmjenaLinijeBiznisa } from "../../api/postFunctions";
import ModalSmall from "../modals/ModalSmall/ModalSmall";
interface ILinijaBiznisaState {
  linije: Array<{
    id: string;
    naziv: string;
    valuta: string;
  }>;
  modal: boolean;
  modalError: boolean;
  modalText:string
}
type LinijaBiznisaProps = {
  token: string;
};
class UnosLinijaBiznisa extends Component<LinijaBiznisaProps> {
  state: ILinijaBiznisaState = {
    linije: [],
    modal: false,
    modalError: false,
    modalText: ""
  };
  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    getLinijeBiznisa(this.axiosCancelSource, this.props.token).then(linije =>
      this.setState({ linije })
    );
  }

  getData = () => {
    getLinijeBiznisa(this.axiosCancelSource, this.props.token).then(linije =>
      this.setState({ linije })
    );
  };
  componentWillUnmount() {
    this.axiosCancelSource.cancel("Component unmounted.");
  }

  izmjeniLiniju = (id: string, naziv: string) => {
    if (naziv.trim() === "") {
      this.showModal(true);
      this.setState({
        modalText: "Polje naziv je obavezno"
      })
    } else if(naziv.trim().length > 100){
      this.showModal(true);
      this.setState({
        modalText: "Linija biznisa ne može imati više od 100 karaktera!"
      })
    } else {
      postIzmjenaLinijeBiznisa(
        { id, naziv },
        this.axiosCancelSource,
        this.props.token
      ).then(res => {
        if (res) {
          this.showModal();
          this.getData();
        }
      });
    }
  };

  showModal = (modalError?: boolean) => {
    this.setState({ modal: true, modalError });
  };

  removeModal = () => {
    this.setState({ modal: false });
  };

  renderModal = () => {
    if (!this.state.modalError) {
      return (
        <ModalSmall
          question={"Linija biznisa uspješno sačuvana."}
          buttons={["euro"]}
          options={["U redu"]}
          id={""}
          onClick={() => false}
          removeModal={this.removeModal}
          title="Linija biznisa sačuvana"
        />
      );
    } else {
      return (
        <ModalSmall
          question={this.state.modalText}
          buttons={["euro"]}
          options={["U redu"]}
          id={""}
          onClick={() => false}
          removeModal={this.removeModal}
          title="Greška prilikom unosa"
        />
      );
    }
  };

  render() {
    return (
      <div className="pb-20">
        <ul style={{ maxWidth: "450px" }}>
          {this.state.linije.map(e => {
            return (
              <li key={e.id} className="pb-20" style={{ listStyle: "none" }}>
                <LinijaBiznisa
                  id={e.id}
                  naziv={e.naziv}
                  onClick={this.izmjeniLiniju}
                />
              </li>
            );
          })}
          <LinijaBiznisa
            id={"0"}
            naziv={""}
            placeholder="Dodaj novu liniju biznisa"
            onClick={this.izmjeniLiniju}
          />
        </ul>
        {this.state.modal ? this.renderModal() : ""}
      </div>
    );
  }
}

export default UnosLinijaBiznisa;
