import React from "react";
import { renderIcon } from "../../../../components/Icons/Icons";

type TableHeadType = {
  imena: Array<string>;
  sortNames: Array<string>;
  sortBy: (
    e: React.MouseEvent<HTMLTableHeaderCellElement>,
    object: string,
    sortName: string
  ) => void;
  kupci?: boolean;
};

const TableHead = ({ imena, sortBy, sortNames, kupci }: TableHeadType) => {
  return (
    <thead>
      <tr>
        {imena.map((e, index) => {
          return (
            <th
              key={index}
              className="f-s-12 sort prl-10 p-r pb-10"
              onClick={(e: React.MouseEvent<HTMLTableHeaderCellElement>) =>
                sortBy(e, kupci ? "kupci" : "dobavljaci", sortNames[index])
              }
              style={{padding: "30px"}}
            >
              {e}
              <span className="sortDirection">
                {renderIcon("ArrowIcon", "")}
              </span>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default TableHead;
