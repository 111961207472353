 import {
  IzlazneFakturePretraga,
  IzlazneFakturePretragaLegend,
  IzlazneFakturePretragaNav
} from "./IzlazneFakture/IzlazneFakturePretraga";
import { IzlazneFaktureMjesecne } from "./IzlazneFakture/IzlazneFaktureMjesecne";
import {
  UlazneFakturePretraga,
  UlazneFakturePretragaLegend,
  UlazneFakturePretragaNav
} from "./UlazneFakture/UlazneFakturePretraga";
import { UlazneFaktureMjesecne } from "./UlazneFakture/UlazneFaktureMjesecne";
import { ProfakturePretraga } from "./ProfakturePretraga/ProfakturePretraga";
import { IIzlazneUlazneFakture } from "./TableTypes/FaktureTypes";
import { DobavljaciNav, Dobavljaci } from "./Dobavljaci/Dobavljaci";
import { Dokumentacija, DokumentacijaNav } from "./Dokumentacija/Dokumentacija";
import { IstekliUgovori } from "./Ugovori/IstekliUgovori";
import { UgovoriNav, SviUgovori } from "./Ugovori/SviUgovori";
import { UslugeNav, PretraziUsluge } from "./Usluge/PretraziUsluge";
import {
  PretragaUslugeNav,
  PretragaUsluge
} from "./PretragaUsluge/PretragaUsluge";
 import {AlarmiNav, PretraziAlarme} from "./Alarmi/PretraziAlarme";

export const IzlazneFaktureSettings: IIzlazneUlazneFakture = {
  title: "Izlazne Fakture",
  nav: IzlazneFakturePretragaNav,
  type: 0,
  legend: IzlazneFakturePretragaLegend,
  page: [IzlazneFakturePretraga, IzlazneFakturePretraga, IzlazneFaktureMjesecne]
};

export const ProfaktureSettings: IIzlazneUlazneFakture = {
  title: "Profakture",
  type: 1,
  nav: [
    { icon: "SearchIcon", title: "Pretraga profaktura" },
    { icon: "DocumentIcon", title: "Unesi profakturu" }
  ],
  legend: [
    {
      color: "",
      title: ""
    }
  ],
  page: [ProfakturePretraga, ProfakturePretraga]
};

export const UlazneFaktureSettings: IIzlazneUlazneFakture = {
  title: "Ulazne Fakture",
  type: 2,
  nav: UlazneFakturePretragaNav,
  legend: UlazneFakturePretragaLegend,
  page: [UlazneFakturePretraga, UlazneFakturePretraga, UlazneFaktureMjesecne]
};

export const DobavljaciSettings: IIzlazneUlazneFakture = {
  title: "Kupci/Dobavljači",
  type: 3,
  nav: DobavljaciNav,
  legend: [{ color: "", title: "" }],
  page: [Dobavljaci, Dobavljaci]
};

export const DokumentacijaSettings: IIzlazneUlazneFakture = {
  title: "Dokumentacija",
  type: 4,
  nav: DokumentacijaNav,
  legend: [{ color: "", title: "" }],
  page: [Dokumentacija, Dokumentacija]
};

export const UgovoriSettings: IIzlazneUlazneFakture = {
  title: "Ugovori",
  type: 5,
  nav: UgovoriNav,
  legend: [{ color: "", title: "" }],
  page: [IstekliUgovori, SviUgovori, IstekliUgovori]
};

export const UslugeSettings: IIzlazneUlazneFakture = {
  title: "Usluge",
  type: 6,
  nav: UslugeNav,
  legend: [{ color: "", title: "" }],
  page: [PretraziUsluge, PretraziUsluge]
};

export const PretragaUslugeSettings: IIzlazneUlazneFakture = {
  title: "Pretraga po uslugama",
  type: 7,
  nav: PretragaUslugeNav,
  legend: [{ color: "", title: "" }],
  page: [PretragaUsluge]
};

 export const AlarmiSettings: IIzlazneUlazneFakture = {
   title: "Alarmi",
   type: 8,
   nav: AlarmiNav,
   legend: [{ color: "", title: "" }],
   page: [PretraziAlarme]
 };
