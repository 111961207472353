import React, { forwardRef } from "react";
import { ICustomSelect } from "../../../interfaces/pageInterfaces";

const CustomSelect = forwardRef(
  (
    {
      name,
      title,
      options,
      open,
      reference,
      toggleDropdown,
      handleFilterClick,
      white
    }: ICustomSelect,
    ref: any
  ) => {
    if (ref.current) {
      const element = ref.current;
      element.style.height = open ? element.scrollHeight + "px" : "0";
    }
    return (
      <div className="customSelect p-r">
        <div className="top">
          <button
            className={`${open ? "active" : ""} ${
              white ? "white" : ""
            } f-s-12 text-color-white f-w-5`}
            onClick={e => toggleDropdown(e, reference)}
          >
            {title}
          </button>
        </div>
        <div className="bottom p-a" ref={ref}>
          <ul>
            {options
              .filter(e => e !== title)
              .map((e: string, index: number) => (
                <li
                  key={index}
                  className="d-flex align-items-center pl-10 f-s-12"
                  onClick={e => handleFilterClick(e, name)}
                >
                  {e}
                </li>
              ))}
          </ul>
        </div>
      </div>
    );
  }
);

export default CustomSelect;
