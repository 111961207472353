import React from "react";

type NavbarLogoType = {
  width?: string;
};

const NavbarLogo = ({ width }: NavbarLogoType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_2"
      viewBox="0 0 1248.6 207.9"
      width={width ? width : "130"}
    >
      <path fill="#ffffff" d="M237.3,2.4v202.6h-52.9V2.4H237.3z" />
      <path fill="#ABAFB3" d="M145.1,110.6v94.3H92.2v-94.3H145.1z" />
      <path fill="#6C737A" d="M52.9,162.2v42.8H0v-42.8H52.9z" />
      <path
        fill="#ffffff"
        d="M323.1,2.4l91.9,122V2.4h49.1v202.6h-46.2L326,83.6v121.4h-49.4V2.4H323.1z"
      />
      <path
        fill="#ffffff"
        d="M698.8,103.5c0,60.4-44.2,101.4-109.8,101.4h-85.3V2.4H591C655.1,2.4,698.8,43.4,698.8,103.5z M644.7,104.1 c0-35-22.8-58.7-54.9-58.7h-33.2v116.8h35.8C623.1,162.2,644.7,138.8,644.7,104.1z"
      />
      <path fill="#ffffff" d="M778,2.4v202.6h-52.9V2.4H778z" />
      <path
        fill="#ffffff"
        d="M951.4,110.6H997v70.6c-21.7,15-56.1,26-84.4,26c-61.8,0-108.4-44.5-108.4-103.5S851.9,0.9,916.1,0.9 c30.3,0,62.4,11.6,83.2,30.1l-30.1,37c-14.5-13.6-35-22.3-53.5-22.3c-32.7,0-57.2,25.1-57.2,58.1c0,33.2,24.9,58.4,57.8,58.4 c10.1,0,22.8-3.5,35-9.5V110.6z"
      />
      <path
        fill="#ffffff"
        d="M1177.8,94.2h9.8h41.6c-4.6-50-44.6-89.7-94.7-93.9v41.6v9.8C1156.4,55.3,1173.8,72.4,1177.8,94.2z"
      />
      <path
        fill="#ffffff"
        d="M1076.3,123.1c-2.3-6-3.6-12.5-3.6-19.2c0-27.5,21-50.1,47.8-52.7v-9.7V0c-55,2.7-98.8,48.2-98.8,103.9 c0,15.4,3.4,30,9.4,43.2l36.7-19.4L1076.3,123.1z"
      />
      <path
        fill="#ffffff"
        d="M1161.1,143.3c-9.4,8.4-21.8,13.6-35.4,13.6c-17.4,0-32.9-8.5-42.6-21.5l-8.6,4.6l-36.8,19.4 c18.4,29.2,50.9,48.5,88,48.5c28,0,53.5-11.1,72.2-29.1l-29.8-28.7L1161.1,143.3z"
      />
      <path
        fill="#ABAFB3"
        d="M1229.6,108.2h-41.4c-0.8,11.6-4.7,22.3-10.9,31.3l30,28.9l13.7,13.2c16.5-20.2,26.7-45.6,27.6-73.5H1229.6z"
      />
    </svg>
  );
};

export default NavbarLogo;
