import React, { Component } from "react";
import Home from "./Home/Home";
import { Route, BrowserRouter, Redirect } from "react-router-dom";
import Helmet from "react-helmet";
import Header from "../components/Header/Header";
import Navbar from "../components/Navbar/Navbar";
import PDV from "./PDV/PDV";
import TableDisplay from "./TableDisplay/TableDisplay";
import {
  UlazneFaktureSettings,
  ProfaktureSettings,
  IzlazneFaktureSettings,
  DobavljaciSettings,
  DokumentacijaSettings,
  UgovoriSettings,
  UslugeSettings,
  PretragaUslugeSettings,
  AlarmiSettings,
} from "../settings/TableDisplaySettings";
import Izvjestaji from "./Izvjestaji/Izvjestaji";
import Klijent from "./Klijent/Klijent";
import Targeti from "./Targeti/Targeti";
import KIF from "./KIF/KIF";
import Login from "./Login/Login";
import Podesavanja from "./Podesavanja/Podesavanja";

class App extends Component {
  state = {
    pageTitle: "",
    redirect: false,
    token: null,
    firma: null,
    logo: null,
    adresa: null,
    grad: null,
    telefon: null,
    pib: null,
    pdv: null,
    ziro_racun: null,
    email: null,
    websajt: null,
  };

  changePageTitle = (pageTitle: string) => {
    this.setState({ pageTitle });
  };

  redirect = () => {
    this.setState({ redirect: true });
    return <Redirect to="/knjige" />;
  };

  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.setState({
        token: localStorage.getItem("token"),
        firma: localStorage.getItem("firma"),
        logo: localStorage.getItem("logo"),
        adresa: localStorage.getItem("adresa"),
        grad: localStorage.getItem("grad"),
        telefon: localStorage.getItem("telefon"),
        pib: localStorage.getItem("pib"),
        pdv: localStorage.getItem("pdv"),
        ziro_racun: localStorage.getItem("ziro_racun"),
        email: localStorage.getItem("email"),
        websajt: localStorage.getItem("websajt"),
      });
    }
  }

  loginUser = (
    token: string,
    firma: string,
    logo: string,
    adresa: string,
    grad: string,
    telefon: string,
    pib: string,
    pdv: string,
    ziro_racun: string,
    email: string,
    websajt: string
  ) => {
    this.setState({
      token,
      firma,
      logo,
      adresa,
      grad,
      telefon,
      pib,
      pdv,
      ziro_racun,
      email,
      websajt,
    });
    localStorage.setItem("token", token);
    localStorage.setItem("firma", firma);
    localStorage.setItem("logo", logo);
    localStorage.setItem("adresa", adresa);
    localStorage.setItem("grad", grad);
    localStorage.setItem("telefon", telefon);
    localStorage.setItem("pib", pib);
    localStorage.setItem("pdv", pdv);
    localStorage.setItem("ziro_racun", ziro_racun);
    localStorage.setItem("email", email);
    localStorage.setItem("websajt", websajt);
  };

  render() {
    if (!this.state.token) {
      return (
        <Login
          changePageTitle={this.changePageTitle}
          loginUser={this.loginUser}
          api=""
        />
      );
    } else {
      return (
        <section id="App">
          <BrowserRouter>
            <Helmet>
              <title>{this.state.pageTitle} - Indigo</title>
            </Helmet>
            <Route
              path={`${process.env.PUBLIC_URL}/`}
              render={(props: any) => (
                <Navbar
                  {...props}
                  logo={this.state.logo}
                  firma={this.state.firma}
                />
              )}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/`}
              render={(props: any) => (
                <Header
                  {...props}
                  title={this.state.pageTitle}
                  api={this.state.token}
                />
              )}
            />
            <section className="mainContainer pt-20 prl-40" id="mainContainer">
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/`}
                render={(props: any) => (
                  <Home
                    {...props}
                    changePageTitle={this.changePageTitle}
                    api={this.state.token}
                  />
                )}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/fakture/izlazneFakture/:unos?`}
                render={(props: any) => (
                  <TableDisplay
                    {...props}
                    changePageTitle={this.changePageTitle}
                    api={this.state.token}
                    settings={IzlazneFaktureSettings}
                    firma={this.state.firma}
                    logo={this.state.logo}
                    adresa={this.state.adresa}
                    grad={this.state.grad}
                    telefon={this.state.telefon}
                    pib={this.state.pib}
                    pdv={this.state.pdv}
                    ziro_racun={this.state.ziro_racun}
                    email={this.state.email}
                    websajt={this.state.websajt}
                    type="izlazne"
                  />
                )}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/fakture/profakture`}
                render={(props: any) => (
                  <TableDisplay
                    {...props}
                    changePageTitle={this.changePageTitle}
                    api={this.state.token}
                    settings={ProfaktureSettings}
                    firma={this.state.firma}
                    logo={this.state.logo}
                    adresa={this.state.adresa}
                    grad={this.state.grad}
                    telefon={this.state.telefon}
                    pib={this.state.pib}
                    pdv={this.state.pdv}
                    ziro_racun={this.state.ziro_racun}
                    email={this.state.email}
                    websajt={this.state.websajt}
                  />
                )}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/fakture/ulazneFakture/:unos?`}
                render={(props: any) => (
                  <TableDisplay
                    {...props}
                    changePageTitle={this.changePageTitle}
                    api={this.state.token}
                    settings={UlazneFaktureSettings}
                    firma={this.state.firma}
                    logo={this.state.logo}
                    adresa={this.state.adresa}
                    grad={this.state.grad}
                    telefon={this.state.telefon}
                    pib={this.state.pib}
                    pdv={this.state.pdv}
                    ziro_racun={this.state.ziro_racun}
                    email={this.state.email}
                    websajt={this.state.websajt}
                  />
                )}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/kupci`}
                render={(props: any) => (
                  <TableDisplay
                    {...props}
                    changePageTitle={this.changePageTitle}
                    api={this.state.token}
                    settings={DobavljaciSettings}
                  />
                )}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/klijent/:name`}
                render={(props: any) => (
                  <Klijent
                    {...props}
                    changePageTitle={this.changePageTitle}
                    api={this.state.token}
                  />
                )}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/dokumentacija`}
                render={(props: any) => (
                  <TableDisplay
                    {...props}
                    changePageTitle={this.changePageTitle}
                    api={this.state.token}
                    settings={DokumentacijaSettings}
                  />
                )}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/ugovori/:unos?`}
                render={(props: any) => (
                  <TableDisplay
                    {...props}
                    changePageTitle={this.changePageTitle}
                    api={this.state.token}
                    settings={UgovoriSettings}
                  />
                )}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/alarmi`}
                render={(props: any) => (
                  <TableDisplay
                    {...props}
                    changePageTitle={this.changePageTitle}
                    api={this.state.token}
                    settings={AlarmiSettings}
                  />
                )}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/izvjestaji`}
                render={(props: any) => (
                  <Izvjestaji
                    {...props}
                    changePageTitle={this.changePageTitle}
                    api={this.state.token}
                  />
                )}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/porezi`}
                render={(props: any) => (
                  <PDV
                    {...props}
                    changePageTitle={this.changePageTitle}
                    api={this.state.token}
                  />
                )}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/targeti`}
                render={(props: any) => (
                  <Targeti
                    {...props}
                    changePageTitle={this.changePageTitle}
                    api={this.state.token}
                  />
                )}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/podesavanja`}
                render={(props: any) => (
                  <Podesavanja
                    {...props}
                    changePageTitle={this.changePageTitle}
                    api={this.state.token}
                  />
                )}
              />

              <Route
                exact
                path={`${process.env.PUBLIC_URL}/usluge`}
                render={(props: any) => (
                  <TableDisplay
                    {...props}
                    changePageTitle={this.changePageTitle}
                    api={this.state.token}
                    settings={UslugeSettings}
                  />
                )}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/pretragaPoUslugama`}
                render={(props: any) => (
                  <TableDisplay
                    {...props}
                    changePageTitle={this.changePageTitle}
                    api={this.state.token}
                    settings={PretragaUslugeSettings}
                    firma={this.state.firma}
                    logo={this.state.logo}
                    adresa={this.state.adresa}
                    grad={this.state.grad}
                    telefon={this.state.telefon}
                    pib={this.state.pib}
                    pdv={this.state.pdv}
                    ziro_racun={this.state.ziro_racun}
                    email={this.state.email}
                    websajt={this.state.websajt}
                  />
                )}
              />
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/knjige`}
                render={(props: any) => (
                  <KIF
                    {...props}
                    changePageTitle={this.changePageTitle}
                    api={this.state.token}
                  />
                )}
              />
            </section>
          </BrowserRouter>
        </section>
      );
    }
  }
}

export default App;