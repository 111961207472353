import React, { Component, ChangeEvent } from "react";
import Select from "react-dropdown-select";
import TextInput from "../Inputs/TextInput/TextInput";
import DateInput from "../Inputs/DateInput/DateInput";
import {
  formatDate,
  formatMoney,
  formatDate1,
} from "../../functions/formaters";
import {
  getDobavljaci,
  getUsluge,
  getKlijent,
  getUslugeFaktura, getBrojFakture,
} from "../../api/getFunctions";
import axios from "axios";
import Usluge from "./Usluge/Usluge";
import TextArea from "../Inputs/TextArea/TextArea";
import CustomButton from "../custom/CustomButton/CustomButton";
import { postInsertFaktura } from "../../api/postFunctions";
import ModalSmall from "../modals/ModalSmall/ModalSmall";
import { validateForm } from "./functions/validateForm";
import ModalErrors from "../modals/ModalErrors/ModalErrors";
import { BASE_URL } from "../../api/baseUrl";
import CustomCheckbox from "../custom/CustomCheckbox/CustomCheckbox";

export interface IUnosFaktura {
  brfakture: string;
  id: string;
  datum: Date | undefined;
  datumizdavanja: string;
  klijent: string;
  klijentName: string;
  grad: string;
  valuta: string;
  napomena: string;
  ui: string;
  pf: string;
  dokument: string;
  documentName: string;
  fileUpload: any;
  ugovor: string;
  odbija_se: boolean;
  kupci: Array<object>;
  dobavljaci: Array<object>;
  uslugeList: Array<{
    id: string;
    naziv: string;
    linija_biznisa: string;
    cijena: string;
    pdv: string;
    valuta: string;
    opis: string;
    opis_usluge: string;
  }>;
  errors: Array<string>;
  racuni: Array<{ key: string }>;
  selectedKupac: string;
  ziroRacun: string;
  cijenaUkupno: number;
  pdvUkupno: Array<any>;
  odabraneUsluge: Array<{
    id: string;
    naziv: string;
    linija_biznisa: string;
    cijena: string;
    pdv: string;
    valuta: string;
    opis: string;
    opis_usluge: string;
  }>;
  usluge: Array<{
    usluga: string;
    kolicina: string;
    kolicina1: string;
    neto: string;
    popust: string;
    popustPercent: string;
    pdv: string;
    valuta: string;
    naziv: string;
    id: string;
    opis: string;
    opis_usluge: string;
  }>;
  modal: boolean;
  type: string;
  isLoading: boolean;
}

interface IUnosFakturaProps {
  token: string;
  type?: number;
  edit?: boolean;
  data?: object;
  klijent?: string;
  copy?: boolean;
  finish?: () => void;
  pageType?: string;
}

type UslugeType = {
  usluga: string;
  kolicina: string;
  neto: string;
  popust: string;
};

type FilterKeys = keyof IUnosFaktura;
type UslugeKeys = keyof UslugeType;

class UnosFaktura extends Component<IUnosFakturaProps, IUnosFaktura> {
  state: IUnosFaktura = {
    errors: [],
    brfakture: "",
    id: "",
    datum: undefined,
    datumizdavanja: "",
    klijent: "",
    klijentName: "",
    grad: "1",
    valuta: "7",
    napomena: "",
    ui: "",
    pf: "",
    dokument: "",
    documentName: "",
    fileUpload: "",
    ugovor: "0",
    odbija_se: false,
    kupci: [],
    dobavljaci: [],
    uslugeList: [],
    pdvUkupno: [],
    cijenaUkupno: 0,
    modal: false,
    racuni: [{ key: "333" }, { key: "123" }, { key: "5123" }, { key: "1234" }],
    odabraneUsluge: [],
    selectedKupac: "",
    ziroRacun: "",
    usluge: [
      {
        usluga: "",
        kolicina: "1",
        kolicina1: "1",
        neto: "0",
        popust: "0",
        popustPercent: "0",
        pdv: "0",
        valuta: "0",
        naziv: "",
        id: "0",
        opis: "-",
        opis_usluge: "",
      },
    ],
    type: "",
    isLoading: false,
  };

  form: any = React.createRef();

  axiosCancelSource = axios.CancelToken.source();

  componentDidMount() {
    getDobavljaci(this.axiosCancelSource, this.props.token).then((res) => {
      this.setState({
        kupci: res.filter((e: any) => {
          if (this.props.type === 0) {
            return e.kupac === 1;
          } else if (this.props.type === 2) {
            return e.dobavljac === 1;
          } else {
            return true;
          }
        }),
      });
    });
    getUsluge(this.axiosCancelSource, this.props.token).then((res) => {
      const response = res.map((e: any) => {
        return {
          id: e.id,
          naziv: e.naziv,
          linija_biznisa: e.linija_biznisa,
          linija_biznisa_naziv: e.linija_biznisa_naziv,
          cijena: e.cijena,
          pdv: e.pdv,
          valuta: e.valuta,
          opis: e.opis === "" ? "-" : e.opis,
          opis_usluge: e.opis_usluge === "" ? "-" : e.opis_usluge,
        };
      });
      this.setState({ uslugeList: response });
    });

    console.log(!Boolean(this.props.data) && this.props.pageType === 'izlazne', this.props.pageType, !Boolean(this.props.data))
    if(!Boolean(this.props.data) && this.props.pageType === 'izlazne'){
      getBrojFakture(this.axiosCancelSource, this.props.token).then((res) => {
        this.setState({ brfakture: res.novi_broj });
      });
    }
    if (this.props.data) {
      this.setState((prevState: IUnosFaktura) => ({
        ...prevState,
        ...this.props.data,
      }));
    }
  }

  componentDidUpdate(prevProps: IUnosFakturaProps, prevState: IUnosFaktura) {
    if (
      this.state.klijent === undefined &&
      this.props.edit &&
      this.state.uslugeList.length > 0
    ) {
      const url = `${BASE_URL}/klijenti.php?id=${this.props.klijent}`;
      getKlijent(url, this.axiosCancelSource, this.props.token).then((res) => {

        this.handleSelectChange(res, "klijent");
      });
      getUslugeFaktura(
        this.state.id,
        this.axiosCancelSource,
        this.props.token
      ).then((res) => {
        const usluge = res.map((e: any, index: number) => {
          return this.state.uslugeList.find((el) => e.usluga === el.id);
        });
        const data = res.map((e: any, index: number) => {
          return {
            id: e.id,
            usluga: e.usluga,
            kolicina: e.kolicina,
            kolicina1: e.kolicina,
            neto: e.neto_cijena,
            popust: e.popust,
            opis_usluge: e.opis_usluge,
            opis: usluge[index].opis === "" ? "-" : usluge[index].opis,
            pdv: usluge[index].pdv,
            naziv: usluge[index].naziv,
            valuta: usluge[index].valuta,
          };
        });
        this.setState({ usluge: data });

        data.map((e: any, index: number) => {
          this.handleSelectChange(e, "usluge", index, true);
        });
      });
    }
    if (this.props.edit && prevState.usluge !== this.state.usluge) {
      this.renderSinglePDV();
      this.calculateDiscountPrice();
    }
  }

  addUsluga = (e?: any, edit?: boolean, data?: any) => {
    if(this.props.pageType === 'izlazne'){
      this.setState((prevState: IUnosFaktura) => ({
        usluge: [
          ...prevState.usluge,
          {
            usluga: "",
            kolicina: "1",
            kolicina1: "1",
            neto: "0",
            popust: "0",
            popustPercent: "0",
            pdv: "0",
            valuta: "0",
            naziv: "",
            id: "0",
            opis: "-",
            opis_usluge: "",
          },
        ],
      }));

    }else{
      this.setState((prevState: IUnosFaktura) => ({
        usluge: [
          ...prevState.usluge,
          {
            brfakture: "",
            usluga: "",
            kolicina: "1",
            kolicina1: "1",
            neto: "0",
            popust: "0",
            popustPercent: "0",
            pdv: "0",
            valuta: "0",
            naziv: "",
            id: "0",
            opis: "-",
            opis_usluge: "",
          },
        ],
      }));
    }
  };

  removeUsluga = (index: number) => {
    if (this.state.usluge.length > 1) {
      this.setState(
        (prevState: IUnosFaktura) => ({
          usluge: [...prevState.usluge.filter((e, i) => i !== index)],
        }),
        () => {
          this.renderSinglePDV();
          this.calculateDiscountPrice();
        }
      );
    }
  };

  componentWillUnmount() {
    this.axiosCancelSource.cancel("Component unmounted.");
  }

  showModal = () => {
    this.setState({ modal: true });
  };
  removeModal = () => {
    this.setState({ modal: false });
  };

  renderModal = () => {
    if (this.state.errors.length > 0) {
      return (
        <ModalErrors
          title="Greška prilikom unosa fakture"
          errors={this.state.errors}
          removeModal={this.removeModal}
          message="Greška prilikom unosa fakture"
        />
      );
    } else {
      if (this.props.edit && this.props.finish) {
        return (
          <ModalSmall
            question="Faktura uspješno sačuvana."
            buttons={["euro"]}
            options={["U redu"]}
            id={""}
            onClick={() => false}
            removeModal={this.props.finish}
            title="Faktura sačuvana"
          />
        );
      } else {
        return (
          <ModalSmall
            question="Faktura uspješno sačuvana."
            buttons={["euro"]}
            options={["U redu"]}
            id={""}
            onClick={() => false}
            removeModal={this.removeModal}
            title="Faktura sačuvana"
          />
        );
      }
    }
  };

  handleInputChange = (
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
    name: FilterKeys,
    subname?: any,
    index?: number
  ) => {
    e.persist();
    if (name !== "usluge") {
      if (name !== "valuta") {
        if (name === "napomena") {
          if (this.state.napomena.length <= 500) {
            this.setState((prevState) => ({
              ...prevState,
              [name]: e.target.value,
            }));
          } else {
            this.setState((prevState) => ({
              ...prevState,
              [name]: e.target.value.substr(0, 500),
              errors: ["Dužina napomene ne može biti veća od 500 karaktera"],
            }));

            this.showModal();
          }
        } else {
          this.setState((prevState) => ({
            ...prevState,
            [name]: e.target.value.trim(),
          }));
        }
      } else {
        this.setState((prevState) => ({
          ...prevState,
          [name]: Math.abs(parseInt(e.target.value)).toString(),
        }));
      }
    } else {
      if (subname && index !== undefined) {
        if (subname !== "popust" && subname !== "popustPercent") {
          if (subname === "opis_usluge") {
            this.setState(
              (prevState: IUnosFaktura) => ({
                ...prevState,
                usluge: [
                  ...prevState.usluge.slice(0, index),
                  {
                    ...prevState.usluge[index],
                    [subname]: e.target.value,
                  },
                  ...prevState.usluge.slice(index + 1),
                ],
              }),
              () => {
                this.renderSinglePDV();
                this.calculateDiscountPrice();
              }
            );
          } else {
            this.setState(
              (prevState: IUnosFaktura) => ({
                ...prevState,
                usluge: [
                  ...prevState.usluge.slice(0, index),
                  {
                    ...prevState.usluge[index],
                    [subname]: Math.abs(parseFloat(e.target.value)).toString(),
                  },
                  ...prevState.usluge.slice(index + 1),
                ],
              }),
              () => {
                this.renderSinglePDV();
                this.calculateDiscountPrice();
              }
            );
          }
        } else {
          if (subname === "popust") {
            const popustPercent = (
              (parseFloat(e.target.value) /
                (parseFloat(this.state.usluge[index].kolicina) *
                  parseFloat(this.state.usluge[index].neto))) *
              100
            ).toString();
            this.setState(
              (prevState: IUnosFaktura) => ({
                ...prevState,
                usluge: [
                  ...prevState.usluge.slice(0, index),
                  {
                    ...prevState.usluge[index],
                    popust: Math.abs(parseFloat(e.target.value)).toString(),
                    popustPercent,
                  },
                  ...prevState.usluge.slice(index + 1),
                ],
              }),
              () => {
                this.renderSinglePDV();
                this.calculateDiscountPrice();
              }
            );
          } else {
            const popust = (
              (parseFloat(this.state.usluge[index].kolicina) *
                parseFloat(this.state.usluge[index].neto) *
                parseFloat(e.target.value)) /
              100
            ).toString();
            this.setState(
              (prevState: IUnosFaktura) => ({
                ...prevState,
                usluge: [
                  ...prevState.usluge.slice(0, index),
                  {
                    ...prevState.usluge[index],
                    popustPercent: Math.abs(
                      parseFloat(e.target.value)
                    ).toString(),
                    popust,
                  },
                  ...prevState.usluge.slice(index + 1),
                ],
              }),
              () => {
                this.renderSinglePDV();
                this.calculateDiscountPrice();
              }
            );
          }
        }
      }
    }
  };

  handleDateChange = (datum: Date | undefined, name: string) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        [name]: datum,
      }),
      () => {
        const datumizdavanja: any = formatDate(this.state.datum);
        this.setState({ datumizdavanja });
      }
    );
  };

  handleSelectChange = (
    val: any,
    name: FilterKeys,
    index?: number,
    edit?: boolean
  ) => {
    if (name === "klijent" && val.length > 0) {
      this.setState({
        klijent: val[0].id,
        grad: val[0].grad_id,
        ziroRacun: val[0].ziro_racun,
        klijentName: val[0].naziv_firme,
      });
    } else if (name === "klijent" && val.length === 0) {
      this.setState({
        klijent: "",
        grad: "1",
        ziroRacun: "",
        klijentName: "",
      });
    }
    if (name === "usluge" && val.length > 0 && index !== undefined) {
      const editedUsluge = Object.assign({}, this.state.usluge);
      editedUsluge[index].neto = val[0].cijena;
      editedUsluge[index].pdv = val[0].pdv;
      editedUsluge[index].naziv = val[0].naziv;
      editedUsluge[index].opis = val[0].opis;
      editedUsluge[index].valuta = val[0].valuta;
      if (edit) {
        editedUsluge[index].id = val[0].id;
        editedUsluge[index].usluga = val[0].usluga;
      } else {
        editedUsluge[index].usluga = val[0].id;
      }
      const usluge = Object.keys(editedUsluge).map((e: any) => {
        return editedUsluge[e];
      });
      this.setState(
        (prevState: IUnosFaktura) => ({
          ...prevState,
          usluge,
        }),
        () => {
          this.renderSinglePDV();
          this.calculateDiscountPrice();
        }
      );
    } else if (name === "usluge" && val.length === 0 && index !== undefined) {
      const editedUsluge = Object.assign({}, this.state.usluge);
      editedUsluge[index].neto = "";
      editedUsluge[index].pdv = "";
      editedUsluge[index].usluga = "";
      editedUsluge[index].naziv = "";
      if (edit) {
        editedUsluge[index].id = "";
        editedUsluge[index].usluga = "";
      } else {
        editedUsluge[index].usluga = "";
      }
      const usluge = Object.keys(editedUsluge).map((e: any) => {
        return editedUsluge[e];
      });
      this.setState(
        (prevState: IUnosFaktura) => ({
          ...prevState,
          usluge,
        }),
        () => {
          this.renderSinglePDV();
          this.calculateDiscountPrice();
        }
      );
    }
  };

  renderUsluge = () => {
    const { usluge, uslugeList } = this.state;
    return usluge.map((e: any, index: number) => {
      return (
        <React.Fragment key={index}>
          <Usluge
              pageType={this.props.pageType}
            uslugeList={uslugeList}
            isEdit={Boolean(this.props.data)}
            handleSelectChange={this.handleSelectChange}
            kolicina={e.kolicina}
            maxKolicina={e.kolicina1}
            handleInputChange={this.handleInputChange}
            neto={e.neto === 0 ? "0" : e.neto}
            popust={e.popust === 0 ? "0" : e.popust}
            opis_usluge={e.opis_usluge}
            popustPercent={
              e.popust === 0
                ? "0"
                : (
                    (parseFloat(e.popust) /
                      (parseFloat(e.kolicina) * parseFloat(e.neto))) *
                    100
                  )
                    .toString()
                    .slice(0, 5)
            }
            pdv={e.pdv === 0 ? "0" : e.pdv}
            // valuta={e.valuta}
            index={index}
            addUsluga={this.addUsluga}
            usluge={usluge}
            removeUsluga={this.removeUsluga}
            nazivUsluge={e.naziv}
          />
        </React.Fragment>
      );
    });
  };

  calculateDiscountPrice = (clear?: boolean) => {
    const amount = this.state.usluge.map((e) => {
      if (e.popust !== "" && e.neto !== "") {
        return (
          parseFloat(e.neto) * parseFloat(e.kolicina) - parseFloat(e.popust)
        );
      } else if (e.neto !== "") {
        return parseFloat(e.neto) * parseFloat(e.kolicina);
      }
    });
    const cijenaUkupno = amount
      .map((e) => (!e ? 0 : e))
      .reduce((a: number, b: number) => a + b);
    if (clear) {
      this.setState({ cijenaUkupno: 0 });
    }

    this.setState({ cijenaUkupno });
  };

  renderSinglePDV = (clear?: boolean) => {
    const PDVs = this.state.usluge.map((e) => {
      return parseFloat(e.pdv);
    });

    const uniqPDVs = [...new Set(PDVs)];
    const calculatedPDVS = uniqPDVs.map((e: any) => {
      return this.state.usluge.map((el) => {
        const popust = el.popust !== "" ? parseFloat(el.popust) : 0;
        if (e === parseInt(el.pdv)) {
          return (
            ((parseFloat(el.neto) * parseFloat(el.kolicina) - popust) *
              parseFloat(el.pdv)) /
            100
          );
        }
      });
    });

    const extractPDVValues = calculatedPDVS.map((e) => {
      const reducer = e.filter((el) => el);
      if (reducer.length > 0) {
        return reducer.reduce((a: number | undefined, b: number | undefined) =>
          a && b ? a + b : undefined
        );
      }
    });

    const pdvUkupno = extractPDVValues.map((e, index) => {
      if (e) {
        return {
          pdv: uniqPDVs[index],
          value: e,
        };
      }
    });
    let updateState = true;
    pdvUkupno.map((e) =>
      e !== undefined ? (updateState = true) : (updateState = false)
    );

    if (clear) {
      this.setState({ pdvUkupno: [] });
    }

    this.setState({ pdvUkupno: pdvUkupno.filter((e) => e) });
  };

  resetForm = () => {
    this.setState({
      datum: undefined,
      datumizdavanja: "",
      klijent: "",
      klijentName: "",
      grad: "1",
      valuta: "7",
      napomena: "",
      ui: "",
      pf: "",
      ziroRacun: "",
      ugovor: "0",
      cijenaUkupno: 0,
      pdvUkupno: [],
      usluge: [
        {
          usluga: "",
          kolicina: "1",
          kolicina1: "1",
          neto: "0",
          popust: "0",
          popustPercent: "0",
          pdv: "0",
          valuta: "0",
          naziv: "",
          id: "0",
          opis: "-",
          opis_usluge: "",
        },
      ],
    });
    if (this.props.edit) {
      this.setState((prevState: IUnosFaktura) => ({
        ...prevState,
        ...this.props.data,
      }));
    }
  };

  postData = (dokumentPath: any) => {
    const {
      brfakture,
      klijent,
      grad,
      napomena,
      usluge,
      ugovor,
      odbija_se,
      valuta,
      id,
    } = this.state;

    const datumizdavanja = this.state.datumizdavanja;

    const { type } = this.props;

    let data;
    let stringUsluge;
    let errors;
    if (!this.props.edit || this.props.copy) {
      const clearUsluge = usluge.map((e) => {
        return {
          usluga: e.usluga,
          kolicina: e.kolicina,
          neto: e.neto,
          popust: e.popust !== "" ? e.popust : 0,
          opis_usluge: e.opis_usluge,
        };
      });

      stringUsluge = JSON.stringify(clearUsluge);
      data = {
        brfakture,
        datumizdavanja,
        klijent,
        grad,
        napomena,
        valuta,
        odbija_se: odbija_se ? 1 : 0,
        ui: type === 0 ? 1 : type === 2 ? 2 : 1,
        pf: type === 1 ? "1" : 0,
        ugovor,
        dokument: dokumentPath,
      };

      errors = validateForm(this.state, "unos");
    } else {
      const clearUsluge = usluge.map((e) => {
        if (this.props.edit) {
          return {
            id: e.id,
            usluga: e.usluga,
            kolicina: e.kolicina,
            neto: e.neto,
            popust: e.popust !== "" ? e.popust : 0,
            opis_usluge: e.opis_usluge,
          };
        } else {
          return {
            usluga: e.usluga,
            kolicina: e.kolicina,
            neto: e.neto,
            popust: e.popust !== "" ? e.popust : 0,
            opis_usluge: e.opis_usluge,
          };
        }
      });
      stringUsluge = JSON.stringify(clearUsluge);

      data = {
        id,
        brfakture,
        datumizdavanja,
        klijent,
        grad,
        valuta,
        napomena,
        odbija_se: odbija_se ? 1 : 0,
        ui: type === 0 ? 1 : type === 2 ? 2 : 1,
        pf: type === 1 ? "1" : 0,
        ugovor,
        dokument: dokumentPath,
      };
      errors = validateForm(this.state, "unos");
    }

    if (errors.length === 0) {
      postInsertFaktura(
        data,
        this.axiosCancelSource,
        this.props.token,
        "",
        stringUsluge
      ).then((res) => {
        if (res.id) {
          this.setState(
            {
              errors: [],
            },
            this.showModal
          );
        } else {
          console.log("Something went wrong");
        }
      });
    } else {
      this.setState({ errors }, () => {
        this.showModal();
      });
    }
    this.setState({ isLoading: false });
  };

  insertFaktura = () => {
    this.setState({ isLoading: true });
    if (this.state.fileUpload !== "") {
      for (const file of this.state.fileUpload) {
        const newFormDataAdd = new FormData();
        newFormDataAdd.append("file", file);
        if (file.name.length !== 0) {
          axios({
            method: "POST",
            url: `${BASE_URL}/ajax/fileUpload.php?api=${this.props.token}`,
            data: newFormDataAdd,
          }).then((r) => {
            this.postData(r.data.file_path);
          });
        }
      }
    } else {
      this.postData(this.state.dokument);
    }
  };

  render() {
    const v = this.state;
    return (
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="unosFakturaContainer">
          <div className="row">
            <div className="col-sm-12 col-lg-6 col-xl-2">
              <div className="title f-s-12 f-w-5 mb-5">
                <label htmlFor="brfakture">
                  Broj fakture <span>*</span>
                </label>
              </div>
              <div className="input">
                <TextInput
                  readOnly={Boolean(this.props.data)}
                  placeholder=""
                  value={v.brfakture}
                  onChange={this.handleInputChange}
                  name="brfakture"
                  id="brfakture"
                />
              </div>
            </div>
            <div className="col-sm-12 col-lg-6 col-xl-2">
              <div className="title f-s-12 f-w-5 mb-5">
                <span>
                  Datum izdavanja <span>*</span>{" "}
                </span>
              </div>
              <div className="input">
                <DateInput
                  date={v.datum}
                  name="datum"
                  onChange={this.handleDateChange}
                />
              </div>
            </div>

            {this.state.ugovor === "0" ? (
              <>
                <div className="col-sm-12 col-lg-6 col-xl-3">
                  <div className="title f-s-12 f-w-5 mb-5">
                    <span>
                      {this.props.type !== 2 ? "Kupac" : "Dobavljač"}{" "}
                      <span>*</span>
                    </span>
                  </div>
                  <div className="input p-r">
                    <label htmlFor="" className="react-select-label f-s-12">
                      {v.klijentName}
                    </label>
                    <Select
                      values={[parseInt(this.state.klijent)]}
                      options={v.kupci}
                      labelField="naziv_firme"
                      valueField="id"
                      placeholder=""
                      clearable={true}
                      dropdownGap={0}
                      clearOnSelect={true}
                      onChange={(val: any) =>
                        this.handleSelectChange(val, "klijent", 0)
                      }
                      onClearAll={() =>
                        this.handleSelectChange([], "klijent", 0)
                      }
                      noDataRenderer={() => (
                        <span className="text-center ptb-5">
                          Nema rezultata
                        </span>
                      )}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6 col-xl-2">
                  <div className="title f-s-12 f-w-5 mb-5">
                    <label htmlFor="ziroRacun">Žiro račun</label>
                  </div>
                  <div className="input">
                    <TextInput
                      placeholder=""
                      value={v.ziroRacun}
                      onChange={() => false}
                      name="ziroRacun"
                      id="ziroRacun"
                      readOnly={true}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6 col-xl-1">
                  <div className="title f-s-12 f-w-5 mb-5">
                    <label htmlFor="valuta">Valuta</label>
                  </div>
                  <div className="input">
                    <TextInput
                      placeholder=""
                      value={v.valuta}
                      onChange={this.handleInputChange}
                      name="valuta"
                      id="valuta"
                      number={true}
                      min={1}
                    />
                  </div>
                </div>
                {this.props.type === 2 ? (
                  <div className="col-sm-12 col-lg-6 col-xl-2">
                    <div className="checkbox d-flex align-items-center height-100">
                      <CustomCheckbox
                        className=""
                        text="Odbija se"
                        checked={this.state.odbija_se}
                        onClick={() => {
                          this.setState({ odbija_se: !this.state.odbija_se });
                        }}
                        name="odbija_se"
                      />
                    </div>
                  </div>
                ) : null}
              </>
            ) : (
              ""
            )}
          </div>
          {this.renderUsluge()}
          <div className="row mt-40">
            <div className="col-sm-12 col-lg-6 col-xl-4 d-flex  flex-column justify-content-center">
              <div className="topSummary pb-10">
                <div className="desc d-flex align-items-center justify-content-between mb-10">
                  <div className="">
                    <span className="f-s-14">Cijena sa popustom:</span>
                  </div>
                  <div className="">
                    <span
                      className={`f-s-14 ${
                        v.cijenaUkupno !== 0 ? "text-color-secondary f-w-5" : ""
                      }`}
                    >
                      {formatMoney(v.cijenaUkupno)}€
                    </span>
                  </div>
                </div>
                {this.state.pdvUkupno.map((e, index) => {
                  return (
                    <div
                      className="desc d-flex align-items-center justify-content-between"
                      key={index}
                    >
                      <div className="">
                        <span className="f-s-12">PDV {e.pdv}%</span>
                      </div>
                      <div className="">
                        <span className="f-s-12 text-color-font-l">
                          {formatMoney(e.value)}€
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
              {v.pdvUkupno.length > 0 ? (
                <div className="desc d-flex align-items-center justify-content-between mt-20">
                  <div className="">
                    <span className="f-s-12">PDV Ukupno:</span>
                  </div>
                  <div className="">
                    <span className="f-s-12 text-color-font-l ">
                      {formatMoney(
                        v.pdvUkupno
                          .map((e): number => e.value)
                          .reduce((a: any, b: any) => (a && b ? a + b : 0))
                      )}
                      €
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="desc d-flex align-items-center justify-content-between mt-20">
                <div className="">
                  <span className="f-s-14">Ukupna cijena sa PDV:</span>
                </div>
                <div className="">
                  <span
                    className={`f-s-14 ${
                      v.cijenaUkupno !== 0 ? "text-color-secondary f-w-5" : ""
                    }`}
                  >
                    {formatMoney(
                      v.cijenaUkupno +
                        (v.pdvUkupno.length > 0
                          ? v.pdvUkupno
                              .map((e): number => e.value)
                              .reduce((a: any, b: any) => (a && b ? a + b : 0))
                          : 0)
                    )}
                    €
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm-5 mb-20">
              <TextArea
                value={v.napomena}
                onChange={this.handleInputChange}
                name="napomena"
                placeholder="Napomena"
                height={150}
              />
            </div>
            {(this.props.type === 2 || v.type === "Ulazne fakture") && (
              <div className="col-sm-12 col-lg-3">
                <label
                  className="custom-file-upload"
                  style={{ marginTop: "0" }}
                >
                  <input
                    type="file"
                    name="fileDokument"
                    id="fileDokument"
                    onChange={(e) =>
                      this.setState({
                        documentName: e.target.value.replace(
                          "C:\\fakepath\\",
                          ""
                        ),
                        fileUpload: e.target.files,
                      })
                    }
                  />
                  <img
                    src="../images/file-upload-solid.svg"
                    height="20px"
                    alt="upload"
                  />{" "}
                  <div>Dodaj dokument</div>
                </label>
                <div>
                  {v.documentName.length !== 0
                    ? v.documentName
                    : v.dokument !== undefined &&
                      v.dokument.replace("uploads/", "")}
                </div>
              </div>
            )}
          </div>
          <div className="row mt-40">
            <div className="col-sm-2">
              <CustomButton
                text="Reset"
                onClick={this.resetForm}
                type="reset"
              />
            </div>
            <div className="col-sm-3">
              <CustomButton
                text="Sačuvaj"
                onClick={this.insertFaktura}
                type="save"
                loading={this.state.isLoading}
                disabled={this.state.isLoading}
              />
            </div>
          </div>
          {this.state.modal ? this.renderModal() : ""}
        </div>
      </form>
    );
  }
}

export default UnosFaktura;