import React, { Component } from "react";
import { IzlazneFaktureResponse } from "../../settings/TableTypes/FaktureTypes";
import {
  getFakture,
  getKlijent,
  getUsluge,
  getUslugeFaktura
} from "../../api/getFunctions";
import axios from "axios";
import { formatMoney } from "../../functions/formaters";
import { renderIcon } from "../Icons/Icons";
import { saveTable } from "../../functions/fileGenerators";
import Loading from "../Loading/Loading";
import { noScrollBody } from "../../functions/functions";
import { IUnosUsluge } from "../UnosUsluge/UnosUsluge";
import { BASE_URL } from "../../api/baseUrl";

interface IGeneratedKIFState {
  numberOfColumns: number;
  fakture: Array<IzlazneFaktureResponse>;
  klijenti: Array<{
    naziv_firme: string;
    pdv_broj: string;
  }>;
  uslugeFakture: Array<{
    id: string;
    faktura: string;
    usluga: string;
    kolicina: string;
    neto_cijena: string;
    popust: string;
  }>;
  loading: boolean;
  usluge: Array<IUnosUsluge>;
}

type GeneratedKIFProps = {
  token: string;
  removeModal: () => void;
  startDate: string;
  endDate: string;
  type: number;
};

class GeneratedKIF extends Component<GeneratedKIFProps> {
  state: IGeneratedKIFState = {
    numberOfColumns: this.props.type === 0 ? 18 : 19,
    fakture: [],
    klijenti: [],
    loading: false,
    usluge: [],
    uslugeFakture: []
  };

  axiosCancelSource = axios.CancelToken.source();

  renderColumnNumber = () => {
    return Array(this.state.numberOfColumns)
      .fill("1")
      .map((e, index) => {
        return (
          <th
            key={index}
            className="f-w-4 f-s-14"
            style={{
              border: "0.5px solid black"
            }}
          >
            {index + 1}
          </th>
        );
      });
  };

  returnKlijentPDVBroj = (name: string) => {
    const klijent = this.state.klijenti.find(e => e.naziv_firme === name);
    if (klijent) {
      return klijent.pdv_broj;
    } else {
      return "";
    }
  };

  reduceAmount = (
    faktura: IzlazneFaktureResponse,
    pdvValue: number,
    type: number
  ) => {
    const uslugeFakt = this.state.uslugeFakture.filter(
      el => el.faktura === faktura.id
    );
    const PDV = uslugeFakt.map(e => {
      const pdv: any = this.state.usluge.find(el => el.id === e.usluga);
      if (pdv) {
        return {
          faktura: e.faktura,
          vrijednostPDV:
            (parseFloat(e.kolicina) * parseFloat(e.neto_cijena) * pdv.pdv) /
            100,
          pdv: pdv.pdv,
          osnova: parseInt(e.kolicina) * parseFloat(e.neto_cijena)
        };
      }
    });
    if (parseInt(faktura.drzava_id) === 1) {
      if (type === 0) {
        const ukupnoPDV = PDV.filter(pdv => pdv && pdv.pdv === pdvValue).map(
          e => e && e.osnova
        );
        if (ukupnoPDV.length > 0) {
          return ukupnoPDV.reduce((a, b) =>
            a !== undefined && b !== undefined ? a + b : 0
          );
        } else {
          return 0;
        }
      } else {
        const ukupnoPDV = PDV.filter(pdv => pdv && pdv.pdv === pdvValue).map(
          e => e && e.vrijednostPDV
        );
        if (ukupnoPDV.length > 0) {
          return ukupnoPDV.reduce((a, b) =>
            a !== undefined && b !== undefined ? a + b : 0
          );
        } else {
          return 0;
        }
      }
    } else {
      return 0;
    }
  };
  renderBody = (page: number, total: number) => {
    let ukupnoSaPDV: number = 0;
    let ukupnoOsnova: number = 0;
    let ukupnoOsnova7: number = 0;
    let ukupnoPDV: number = 0;
    let ukupnoPDV7: number = 0;
    let smijeSeOdbiti: number = 0;
    let neSmijeSeOdbiti: number = 0;
    let ukupnoInostranstvo: number = 0;
    if (this.props.type === 0) {
      let start: number = 0;
      if (page === 1) {
        start += 35;
      } else if (page === 2) {
        start += 76;
      } else if (page > 2) {
        start += page * 41 - 6;
      }
      this.state.fakture.map(e => {
        ukupnoOsnova += this.reduceAmount(e, 21, 0) || 0;
        ukupnoOsnova7 += this.reduceAmount(e, 7, 0) || 0;
        ukupnoPDV += this.reduceAmount(e, 21, 1) || 0;
        ukupnoPDV7 += this.reduceAmount(e, 7, 1) || 0;
        if (parseInt(e.drzava_id) === 1) {
          ukupnoSaPDV += parseFloat(e.iznos);
        } else {
          ukupnoInostranstvo += parseFloat(e.iznos) - parseFloat(e.pdv);
        }
      });
      return (
        <>
          {this.state.fakture
            .slice(start, page === 0 ? 36 : start + 41)
            .map((e, index) => {
              if (index >= page) {
                return (
                  <tr key={index}>
                    <td
                      className="f-s-14 f-w-4 pb-5"
                      style={{ textAlign: "center" }}
                    >
                      {start + index + 1}
                    </td>
                    <td
                      className="f-s-14 f-w-4 pb-5"
                      style={{ textAlign: "center" }}
                    >
                      {this.formatDate(e.datum)}
                    </td>
                    <td
                      className="f-s-14 f-w-4 pb-5"
                      style={{ textAlign: "center" }}
                    >
                      {e.br_fakture}
                    </td>
                    <td
                      className="f-s-14 f-w-4 pb-5"
                      style={{ textAlign: "center" }}
                    >
                      {this.formatDate(e.datum)}
                    </td>
                    <td className="f-s-14 f-w-4 pb-5">{e.kupac}</td>
                    <td className="f-s-14 f-w-4 pb-5">
                      {this.returnKlijentPDVBroj(e.kupac)}
                    </td>
                    <td
                      className="f-s-14 f-w-4 pb-5"
                      style={{ textAlign: "right" }}
                    >
                      {parseInt(e.drzava_id) === 1
                        ? formatMoney(e.iznos)
                        : "0,00"}
                    </td>
                    <td
                      className="f-s-14 f-w-4 pb-5"
                      style={{ textAlign: "right" }}
                    >
                      0,00
                    </td>
                    <td
                      className="f-s-14 f-w-4 pb-5"
                      style={{ textAlign: "right" }}
                    >
                      0,00
                    </td>
                    <td
                      className="f-s-14 f-w-4 pb-5"
                      style={{ textAlign: "right" }}
                    >
                      {parseInt(e.drzava_id) !== 1
                        ? formatMoney(parseInt(e.iznos) - parseInt(e.pdv))
                        : "0,00"}
                    </td>
                    <td
                      className="f-s-14 f-w-4 pb-5"
                      style={{ textAlign: "right" }}
                    >
                      {formatMoney(this.reduceAmount(e, 21, 0))}
                    </td>
                    <td
                      className="f-s-14 f-w-4 pb-5"
                      style={{ textAlign: "right" }}
                    >
                      {formatMoney(this.reduceAmount(e, 21, 1))}
                    </td>
                    <td
                      className="f-s-14 f-w-4 pb-5"
                      style={{ textAlign: "right" }}
                    >
                      {formatMoney(this.reduceAmount(e, 7, 0))}
                    </td>
                    <td
                      className="f-s-14 f-w-4 pb-5"
                      style={{ textAlign: "right" }}
                    >
                      {formatMoney(this.reduceAmount(e, 7, 1))}
                    </td>
                    <td
                      className="f-s-14 f-w-4 pb-5"
                      style={{ textAlign: "right" }}
                    >
                      0,00
                    </td>
                    <td
                      className="f-s-14 f-w-4 pb-5"
                      style={{ textAlign: "right" }}
                    >
                      0,00
                    </td>
                    <td
                      className="f-s-14 f-w-4 pb-5"
                      style={{ textAlign: "right" }}
                    >
                      0,00
                    </td>
                    <td
                      className="f-s-14 f-w-4 pr-10 pb-5"
                      style={{ textAlign: "right" }}
                    >
                      0,00
                    </td>
                  </tr>
                );
              }
            })}
          {total - start < 36 ? (
            <tr style={{ borderTop: "0.5px solid black" }}>
              <td className="f-s-14 f-w-4 pb-5 pl-10 f-w-6" colSpan={6}>
                UKUPNO
              </td>
              <td className="f-s-14 f-w-4 pb-5 pl-10 f-w-6 text-right">
                {formatMoney(ukupnoSaPDV)}
              </td>
              <td
                className="f-s-14 f-w-4 pb-5 pl-10 f-w-6"
                style={{ textAlign: "right" }}
              >
                0,00
              </td>
              <td
                className="f-s-14 f-w-4 pb-5 pl-10 f-w-6"
                style={{ textAlign: "right" }}
              >
                0,00
              </td>
              <td
                className="f-s-14 f-w-4 pb-5 pl-10 f-w-6"
                style={{ textAlign: "right" }}
              >
                {formatMoney(ukupnoInostranstvo)}
              </td>
              <td
                className="f-s-14 f-w-4 pb-5 pl-10 f-w-6"
                style={{ textAlign: "right" }}
              >
                {formatMoney(ukupnoOsnova)}
              </td>
              <td
                className="f-s-14 f-w-4 pb-5 pl-10 f-w-6"
                style={{ textAlign: "right" }}
              >
                {formatMoney(ukupnoPDV)}
              </td>
              <td
                className="f-s-14 f-w-4 pb-5 pl-10 f-w-6"
                style={{ textAlign: "right" }}
              >
                {formatMoney(ukupnoOsnova7)}
              </td>
              <td
                className="f-s-14 f-w-4 pb-5 pl-10 f-w-6"
                style={{ textAlign: "right" }}
              >
                {formatMoney(ukupnoPDV7)}
              </td>
              <td
                className="f-s-14 f-w-4 pb-5 pl-10 f-w-6"
                style={{ textAlign: "right" }}
              >
                0,00
              </td>
              <td
                className="f-s-14 f-w-4 pb-5 pl-10 f-w-6"
                style={{ textAlign: "right" }}
              >
                0,00
              </td>
              <td
                className="f-s-14 f-w-4 pb-5 pl-10 f-w-6"
                style={{ textAlign: "right" }}
              >
                0,00
              </td>
              <td
                className="f-s-14 f-w-4 pb-5 pl-10 f-w-6 pr-10"
                style={{ textAlign: "right" }}
              >
                0,00
              </td>
            </tr>
          ) : (
            ""
          )}
        </>
      );
    } else {
      let start: number = 0;
      if (page === 1) {
        start += 35;
      } else if (page === 2) {
        start += 76;
      } else if (page > 2) {
        start += page * 41 - 6;
      }
      this.state.fakture.map(e => {
        ukupnoSaPDV += parseFloat(e.iznos);
        ukupnoOsnova += parseFloat(e.iznos) - parseFloat(e.pdv);
        ukupnoPDV += this.reduceAmount(e, 21, 1) || 0;
        ukupnoPDV7 += this.reduceAmount(e, 7, 1) || 0;
        smijeSeOdbiti += e.odbija_se === 1 ? parseFloat(e.pdv) : 0;
        neSmijeSeOdbiti += e.odbija_se === 0 ? parseFloat(e.pdv) : 0;
      });
      return (
        <>
          {this.state.fakture
            .slice(start, page === 0 ? 35 : start + 41)
            .map((e, index) => {
              return (
                <tr key={index}>
                  <td
                    className="f-s-14 f-w-4 pb-5"
                    style={{ textAlign: "center" }}
                  >
                    {start + index + 1}
                  </td>
                  <td
                    className="f-s-14 f-w-4 pb-5"
                    style={{ textAlign: "center", minWidth: "90px" }}
                  >
                    {this.formatDate(e.datum)}
                  </td>
                  <td
                    className="f-s-14 f-w-4 pb-5"
                    style={{ textAlign: "center", minWidth: "90px" }}
                  >
                    {this.formatDate(e.datum)}
                  </td>
                  <td
                    className="f-s-14 f-w-4 pb-5"
                    style={{ textAlign: "center", minWidth: "100px" }}
                  >
                    {e.br_fakture}
                  </td>
                  <td
                    className="f-s-14 f-w-4 pb-5"
                    style={{ textAlign: "center", minWidth: "100px" }}
                  >
                    {this.formatDate(e.datum)}
                  </td>
                  <td
                    className="f-s-14 f-w-4 pb-5"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {e.kupac}
                  </td>
                  <td
                    className="f-s-14 f-w-4 pb-5"
                    style={{ maxWidth: "80px" }}
                  >
                    {this.returnKlijentPDVBroj(e.kupac)}
                  </td>
                  <td
                    className="f-s-14 f-w-4 pb-5"
                    style={{ textAlign: "right" }}
                  >
                    {formatMoney(e.iznos)}
                  </td>
                  <td
                    className="f-s-14 f-w-4 pb-5"
                    style={{ textAlign: "right" }}
                  />
                  <td
                    className="f-s-14 f-w-4 pb-5"
                    style={{ textAlign: "right" }}
                  >
                    {e.odbija_se === 1 ? formatMoney(e.pdv) : "0,00"}
                  </td>
                  <td
                    className="f-s-14 f-w-4 pb-5"
                    style={{ textAlign: "right" }}
                  >
                    {e.odbija_se === 0 ? formatMoney(e.pdv) : "0,00"}
                  </td>
                  <td
                    className="f-s-14 f-w-4 pb-5"
                    style={{ textAlign: "right" }}
                  >
                    {formatMoney(parseFloat(e.iznos) - parseFloat(e.pdv))}
                  </td>
                  <td
                    className="f-s-14 f-w-4 pb-5"
                    style={{ textAlign: "right" }}
                  >
                    {formatMoney(this.reduceAmount(e, 21, 1))}
                  </td>
                  <td
                    className="f-s-14 f-w-4 pb-5"
                    style={{ textAlign: "right" }}
                  >
                    {formatMoney(this.reduceAmount(e, 7, 1))}
                  </td>
                  <td
                    className="f-s-14 f-w-4 pb-5"
                    style={{ textAlign: "right" }}
                  >
                    0,00
                  </td>
                  <td
                    className="f-s-14 f-w-4 pb-5"
                    style={{ textAlign: "right" }}
                  >
                    0,00
                  </td>
                  <td
                    className="f-s-14 f-w-4 pb-5"
                    style={{ textAlign: "right" }}
                  >
                    0,00
                  </td>
                  <td
                    className="f-s-14 f-w-4 pb-5"
                    style={{ textAlign: "right" }}
                  >
                    0,00
                  </td>
                  <td
                    className="f-s-14 f-w-4 pr-10 pb-5"
                    style={{ textAlign: "right" }}
                  >
                    0,00
                  </td>
                </tr>
              );
            })}
          {total - start < 36 ? (
            <tr style={{ borderTop: "0.5px solid black" }}>
              <td className="f-s-14 f-w-4 pb-5 pl-10 f-w-6" colSpan={7}>
                UKUPNO
              </td>
              <td className="f-s-14 f-w-4 pb-5 pl-10 f-w-6">
                {formatMoney(ukupnoSaPDV)}
              </td>
              <td
                className="f-s-14 f-w-4 pb-5 pl-10 f-w-6"
                style={{ textAlign: "right" }}
              >
                0,00
              </td>
              <td
                className="f-s-14 f-w-4 pb-5 pl-10 f-w-6"
                style={{ textAlign: "right" }}
              >
                {formatMoney(smijeSeOdbiti)}
              </td>
              <td
                className="f-s-14 f-w-4 pb-5 pl-10 f-w-6"
                style={{ textAlign: "right" }}
              >
                {formatMoney(neSmijeSeOdbiti)}
              </td>
              <td
                className="f-s-14 f-w-4 pb-5 pl-10 f-w-6"
                style={{ textAlign: "right" }}
              >
                {formatMoney(ukupnoOsnova)}
              </td>
              <td
                className="f-s-14 f-w-4 pb-5 pl-10 f-w-6"
                style={{ textAlign: "right" }}
              >
                {formatMoney(ukupnoPDV)}
              </td>
              <td
                className="f-s-14 f-w-4 pb-5 pl-10 f-w-6"
                style={{ textAlign: "right" }}
              >
                {formatMoney(ukupnoPDV7)}
              </td>
              <td
                className="f-s-14 f-w-4 pb-5 pl-10 f-w-6"
                style={{ textAlign: "right" }}
              ></td>
              <td
                className="f-s-14 f-w-4 pb-5 pl-10 f-w-6"
                style={{ textAlign: "right" }}
              >
                0,00
              </td>
              <td
                className="f-s-14 f-w-4 pb-5 pl-10 f-w-6"
                style={{ textAlign: "right" }}
              >
                0,00
              </td>
              <td
                className="f-s-14 f-w-4 pb-5 pl-10 f-w-6"
                style={{ textAlign: "right" }}
              >
                0,00
              </td>
              <td
                className="f-s-14 f-w-4 pb-5 pl-10 f-w-6"
                style={{ textAlign: "right" }}
              >
                0,00
              </td>
            </tr>
          ) : (
            ""
          )}
        </>
      );
    }
  };

  renderTable = (type?: number) => {
    const pages = Math.ceil(this.state.fakture.length / 35);
    const pagesArray = new Array(pages).fill("1");
    const datumGenerisanja = new Date();

    const datumGenerisanjaString = `${datumGenerisanja.getDate()}.${datumGenerisanja.getMonth() +
      1}.${datumGenerisanja.getFullYear()}, ${datumGenerisanja.getHours()}:${datumGenerisanja.getMinutes()}:${datumGenerisanja.getSeconds()}`;
    return pagesArray.map((e, index) => {
      if (type === 0) {
        return (
          <div
            className={`kifPrint pa-10 center-margin ${
              index > 0 ? "mt-40" : ""
            }`}
            id={`kifPrint-${index}`}
            key={index}
          >
            {index === 0 ? (
              <div className="header">
                <div className="row title">
                  <div className="col-sm-4">
                    <h4 className="f-s-26 f-w-4 lh">Roba | Obračun PDV</h4>
                  </div>
                </div>
                <div className="row info">
                  <div className="col-sm-4">
                    <span className="f-s-28 lh" id="nazivKIF">Knjiga izdatih računa</span>
                  </div>
                  <div className="col-sm-8">
                    <div className="row">
                      <div className="col-sm-2">
                        <span className="f-s-14 lh">Preduzeće</span>
                      </div>
                      <div className="col-sm-5">
                        <span className="f-s-14 lh">Amplitudo d.o.o</span>
                      </div>
                      <div className="col-sm-2">
                        <span className="f-s-14 lh">Datum</span>
                      </div>
                      <div className="col-sm-3">
                        <span className="f-s-14 lh" id="datum">
                          od {this.formatDate(this.props.startDate)} do{" "}
                          {this.formatDate(this.props.endDate)}
                        </span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-2">
                        <span className="f-s-14 lh">Adresa</span>
                      </div>
                      <div className="col-sm-5">
                        <span className="f-s-14 lh">
                          Bulevar Svetog Petra Cetinjskog 56 81000 Podgorica
                        </span>
                      </div>
                      <div className="col-sm-2">
                        <span className="f-s-14 lh">Status dok</span>
                      </div>
                      <div className="col-sm-3">
                        <span className="f-s-14 lh">Svi</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-2">
                        <span className="f-s-14 lh">Datum ispisa</span>
                      </div>
                      <div className="col-sm-5">
                        <span className="f-s-14 lh">
                          {datumGenerisanjaString}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <table>
              <thead>
                <tr className="firstHead">
                  <th className="f-w-4" colSpan={2} style={{ width: "8%" }} />
                  <th className="f-w-4" colSpan={4}>
                    Isprava
                  </th>
                  <th className="f-w-4" colSpan={4} style={{ width: "25%" }}>
                    Oslobođen promet
                  </th>
                  <th className="f-w-4" colSpan={8} style={{ width: "30%" }}>
                    Oporeziv promet
                  </th>
                </tr>
                <tr className="secondHead">
                  <th
                    className="f-w-4 f-s-14 prl-10"
                    rowSpan={3}
                    style={{
                      verticalAlign: "top",
                      border: "0.5px solid black"
                    }}
                  >
                    Br
                  </th>
                  <th
                    className="f-w-4 f-s-14"
                    rowSpan={3}
                    style={{
                      verticalAlign: "top",
                      border: "0.5px solid black"
                    }}
                  >
                    Datum knjiženja
                  </th>
                  <th
                    className="f-w-4 f-s-14 prl-10"
                    rowSpan={3}
                    style={{
                      verticalAlign: "top" ,
                      border: "0.5px solid black"
                    }}
                  >
                    Broj
                  </th>
                  <th
                    className="f-w-4 f-s-14 prl-10"
                    rowSpan={3}
                    style={{
                      verticalAlign: "top",
                      border: "0.5px solid black"
                    }}
                  >
                    Datum
                  </th>
                  <th
                    className="f-w-4 f-s-14"
                    rowSpan={3}
                    style={{
                      verticalAlign: "top",
                      border: "0.5px solid black"
                    }}
                  >
                    Ime i sjedište kupca
                  </th>
                  <th
                    className="f-w-4 f-s-14"
                    rowSpan={3}
                    style={{
                      verticalAlign: "top",
                      border: "0.5px solid black"
                    }}
                  >
                    Identifikacioni broj za kupca
                  </th>
                  <th
                    className="f-w-4 f-s-14"
                    rowSpan={3}
                    style={{
                      verticalAlign: "top",
                      border: "0.5px solid black"
                    }}
                  >
                    Vrijednost sa PDV
                  </th>
                  <th
                    className="f-w-4 f-s-14"
                    rowSpan={3}
                    style={{
                      verticalAlign: "top",
                      border: "0.5px solid black"
                    }}
                  >
                    Osl. promet bez prava na odb. ulaz. PDV
                  </th>
                  <th
                    className="f-w-4 f-s-14 "
                    rowSpan={3}
                    style={{
                      verticalAlign: "top",
                      border: "0.5px solid black"
                    }}
                  >
                    Izvoz robe
                  </th>
                  <th
                    className="f-w-4 f-s-14"
                    rowSpan={3}
                    style={{
                      verticalAlign: "top",
                      border: "0.5px solid black"
                    }}
                  >
                    Drugi promet sa pravom odb. ulaz. PDV
                  </th>
                  <th
                    className="f-w-4 f-s-14"
                    colSpan={4}
                    style={{
                      border: "0.5px solid black"
                    }}
                  >
                    Obveznici
                  </th>
                  <th
                    className="f-w-4 f-s-14"
                    colSpan={4}
                    style={{
                      border: "0.5px solid black"
                    }}
                  >
                    Krajnji kupci
                  </th>
                </tr>
                <tr>
                  <th
                    className="f-w-4 f-s-14"
                    colSpan={2}
                    style={{
                      border: "0.5px solid black"
                    }}
                  >
                    Po stopi 21%
                  </th>
                  <th
                    className="f-w-4 f-s-14"
                    colSpan={2}
                    style={{
                      border: "0.5px solid black"
                    }}
                  >
                    Po stopi 7%
                  </th>
                  <th
                    className="f-w-4 f-s-14"
                    colSpan={2}
                    style={{
                      border: "0.5px solid black"
                    }}
                  >
                    Po stopi 21%
                  </th>
                  <th
                    className="f-w-4 f-s-14"
                    colSpan={2}
                    style={{
                      border: "0.5px solid black"
                    }}
                  >
                    Po stopi 7%
                  </th>
                </tr>
                <tr>
                  <th
                    className="f-w-4 f-s-14"
                    style={{ border: "0.5px solid black" }}
                  >
                    Osnova
                  </th>
                  <th
                    className="f-w-4 f-s-14"
                    style={{ border: "0.5px solid black" }}
                  >
                    PDV
                  </th>
                  <th
                    className="f-w-4 f-s-14"
                    style={{ border: "0.5px solid black" }}
                  >
                    Osnova
                  </th>
                  <th
                    className="f-w-4 f-s-14"
                    style={{ border: "0.5px solid black" }}
                  >
                    PDV
                  </th>
                  <th
                    className="f-w-4 f-s-14"
                    style={{ border: "0.5px solid black" }}
                  >
                    Osnova
                  </th>
                  <th
                    className="f-w-4 f-s-14"
                    style={{ border: "0.5px solid black" }}
                  >
                    PDV
                  </th>
                  <th
                    className="f-w-4 f-s-14"
                    style={{ border: "0.5px solid black" }}
                  >
                    Osnova
                  </th>
                  <th
                    className="f-w-4 f-s-14"
                    style={{ border: "0.5px solid black" }}
                  >
                    PDV
                  </th>
                </tr>
                <tr>{this.renderColumnNumber()}</tr>
              </thead>
              <tbody>{this.renderBody(index, this.state.fakture.length)}</tbody>
            </table>
          </div>
        );
      } else {
        return (
          <React.Fragment key={index}>
            <div
              className={`kifPrint pa-10 center-margin ${
                index > 0 ? "mt-40" : ""
              }`}
              id={`kifPrint-${index}`}
            >
              {index === 0 ? (
                <div className="header">
                  <div className="row title">
                    <div className="col-sm-4">
                      <h4 className="f-s-26 f-w-4 lh">Roba | Obračun PDV</h4>
                    </div>
                  </div>
                  <div className="row info">
                    <div className="col-sm-4">
                      <span className="f-s-28 lh" id="nazivKIF">
                        Knjiga primljenih računa
                      </span>
                    </div>
                    <div className="col-sm-8">
                      <div className="row">
                        <div className="col-sm-2">
                          <span className="f-s-14 lh">Preduzeće</span>
                        </div>
                        <div className="col-sm-5">
                          <span className="f-s-14 lh">Amplitudo d.o.o</span>
                        </div>
                        <div className="col-sm-2">
                          <span className="f-s-14 lh">Datum</span>
                        </div>
                        <div className="col-sm-3">
                          <span className="f-s-14 lh" id="datum">
                            od {this.formatDate(this.props.startDate)} do{" "}
                            {this.formatDate(this.props.endDate)}
                          </span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-2">
                          <span className="f-s-14 lh">Adresa</span>
                        </div>
                        <div className="col-sm-5">
                          <span className="f-s-14 lh">
                            Bulevar Svetog Petra Cetinjskog 56 81000 Podgorica
                          </span>
                        </div>
                        <div className="col-sm-2">
                          <span className="f-s-14 lh">Status dok</span>
                        </div>
                        <div className="col-sm-3">
                          <span className="f-s-14 lh">Svi</span>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-2">
                          <span className="f-s-14 lh">Datum ispisa</span>
                        </div>
                        <div className="col-sm-5">
                          <span className="f-s-14 lh">
                            {datumGenerisanjaString}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <table>
                <thead>
                  <tr className="firstHead">
                    <th />
                    <th className="f-w-4" colSpan={2}>
                      Datum
                    </th>
                    <th className="f-w-4" colSpan={6}>
                      Isprava
                    </th>

                    <th className="f-w-4" colSpan={10}>
                      PDV
                    </th>
                  </tr>
                  <tr className="secondHead">
                    <th
                      className="f-w-4 f-s-14 prl-10"
                      rowSpan={4}
                      style={{ verticalAlign: "top" }}
                    >
                      Br
                    </th>
                    <th
                      className="f-w-4 f-s-14"
                      rowSpan={4}
                      style={{
                        verticalAlign: "top",
                        borderLeft: "0.5px solid black"
                      }}
                    >
                      Knjiženja isprave
                    </th>
                    <th
                      className="f-w-4 f-s-14"
                      rowSpan={4}
                      style={{
                        verticalAlign: "top",
                        borderRight: "0.5px solid black"
                      }}
                    >
                      Prijema isprave
                    </th>
                    <th
                      className="f-w-4 f-s-14 prl-10"
                      rowSpan={4}
                      style={{ verticalAlign: "top" }}
                    >
                      Broj
                    </th>
                    <th
                      className="f-w-4 f-s-14 prl-10"
                      rowSpan={4}
                      style={{ verticalAlign: "top" }}
                    >
                      Datum
                    </th>
                    <th
                      className="f-w-4 f-s-14"
                      rowSpan={4}
                      style={{ verticalAlign: "top" }}
                    >
                      Ime i sjedište dobavljača
                    </th>
                    <th
                      className="f-w-4 f-s-14"
                      rowSpan={4}
                      style={{
                        verticalAlign: "top"
                      }}
                    >
                      Identifikacioni broj za dobavljača
                    </th>
                    <th
                      className="f-w-4 f-s-14"
                      rowSpan={4}
                      style={{ verticalAlign: "top" }}
                    >
                      Vrijednost sa PDV
                    </th>
                    <th
                      className="f-w-4 f-s-14"
                      rowSpan={4}
                      style={{
                        verticalAlign: "top",
                        borderRight: "0.5px solid black"
                      }}
                    >
                      Oslobođene nabavke
                    </th>

                    <th
                      className="f-w-4 f-s-14"
                      colSpan={2}
                      rowSpan={2}
                      style={{
                        borderRight: "0.5px solid black",
                        borderBottom: "0.5px solid black"
                      }}
                    >
                      UKUPNI IZNOS ULAZNOG PDV
                    </th>
                    <th
                      className="f-w-4 f-s-14"
                      colSpan={3}
                      style={{
                        borderRight: "0.5px solid black",
                        borderBottom: "0.5px solid black"
                      }}
                    >
                      Od nabavki na domaćem tržištu
                    </th>
                    <th
                      className="f-w-4 f-s-14"
                      colSpan={3}
                      style={{
                        borderRight: "0.5px solid black",
                        borderBottom: "0.5px solid black"
                      }}
                    >
                      Nabavke od poreznih obveznika koji nemaju sjedište u CG
                    </th>
                    <th
                      className="f-w-4 f-s-14"
                      colSpan={2}
                      style={{
                        borderRight: "0.5px solid black",
                        borderBottom: "0.5px solid black"
                      }}
                    >
                      Paušalna naknada
                    </th>
                  </tr>
                  <tr />
                  <tr>
                    <th
                      className="f-w-4 f-s-14"
                      style={{
                        borderRight: "0.5px solid black",
                        borderBottom: "0.5px solid black"
                      }}
                      rowSpan={2}
                    >
                      KOJI SE SMIJE ODBITI
                    </th>
                    <th
                      className="f-w-4 f-s-14"
                      rowSpan={2}
                      style={{
                        borderRight: "0.5px solid black",
                        borderBottom: "0.5px solid black"
                      }}
                    >
                      KOJI SE NE SMIJE ODBITI
                    </th>
                    <th
                      className="f-w-4 f-s-14"
                      rowSpan={2}
                      style={{ borderRight: "0.5px solid black" }}
                    >
                      Osnova
                    </th>
                    <th
                      className="f-w-4 f-s-14"
                      colSpan={2}
                      style={{
                        border: "0.5px solid black"
                      }}
                    >
                      PDV
                    </th>
                    <th
                      className="f-w-4 f-s-14"
                      rowSpan={2}
                      style={{ border: "0.5px solid black" }}
                    >
                      Osnova
                    </th>
                    <th
                      className="f-w-4 f-s-14"
                      rowSpan={2}
                      style={{ borderRight: "0.5px solid black" }}
                    >
                      Iznos PDV
                    </th>
                    <th
                      className="f-w-4 f-s-14"
                      rowSpan={2}
                      style={{ borderRight: "0.5px solid black" }}
                    >
                      Se ne smije odbiti
                    </th>
                    <th
                      className="f-w-4 f-s-14"
                      rowSpan={2}
                      style={{ borderRight: "0.5px solid black" }}
                    >
                      Osnova
                    </th>
                    <th
                      className="f-w-4 f-s-14"
                      style={{
                        borderRight: "0.5px solid black",
                        borderBottom: "0.5px solid black"
                      }}
                    >
                      Iznos PDV
                    </th>
                  </tr>
                  <tr>
                    <th
                      className="f-w-4 f-s-14"
                      style={{ borderRight: "0.5px solid black" }}
                    >
                      21%
                    </th>
                    <th
                      className="f-w-4 f-s-14"
                      style={{ borderRight: "0.5px solid black" }}
                    >
                      7%
                    </th>
                    <th
                      className="f-w-4 f-s-14"
                      style={{ borderRight: "0.5px solid black" }}
                    >
                      5%
                    </th>
                  </tr>
                  <tr>{this.renderColumnNumber()}</tr>
                </thead>
                <tbody>
                  {this.renderBody(index, this.state.fakture.length)}
                </tbody>
              </table>
            </div>
          </React.Fragment>
        );
      }
    });
  };

  componentDidMount() {
    getFakture(
      this.props.type,
      this.axiosCancelSource,
      this.props.token,
      { od: this.props.startDate, do: this.props.endDate },
      0
    ).then(res => {
      this.setState({ fakture: res }, () => {
        this.state.fakture.map(e => {
          getUslugeFaktura(e.id, this.axiosCancelSource, this.props.token).then(
            res => {
              this.setState((prevState: IGeneratedKIFState) => ({
                uslugeFakture: [...prevState.uslugeFakture, ...res]
              }));
            }
          );
        });
      });
    });
    getKlijent(
        `${BASE_URL}/klijenti.php?nolimit=1`,
      this.axiosCancelSource,
      this.props.token
    ).then(res => this.setState({ klijenti: res }));
    getUsluge(this.axiosCancelSource, this.props.token).then(res => {
      this.setState({ usluge: res });
    });
  }

  componentWillUnmount() {
    this.axiosCancelSource.cancel("Component unmounted.");
  }

  toggleLoading = (loading: boolean) => {
    this.setState({ loading });
    noScrollBody(loading);
  };

  formatDate = (date: string) => {
    const parts = date.split("-");
    return `${parts[2]}.${parts[1]}.${parts[0]}.`;
  };
  render() {
    const pages = Math.ceil(this.state.fakture.length / 35);

    return (
      <div className="kifBackground ">
        {this.state.loading ? <Loading small title="Generisanje PDF-a" /> : ""}
        <div className="actions">
          <div className="btn pr-10 ml-20 mt-20">
            <div className="btn pr-10 ml-20 mt-20 d-flex mb-20 justify-content-center">
              <button
                className="d-flex align-items-center justify-content-center mr-10 exit"
                onClick={this.props.removeModal}
              >
                <span className="f-s-14 text-transform-uppercase line-height-1">
                  Odustani
                </span>
              </button>
              <button
                className="d-flex align-items-center justify-content-center mr-10"
                onClick={() =>
                  saveTable(true, this.toggleLoading, this.props.type, pages)
                }
              >
                {renderIcon("PDFIcon", "mr-10", 20)}
                <span className="f-s-14 text-transform-uppercase line-height-1">
                  Preuzmi
                </span>
              </button>
            </div>
          </div>
        </div>
        {this.props.type === 0 ? this.renderTable(0) : this.renderTable()}
      </div>
    );
  }
}

export default GeneratedKIF;
