export const toggleDropdown = (
  e: React.MouseEvent<HTMLButtonElement>,
  name: string,
  firstSelect: HTMLDivElement,
  firstDropdown: boolean,
  closeSecond?: HTMLDivElement,
  secondName?: string
) => {
  if (firstSelect) {
    firstSelect.style.height = firstDropdown
      ? firstSelect.scrollHeight + "px"
      : "0";
  }
  if (closeSecond) {
    closeSecond.style.height = "0";
  }
  return secondName;
};

export const calculateTicks = (value: number) => {
  let maxVal;
  let ticks = [];
  let tickCounter = 0;
  if (value < 1000) {
    maxVal = Math.ceil(value / 100) * 100;
  } else if (value < 10000) {
    if (value % 1000 < 500) {
      maxVal = Math.ceil(value / 500) * 500;
    } else {
      maxVal = Math.ceil(value / 1000) * 1000;
    }
  } else if (value < 20000 || value < 50000) {
    maxVal = Math.ceil(value / 5000) * 5000;
  } else if (value < 100000) {
    maxVal = Math.ceil(value / 10000) * 10000;
  } else if (value < 1000000) {
    maxVal = Math.ceil(value / 50000) * 50000;
  } else {
    maxVal = Math.ceil(value / 100000) * 100000;
  }
  const tick = maxVal / 10;
  for (let index = 0; index <= 10; index++) {
    ticks.push(tickCounter);
    tickCounter += tick;
  }
  return { maxVal, ticks };
};

export const sortItems = (
  array: Array<any>,
  type: any,
  direction: boolean,
  itemName: string
) => {
  let sortedArray;
  if (typeof type === "string") {
    if (type !== "datum") {
      if (direction) {
        sortedArray = array.sort((a: any, b: any) => {
          return a[itemName].localeCompare(b[itemName]);
        });
      } else {
        sortedArray = array.sort((a: any, b: any) => {
          return b[itemName].localeCompare(a[itemName]);
        });
      }
    } else {
      if (direction) {
        sortedArray = array.sort((a: any, b: any) => {
          return (
            new Date(b[itemName]).getTime() - new Date(a[itemName]).getTime()
          );
        });
      } else {
        sortedArray = array.sort((a: any, b: any) => {
          return (
            new Date(a[itemName]).getTime() - new Date(b[itemName]).getTime()
          );
        });
      }
    }
  } else {
    if (direction) {
      sortedArray = array.sort((a: any, b: any) => {
        let cijena1 = b[itemName];
        let cijena2 = a[itemName];
        return cijena1 - cijena2;
      });
    } else {
      sortedArray = array.sort((a: any, b: any) => {
        let cijena1 = b[itemName];
        let cijena2 = a[itemName];
        return cijena2 - cijena1;
      });
    }
  }
  return { sortedArray, direction };
};

export const noScrollBody = (scroll: boolean) => {
  if (scroll) {
    document.body.style.overflowY = "hidden";
    const html = document.querySelector("html");
    if (html) {
      html.style.overflowY = "hidden";
    }
  } else {
    document.body.style.overflowY = "auto";
    const html = document.querySelector("html");
    if (html) {
      html.style.overflowY = "auto";
    }
  }
};

export const emailIsValid = (email: string) => {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};
