import React from "react";
import ReactTooltip from "react-tooltip";

type ChartType = {
  customClass: string;
  moneyValue: string;
  width: number;
  color: string;
  id: string;
  subCustomClass?: string;
  subMoneyValue?: string;
  subWidth?: number;
  subColor?: string;
  subId?: string;
  subLeft?: number;
};

export const ChartItem = ({
  customClass,
  moneyValue,
  width,
  color,
  id,
  subCustomClass,
  subMoneyValue,
  subWidth,
  subColor,
  subId,
  subLeft
}: ChartType) => {
  return (
    <div className="barContainer mt-20 p-r">
      <span
        className="bar p-r"
        data-class={customClass}
        data-tip={`Vrijednost: ${moneyValue}€`}
        data-for={id}
        style={{
          width: isNaN(width) || width < 0 ? 0 : width + "%",
          backgroundColor: color
        }}
      />
      <ReactTooltip id={id} />
      <span
        className="subBar"
        data-class={subCustomClass}
        data-tip={`Vrijednost: ${subMoneyValue}€`}
        data-for={subId}
        style={{
          width: isNaN(width) ? 0 : subWidth + "%",
          backgroundColor: subColor,
          left: subLeft + "%"
        }}
      />
      <ReactTooltip id={subId} />
    </div>
  );
};
