import React, { Component } from "react";
import { IHeader } from "../../interfaces/pageInterfaces";
import { renderIcon } from "../Icons/Icons";
import { Link } from "react-router-dom";
import ModalSmall from "../modals/ModalSmall/ModalSmall";
import ModalAlarm from "../modals/ModalAlarm/ModalAlarm";
import {getAlarmi} from "../../api/getFunctions";
import axios from "axios";

class Header extends Component<IHeader> {
  state = {
    modal: false,
    modalAlarm: false,
    newAlarms: false,
    alarmi: []
  };
  axiosCancelSource = axios.CancelToken.source();
  componentDidMount() {
    getAlarmi(
        this.axiosCancelSource,
        this.props.api,
    ).then(res => {
      if(parseInt(res.unseen) > 0) {
        this.setState({ newAlarms: true, alarmi: res.alarmi });
      }
      // this.setState({ alarmi: res.alarmi });
    });
  }

  toggleCreate = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const listParent = e.currentTarget.parentNode;
    if (listParent) {
      const list = listParent.querySelector("ul");
      if (list) {
        list.classList.toggle("open");
      }
    }
  };
  closeDropdown = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const ul = e.currentTarget;
    if (ul) ul.classList.remove("open");
  };
  showModal = () => {
    this.setState({ modal: true });
  };
  removeModal = () => {
    this.setState({ modal: false });
  };

  showModalAlarm = () => {
    this.setState({
      modalAlarm: !this.state.modalAlarm,
      newAlarms: false
    });
  };

  logout = () => {
    localStorage.removeItem("token");
    window.location.href = "/";
  };

  renderModal = (name: string) => {
    if (name === "logout") {
      return (
        <ModalSmall
          question="Da li ste sigurni da želite da se izlogujete?"
          buttons={["delete", "euro"]}
          options={["Izloguj se", "Odustani"]}
          id={""}
          onClick={() => false}
          removeModal={this.removeModal}
          title="Log out"
          logout={this.logout}
        />
      );
    } else if (name === "alarm") {
      return (
        <ModalAlarm
          alarmi={this.state.alarmi}
          removeModal={this.showModalAlarm}
          api={this.props.api}
        />
      );
    }
  };

  render() {
    return (
      <div id="mainHeader">
        <div className="mainHeaderContainer mr-40 ml-40 d-flex justify-content-between align-items-center">
          <h1 className="m-0 line-height-1 text-transform-uppercase f-w-5">
            {this.props.title}
          </h1>
          <div className="buttonsContainer d-flex align-items-center">
            <div className="createButton mr-30">
              <button
                className="text-transform-uppercase f-s-14 f-w-6 text-color-white d-flex align-items-center justify-content-center"
                onClick={this.toggleCreate}
              >
                <span className="line-height-1 mr-10">
                  {renderIcon("PlusIcon", "")}
                </span>
                <span className="line-height-1">Kreiraj</span>
              </button>
              <ul id="create" onClick={this.closeDropdown}>
                <li>
                  <Link to="/fakture/izlazneFakture/unos">Izlazna faktura</Link>
                </li>
                <li>
                  <Link to="/fakture/ulazneFakture/unos">Ulazna faktura</Link>
                </li>
                <li>
                  <Link to="/ugovori/unos">Ugovor</Link>
                </li>
              </ul>
            </div>
            <div className="functionalButtons d-flex">
              <div
                className="notifications line-height-1 mr-20 d-flex align-items-center"
                onClick={this.showModalAlarm}
              >
                {renderIcon("BellIcon", "")}
                {this.state.newAlarms && <div className="alarmCircle">{" "}</div> }
              </div>
              <Link to="/podesavanja">
                <div className="settings line-height-1 mr-20 d-flex align-items-center">
                  {renderIcon("SettingsIcon", "")}
                </div>
              </Link>
              <div
                className="logout line-height-1 d-flex align-items-center"
                onClick={this.showModal}
              >
                {renderIcon("LogoutIcon", "")}
              </div>
            </div>
          </div>
        </div>
        {this.state.modal ? this.renderModal("logout") : ""}
        {this.state.modalAlarm ? this.renderModal("alarm") : ""}
      </div>
    );
  }
}

export default Header;
