import React, { Component } from "react";
import { IPage } from "../../interfaces/pageInterfaces";
import { renderIcon } from "../../components/Icons/Icons";
import Select from "react-dropdown-select";
import GeneratedKIF from "../../components/fileComponents/GeneratedKIF";
import { formatDate } from "../../functions/formaters";
import {getFakture, getKlijent} from "../../api/getFunctions";
import {BASE_URL} from "../../api/baseUrl";
import axios from "axios";
import ModalErrors from "../../components/modals/ModalErrors/ModalErrors";

interface IKIFState {
  pageNumber: number;
  godina: number;
  listaGodina: Array<{ id: number; naziv: number }>;
  listaMjeseci: Array<{ id: number; naziv: string }>;
  mjesec: string;
  modal: boolean;
  fakture: any;
}

type StateKeys = keyof IKIFState;

class KIF extends Component<IPage> {
  yearList = (): Array<{ id: number; naziv: number }> => {
    let currentYear = new Date().getFullYear();
    let minYear = 2015;
    let counter = 0;
    let years = [];
    for (let index = currentYear; index > 2014; index--) {
      years.push({ id: counter, naziv: index });
      counter++;
    }
    return years;
  };

  monthList = (year: number): Array<{ id: number; naziv: string }> => {
    if (year === new Date().getFullYear()) {
      const date = new Date().getMonth();
      const monthList = [];

      for (let index = 0; index <= date; index++) {
        monthList.push({
          id: index,
          naziv: new Date(0, index).toLocaleString("sr-latn-SR", {
            month: "long"
          })
        });
      }
      return monthList;
    } else {
      const monthList = [];
      for (let index = 0; index <= 11; index++) {
        monthList.push({
          id: index,
          naziv: new Date(0, index).toLocaleString("sr-latn-SR", {
            month: "long"
          })
        });
      }
      return monthList;
    }
  };
  currentMonth = () => {
    return new Date().toLocaleString("sr-latn-SR", {
      month: "long"
    });
  };
  state: IKIFState = {
    pageNumber: 0,
    godina: new Date().getFullYear(),
    listaGodina: this.yearList(),
    listaMjeseci: this.monthList(new Date().getFullYear()),
    mjesec: this.currentMonth(),
    modal: false,
    fakture: []
  };
  constructor(props: any) {
    super(props);
    if (this.props.changePageTitle) {
      this.props.changePageTitle("KIF/KUF");
    }
  }
  handleNavChange = (
    e: React.MouseEvent<HTMLLIElement>,
    pageNumber: number
  ) => {
    const parent: any = e.currentTarget.parentNode;
    parent.querySelectorAll("li").forEach((el: any) => {
      el.classList.remove("active");
    });
    e.currentTarget.classList.add("active");
    this.setState({
      pageNumber
    });
  };
  handleSelectChange = (
    val: any,
    name: StateKeys,
    index?: number,
    edit?: boolean
  ) => {
    if (name === "godina" && val.length > 0) {
      this.setState({ godina: val[0].naziv }, () => {
        this.setState({ listaMjeseci: this.monthList(this.state.godina) });
      });
    } else if (name === "mjesec" && val.length > 0) {
      this.setState({ mjesec: val[0].naziv });
    }
  };

  showModal = () => {
    const s = this.state;
    const startDate =
        formatDate(
            new Date(
                s.godina,
                this.state.listaMjeseci.map(e => e.naziv).indexOf(this.state.mjesec)
            )
        ) || "";
    const endDate =
        formatDate(
            new Date(
                s.godina,
                this.state.listaMjeseci.map(e => e.naziv).indexOf(this.state.mjesec) +
                1,
                0
            )
        ) || "";
    axios({
      method: "GET",
      url: `${BASE_URL}/izlazne.php?od=${startDate}&do=${endDate}&mjesecna=&api=${this.props.api}`,
    }).then(result => this.setState({fakture: result.data, modal: true}));
  };

  removeModal = () => {
    this.setState({ modal: false });
  };
  render() {
    const s = this.state;
    const startDate =
      formatDate(
        new Date(
          s.godina,
          this.state.listaMjeseci.map(e => e.naziv).indexOf(this.state.mjesec)
        )
      ) || "";
    const endDate =
      formatDate(
        new Date(
          s.godina,
          this.state.listaMjeseci.map(e => e.naziv).indexOf(this.state.mjesec) +
            1,
          0
        )
      ) || "";
    return (
      <div className="kif">
        {s.fakture.length > 0 && s.modal && (
          <GeneratedKIF
            removeModal={this.removeModal}
            startDate={startDate}
            endDate={endDate}
            type={this.state.pageNumber}
            token={this.props.api}
          />
        )}
        {s.fakture.length === 0 && s.modal && (
            <ModalErrors
                title="Nema prometa"
                errors={["Nema prometa u tom mesecu."]}
                removeModal={this.removeModal}
                message="Nema prometa u tom mesecu."
            />
        )}
        <nav>
          <ul className="nav d-flex">
            <li
              className={`prl-10 d-flex align-items-center transition-1 ${
                this.state.pageNumber === 0 ? "active" : ""
              }`}
              onClick={(e: React.MouseEvent<HTMLLIElement>) =>
                this.handleNavChange(e, 0)
              }
            >
              <span className="line-height-1 mr-10">
                {renderIcon("SearchIcon", "", 20)}
              </span>
              <span className="f-s-20 f-w-6 line-height-1">
                Knjiga izdatih računa
              </span>
            </li>
            <li
              className={`prl-10 d-flex align-items-center transition-1 ${
                this.state.pageNumber === 2 ? "active" : ""
              }`}
              onClick={(e: React.MouseEvent<HTMLLIElement>) =>
                this.handleNavChange(e, 2)
              }
            >
              <span className="line-height-1 mr-10">
                {renderIcon("SearchIcon", "", 20)}
              </span>
              <span className="f-s-20 f-w-6 line-height-1">
                Knjiga primljenih računa
              </span>
            </li>
          </ul>
        </nav>
        <div className="content pa-20">
          <div className="row pt-40">
            <div className="col-sm-12 col-md-6">
              <div className="row">
                <div className="col-sm-12 col-md-6">
                  {/*<div className="title f-s-12 f-w-5 mb-5">*/}
                  {/*  <span>Godina</span>*/}
                  {/*</div>*/}
                  <div className="input p-r">
                    <label htmlFor="" className="react-select-label f-s-12">
                      {s.godina}
                    </label>
                    <Select
                      options={s.listaGodina}
                      labelField="naziv"
                      valueField="id"
                      placeholder=""
                      searchable={false}
                      dropdownGap={0}
                      clearOnSelect={true}
                      onChange={(val: any) =>
                        this.handleSelectChange(val, "godina", 0)
                      }
                      noDataRenderer={() => (
                        <span className="text-center ptb-5">Nema godina</span>
                      )}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  {/*<div className="title f-s-12 f-w-5 mb-5">*/}
                  {/*  <span>Mjesec</span>*/}
                  {/*</div>*/}
                  <div className="input p-r">
                    <label htmlFor="" className="react-select-label f-s-12">
                      {s.mjesec}
                    </label>
                    <Select
                      options={s.listaMjeseci}
                      labelField="naziv"
                      valueField="id"
                      placeholder=""
                      searchable={false}
                      dropdownGap={0}
                      clearOnSelect={true}
                      onChange={(val: any) =>
                        this.handleSelectChange(val, "mjesec", 0)
                      }
                      noDataRenderer={() => (
                        <span className="text-center ptb-5">
                          Odaberi godinu
                        </span>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-20">
                <div className="col-sm-12 col-md-6 col-lg-3">
                  <div className="btn">
                    <button
                      className="d-flex align-items-center justify-content-center mr-10"
                      onClick={this.showModal}
                    >
                      {renderIcon("DocumentIcon", "mr-5", 20)}{" "}
                      <span className="f-s-14 text-transform-uppercase line-height-1">
                        Prikaži
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default KIF;
